import { useState, useEffect } from 'react';

function RelatedCompany(props) {
  const [isActive, setIsActive] = useState(false);
  const [studyID, setStudyID] = useState(null);

  useEffect(() => {
    if (props.isPercentScale) {
      if (isActive && studyID) {
        props.widget.activeChart().getStudyById(studyID).changePriceScale('as-series');
      }
    } else {
      if (isActive && studyID) {
        props.widget.activeChart().getStudyById(studyID).changePriceScale('new-right');
      }
    }
  }, [props.isPercentScale, isActive, studyID]);

  function handleClick(e) {
    e.preventDefault();

    setIsActive(prevState => {
      if (prevState) {
        if (studyID) {
          props.widget.activeChart().getStudyById(studyID).changePriceScale('as-series');
          props.widget.activeChart().getStudyById(studyID).setVisible(false);
        }
        return false;
      }
      else {
        if (props.currency !== 'USD')
          props.widget.activeChart().setSymbol(props.USDTicker);

        if (studyID) {
          if (props.isPercentScale) {
            props.widget.activeChart().getStudyById(studyID).changePriceScale('as-series');
          } else {
            props.widget.activeChart().getStudyById(studyID).changePriceScale('new-right');
          }

          props.widget.activeChart().getStudyById(studyID).setVisible(true);
        } else {
          if (props.isPercentScale) {
            props.widget.activeChart().createStudy(
              'Compare', true, true,
              {source: 'close', symbol: props.ticker},
              null, {priceScale: 'as-series'}
            ).then(val => {
              setStudyID(val);
              props.widget.activeChart().getPanes()[0].getRightPriceScales()[0].setMode(2);
            });
          } else {
            props.widget.activeChart().createStudy(
              'Compare', true, true,
              {source: 'close', symbol: props.ticker},
              null, {priceScale: 'new-right'}
            ).then(val => {
              setStudyID(val);
              props.widget.activeChart().getPanes()[0].getRightPriceScales()[0].setMode(0);
            });
          }
        }

        return true;
      }
    });
  }

  if (props.isWidgetReady) {
    return (
      <li className={ isActive ? "state-selected": null} style={{ display: props.elDisplay}}><a href="#" onClick={handleClick}>{props.name}</a></li>
    );
  } else {
    return null;
  }

}

export default RelatedCompany;
