import { formatFloats, formatPercents, formatPrice } from '../../common/formatting'

function MobileTableRow(props) {
  return (
    <li>
      <div className="summary__list-top">
        <div className="summary__list-name"><a href={'/company/' + props.data.key + '/'}>{props.data.name}</a><span>({props.data.share.name})</span></div>
        <div className="summary__list-delta d-flex align-items-center color-blue">SP: {formatPrice(props.data.share.price)}</div>
      </div>
      <div className="summary__list-data">
        <div>MCAP: {formatFloats(props.data.mcap_usd, 0, true)}</div>
        <div>EV/EBITDA: {formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</div>
        <div>Div yield: {formatPercents(props.data.multipliers.Div_yield, true)}</div>
      </div>
    </li>
  );
}

export default MobileTableRow;