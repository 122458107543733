import { formatFloats, formatPercents, formatPrice, formatDatetime } from '../../common/formatting'

function TableRow(props) {
  return (
    <tr>
      <td className="sticky-col"><a href={'/company/' + props.data.key + '/'}>{props.data.name}</a></td>
      <td>{props.data.share.name}</td>
      <td>{formatPrice(props.data.share.price)}</td>
      <td>{formatFloats(props.data.mcap_usd, 0, true)}</td>
      <td>{formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</td>
      <td>{formatFloats(props.data.multipliers.P_E, 2, true)}</td>
      <td>{formatPercents(props.data.multipliers.FCF_yield, true)}</td>
      <td>{formatPercents(props.data.multipliers.Div_yield, true)}</td>
      <td>{formatPercents(props.data.sector_info.x_score, true)}</td>
      <td>{formatDatetime(props.data.sector_info.tu_date, false, false, false)}</td>
      <td>{formatDatetime(props.data.sector_info.ref_date, false, false, false)}</td>
      <td>{formatFloats(props.data.sector_info.f_value, 0, true)}</td>
      <td>{formatFloats(props.data.sector_info.cons, 0, true)}</td>
      <td>{formatPercents(props.data.sector_info.f_vs_cons, true)}</td>
      <td>{formatFloats(props.data.sector_info.w_value, 0, true)}</td>
      <td>{formatPercents(props.data.sector_info.w_vs_cons, true)}</td>
    </tr>
  );
}

export default TableRow;