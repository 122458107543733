import React from 'react';
import TableRow from './TableRow';

function TablePart(props) {
  return (
    <React.Fragment>
      <tr className="table__name">
        <td className="sticky-col">{props.data.key}</td>
        <td colSpan="12"></td>
      </tr>
      {props.data.results.map(row => {
        return <TableRow key={row.key} data={row} />
      })}
    </React.Fragment>
  );
}

export default TablePart;