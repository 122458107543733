import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';
import { getCurYearNums } from '../../common/formatting'

import TablePart from './TablePart';

function Table(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    var filtersJSON = {};

    if (props.firstLevel)
      filtersJSON['l1'] = {'key': props.firstLevel};
    if (props.secondLevel)
      filtersJSON['l2'] = {'key': props.secondLevel};
    if (props.thirdLevel)
      filtersJSON['l3'] = {'key': props.thirdLevel};
    if (props.fourthLevel)
      filtersJSON['l4'] = {'key': props.fourthLevel};

    newAxiosInstance.post('commodities/table/', filtersJSON).then((res) => {
      var newData = [];
      var prevGroup = res.data[0].region;
      var newGroup = {
        'key': prevGroup,
        'results': []
      };

      res.data.forEach(result => {
        if (result.region !== prevGroup) {
          newData.push(newGroup);

          newGroup = {
            'key': result.region,
            'results': []
          };
        }

        newGroup.results.push(result);
        prevGroup = result.region;
      });
      newData.push(newGroup);

      setData(newData);
    });
  }, [props.firstLevel, props.secondLevel, props.thirdLevel, props.fourthLevel]);

  return (
  <table>
    <thead>
      <tr>
        <th className="sticky-col">
          Commodity name
        </th>
        <th>
          Price/unit
        </th>
        <th>
          Frequency
        </th>
        <th>
          Last reported
        </th>
        <th>
          SPOT
        </th>
        <th>
          1D diff.
        </th>
        <th>
          1W diff.
        </th>
        <th>
          1M diff.
        </th>
        <th>
          YTD diff.
        </th>
        <th>
          Stress
        </th>
        <th>
          Mid
        </th>
        <th>
          Bull
        </th>
        <th>
          Cons-s&#39;{getCurYearNums()}
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map(tablePart => {
        return <TablePart key={tablePart.key} data={tablePart} />
      })}
    </tbody>
  </table>
  );
}

export default Table;