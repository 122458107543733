import { useState, useEffect } from 'react';
import ListItem from '../../common/components/ListItem';

function SensChartsCompaniesList(props) {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (props.companies.length > 0 && props.companies.length < 6) {
      setShowAll(true);
    }
  }, [props.companies]);

  function handleShowAllClick(e) {
    e.preventDefault();

    setShowAll(prevState => {
      return !prevState;
    });
  }

  return (
    <ul className="tags" style={{ marginBottom: '0px' }}>
      {props.companies.map((company, index) => {
        return <ListItem key={company.key} elDisplay={index < 5 || showAll ? 'block': 'none'} withLink={true} linkClass={null} isActive={props.sensChartsFilter.includes(company.key.replace('_', ' '))} activeClass={'state-selected'} baseClass={null} instanceKey={company.key.replace('_', ' ')} instanceName={company.name} clickHandler={props.handleSensChartsCompClick} />
      })}
      { props.companies.length > 5 &&
        <ListItem withLink={true} linkClass={null} isActive={showAll} activeClass={'state-selected'} baseClass={null} instanceKey={'More ...'} instanceName={'More ...'} clickHandler={handleShowAllClick} />
      }
    </ul>
  );
}

export default SensChartsCompaniesList;