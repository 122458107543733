import { useState } from 'react';

import { IconContext } from 'react-icons';
import { HiExternalLink } from 'react-icons/hi';

function ObjectLink(props) {
  const [iconColor, setIconColor] = useState('grey');

  return (
    <li>
      <div className="search__selected-item">
        <span
            onMouseOver={(e) => setIconColor('#0084ff')}
            onMouseOut={(e) => setIconColor('grey')}
          ><a href={'/' + props.objType + '/' + props.data.key}>{props.data.name}{props.extraExpr}</a></span>
        <a className="search__selected-item--hideLink" href={"/sector/" + props.data.key}
           onMouseOver={(e) => setIconColor('#0084ff')}
           onMouseOut={(e) => setIconColor('grey')}
          >
          <IconContext.Provider value={{ color: iconColor, size: '16' }}>
            <div>
              <HiExternalLink />
            </div>
          </IconContext.Provider>
        </a>
      </div>
    </li>
  );
}

export default ObjectLink;