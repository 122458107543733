import { useState, useEffect } from 'react';
import { handleClick } from '../../common/handlers';

function ListItem(props) {
  const [elDisplay, setElDisplay] = useState('block');

  useEffect(() => {
    if (!!props.elDisplay) {
      setElDisplay(props.elDisplay);
    }
  }, [props.elDisplay]);


  if (props.withLink) {
    return (
      <li className={props.isActive ? props.activeClass: props.baseClass} style={{ display: elDisplay}}><a className={props.linkClass} href="#" data-value={props.instanceKey} onClick={props.clickArgs ? (e) => handleClick(e, ...props.clickArgs): props.clickHandler}>{props.instanceName}</a></li>
    );
  }
  else {
    return (
      <li className={props.isActive ? props.activeClass: props.baseClass} style={{ display: elDisplay}} data-value={props.instanceKey} onClick={props.clickArgs ? (e) => handleClick(e, ...props.clickArgs): props.clickHandler}>{props.instanceName}</li>
    );
  }
}

export default ListItem;