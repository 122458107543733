import { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsTelegram } from 'react-icons/bs';

import { formatDatetime } from '../../common/formatting'

function NewsCard(props) {
  const [newsBody, setNewsBody] = useState('');

  useEffect(() => {
    var newBody = props.data.body;
    var matches = [];

    newBody.replace(/<P>(.*?)<\/P>/g, function () {
      matches.push(arguments[1]);
    });

    if (matches.length === 0)
      newBody.replace(/<p>(.*?)<\/p>/g, function () {
        matches.push(arguments[1]);
      });

    if (matches.length === 0)
      newBody.replace(/<B>(.*?)<\/B>/g, function () {
        matches.push(arguments[1]);
      });

    if (matches.length > 0)
      newBody = matches[0];
    setNewsBody(newBody);
  }, [props.data.body]);

  return (
    <div className="col col-50">
      <div className="publications__item card card-white">
        <ul className="publications__item-info">
          <li>
            <i className="icon icon__speaker">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.9591 15.4171C12.8795 15.4171 11.6245 13.0512 11.6245 10.0004C11.6245 6.94958 12.8795 4.58375 13.9591 4.58375C15.0378 4.58375 16.2928 6.94958 16.2928 10.0004C16.2928 13.0512 15.0378 15.4171 13.9591 15.4171ZM8.72654 14.9162C8.49738 15.2125 8.08571 15.31 7.74738 15.1487L6.06779 14.3487C5.79196 14.2171 5.61696 13.9458 5.61071 13.64L5.6253 12.9175L9.02446 14.5196L8.72654 14.9162ZM13.9591 2.96375C13.1953 2.96375 12.487 3.39375 11.8949 4.12791L2.82363 8.40333C2.11446 9.20541 2.11446 10.7946 2.82363 11.5975L4.38113 12.3312L4.37572 13.665C4.39197 14.4408 4.83655 15.1304 5.53738 15.4633L7.21529 16.2637C7.4932 16.3954 7.79112 16.4592 8.08487 16.4592C8.70154 16.4592 9.30822 16.1812 9.70238 15.6729L10.1682 15.0587L11.8949 15.8725C12.487 16.6062 13.1953 17.0367 13.9591 17.0367C16.0299 17.0367 17.7082 13.8862 17.7082 10.0004C17.7082 6.11416 16.0299 2.96375 13.9591 2.96375ZM12.694 8.29208C12.6703 8.29208 12.6499 8.29583 12.629 8.29875C12.4832 8.72541 12.3936 9.23041 12.3753 9.77458C12.3728 9.84458 12.3707 9.91333 12.3703 9.98458C12.372 10.6258 12.4757 11.2171 12.6499 11.7046C12.6645 11.7054 12.6786 11.7087 12.694 11.7087C13.6361 11.7087 14.4011 10.9437 14.4011 10.0004C14.4011 9.05666 13.6361 8.29208 12.694 8.29208Z"
                  fill="#606980"/>
              </svg>
            </i>
            <a href="/">Metals Wire</a>
          </li>
          <li>{formatDatetime(props.data.moment, true, false, true)}</li>
        </ul>
        <h3 className="publications__item-title">
          <a href={"/news/" + props.data.id}>
            {props.data.header}
          </a>
        </h3>
        <p dangerouslySetInnerHTML={{ __html: newsBody }} />
        <div className="card__bottom">
          <div className="card__bottom-author">
            <div className="card__bottom-author--name"><a href="#">Nikanor Khalin</a></div>
          </div>
          <div className="card__bottom-links">
            { props.data.tg_link &&
              <ul>
                <li>
                  <a href={props.data.tg_link}>
                    <IconContext.Provider value={{ color: 'grey', size: '24' }}>
                      <div>
                        <BsTelegram />
                      </div>
                    </IconContext.Provider>
                  </a>
                </li>
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;