import { formatDatetime } from '../../common/formatting'

import ICalLink from './ICalLink';

function EventCard(props) {
  return (
    <div className="card card-white">
      <div className="events-calendar__links">
        <ul>
          <li><span className="color-red">{formatDatetime(props.data.moment, true, false, false)}</span></li>
          <li>{props.data.event_type}</li>
          <li><a href={'/company/' + props.data.company_key + '/'}>{props.data.company_name}</a></li>
        </ul>
      </div>
      <div className="events-calendar__description">
        <h3><a href={'/event/' + props.data.id}>{props.data.header}</a></h3>
      </div>
      <div className="card__bottom">
        <div className="card__bottom-author">
        </div>
        <div className="card__bottom-links">
          <ul>
            <li>
              <ICalLink withClass={false} data={props.data} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EventCard;