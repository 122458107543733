import React from 'react';
import { useState, useEffect } from 'react';

function CommoditySearchResult(props) {
  const [commodities, setCommodities] = useState([]);

  useEffect(() => {
    var sortedData = props.data.sort(function(a, b) {
      return ('' + a.name).localeCompare(b.name);
    });
    setCommodities(sortedData);
  }, [props.data]);

  return (
    <React.Fragment>
      {commodities.map(commodityData => {
        return <li key={commodityData.key} ><a href={'/data-chart/' + commodityData.key}>{commodityData.name}</a></li>;
      })}
    </React.Fragment>
  );
}

export default CommoditySearchResult;