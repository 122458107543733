import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../axios/instance';
import newAxiosInstance from '../axios/newInstance';
import { formatUTCDatetime } from '../common/formatting';

import ListItem from '../common/components/ListItem';
import CheckBox from '../common/components/CheckBox';
import ObjectLink from '../common/components/ObjectLink';

import Table from '../common/components/SummaryTable/Table';

function Summary() {
  const { sectorKey } = useParams();

  const [scenario, setScenario] = useState('S');
  const [scenarioName, setScenarioName] = useState('SPOT');
  const [scenarios, setScenarios] = useState([{'key': null, 'name': null}]);
  const [scenariosNames, setScenariosNames] = useState({'S': 'SPOT'})

  const [sectors, setSectors] = useState([{'key': 'All', 'name': 'Show all'}]);
  const [initialSectorsStates, setInitialSectorsStates] = useState({'All': true});
  const [sectorsStates, setSectorsStates] = useState({'All': true});

  const [filters, setFilters] = useState({
    'isRussian': false,
    'isAsian': false,
    'isCovered': false,
    'withLargeCap': false,
  });

  const [spotDate, setSpotDate] = useState(null);

  useEffect(() => {
    newAxiosInstance.get('option/scenario/').then((res) => {
      var newScenariosNames = {};

      res.data.forEach(result => {
        newScenariosNames[result.key] = result.name;
      });

      setScenarios(res.data);
      setScenariosNames(newScenariosNames);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('option/sector/').then((res) => {
      var newSectors = [{'key': 'All', 'name': 'Show all'}];
      var newInitialSectorsStates = {};
      var newSectorsStates = {};
      var newSector = {};

      if (sectorKey)
          newSectorsStates['All'] = false;

      res.data.forEach(result => {
        if (result.companies_num > 0) {
          newSector = {
            'key': result.sector_key,
            'name': result.sector_name
          };

          newSectors.push(newSector);
          newInitialSectorsStates[result.sector_key] = false;
          if (sectorKey) {
            if (result.sector_key === sectorKey) {
              newSectorsStates[result.sector_key] = true;
            } else {
              newSectorsStates[result.sector_key] = false;
            }
          } else {
            newSectorsStates[result.sector_key] = false;
          }
        }
      });

      setSectors(newSectors);
      setInitialSectorsStates(prevState => ({ ...prevState, ...newInitialSectorsStates }));
      setSectorsStates(prevState => ({ ...prevState, ...newSectorsStates }));
    });
  }, [sectorKey]);

  useEffect(() => {
    newAxiosInstance.get('ref-moment/spot_date/').then((res) => {
      setSpotDate(res.data.value);
    });
  }, []);

  function handleSectorClick(e) {
    e.preventDefault();
    if (e.target.getAttribute('data-value') === 'All') {
      setSectorsStates(prevState => {
        if (prevState['All']) {
          return { ...prevState, [e.target.getAttribute('data-value')]: !(prevState[e.target.getAttribute('data-value')]) };
        } else {
          return { ...prevState, ...initialSectorsStates };
        }
      });
    } else {
      setSectorsStates(prevState => {
        if (prevState['All']) {
          return { ...prevState, [e.target.getAttribute('data-value')]: !(prevState[e.target.getAttribute('data-value')]), ['All']: false };
        } else {
          return { ...prevState, [e.target.getAttribute('data-value')]: !(prevState[e.target.getAttribute('data-value')]) };
        }
      });
    }
  }

  function handleFilterClick(e) {
    setFilters(prevState => ({
      ...prevState,
      [e.target.getAttribute('data-value')]: e.target.checked
    }));
  }

  return (
    <div className="app page-metals-mining-summary">
      <div className="wrapper">
        <main className="content">
          <div className="container">
            <div className="metals-mining-summary">
              <div className="section__head">
                <div className="dropdown hidden-desktop">
                  <div className="dropdown__button js-dropdown-button">
                    <i className="icon">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M7.48641 10.8333C7.48641 10.8333 4.97671 7.08334 3.48135 4.67501C3.12157 4.09826 3.44797 3.33334 4.14046 3.33334H15.8569C16.5494 3.33334 16.8826 4.0838 16.516 4.67501C15.0226 7.08334 12.4924 10.8333 12.4924 10.8333V15.8333C12.4924 16.2917 12.1169 16.6667 11.658 16.6667H8.32073C7.86185 16.6667 7.48641 16.2917 7.48641 15.8333V10.8333ZM9.16683 10.4167L5.62516 5.00001H14.4074L10.8335 10.4167V15L9.16683 15V10.4167Z"
                          fill="#606980" />
                      </svg>
                    </i>
                  </div>
                  <div className="dropdown__selection dropdown__menu dropdown__menu-white dropdown__menu-fixed">
                    <div className="dropdown__slide">
                      <div className="dropdown__hide">
                        <svg width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.5"
                            d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                            fill="#D4D7DF" />
                        </svg>
                      </div>
                      <div className="dropdown__menu-title">Feed Settings</div>
                      <div className="dropdown__slide-content scrollable">
                        <ul>
                          <CheckBox withListItemWrap={true} id={'chkbx_111'} value={'1'} label={'Russian companies'} isActive={filters['isRussian']} instanceKey={'isRussian'} clickHandler={handleFilterClick} />
                          <CheckBox withListItemWrap={true} id={'chkbx_222'} value={'1'} label={'Asian companies'} isActive={filters['isAsian']} instanceKey={'isAsian'} clickHandler={handleFilterClick} />
                          <CheckBox withListItemWrap={true} id={'chkbx_333'} value={'1'} label={'Covered companies'} isActive={filters['isCovered']} instanceKey={'isCovered'} clickHandler={handleFilterClick} />
                          <CheckBox withListItemWrap={true} id={'chkbx_444'} value={'1'} label={'Large cap companies'} isActive={filters['withLargeCap']} instanceKey={'withLargeCap'} clickHandler={handleFilterClick} />
                        </ul>
                      </div>
                      <div className="button__wrapper"><button type="button" className="button button__blue hidden-desktop">Apply</button></div>
                    </div>
                    <div className="dropdown__overlay"></div>
                  </div>
                </div>
              </div>
              <div className="summary__filter">
                <div className="control-group">
                  <CheckBox withListItemWrap={false} id={'chkbx_111'} value={'1'} label={'Russian companies'} isActive={filters['isRussian']} instanceKey={'isRussian'} clickHandler={handleFilterClick} />
                  <CheckBox withListItemWrap={false} id={'chkbx_222'} value={'1'} label={'Asian companies'} isActive={filters['isAsian']} instanceKey={'isAsian'} clickHandler={handleFilterClick} />
                  <CheckBox withListItemWrap={false} id={'chkbx_333'} value={'1'} label={'Covered companies'} isActive={filters['isCovered']} instanceKey={'isCovered'} clickHandler={handleFilterClick} />
                  <CheckBox withListItemWrap={false} id={'chkbx_444'} value={'1'} label={'Large cap companies'} isActive={filters['withLargeCap']} instanceKey={'withLargeCap'} clickHandler={handleFilterClick} />
                </div>
                <div className="d-flex">
                  <div className="custom-select__name">Scenarios:</div>
                  <div className="custom-select">
                    <div className="custom-select__inner">
                      <div className="custom-select__trigger"><span>{scenarioName}</span>
                        <i className="icon">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#0A2896" />
                          </svg>
                        </i>
                      </div>
                      <div className="custom-select__options position-right">
                        <div className="custom-select__options-inner scrollable">
                          <ul className="custom-select__options-list">
                            {scenarios.map(scenario => {
                              return <ListItem key={scenario.key} withLink={false} linkClass={null} isActive={scenario === scenario.key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={scenario.key} instanceName={scenario.name} clickArgs={[false, setScenario, scenariosNames, setScenarioName]} />
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary__head">
                <ul className="tags">
                  {sectors.map(sectorData => {
                    return <ListItem key={sectorData.key} withLink={true} linkClass={null} isActive={sectorsStates[sectorData.key]} activeClass={'state-selected'} baseClass={null} instanceKey={sectorData.key} instanceName={sectorData.name} clickHandler={handleSectorClick} />
                  })}
                </ul>
                <div className="export hidden-mobile">
                  <ul>
                    <li className="export__date">{formatUTCDatetime(spotDate, true, true, false)}</li>
                    <li>
                      <span>Spot Date</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="search__selected">
                <div className="search__selected-items">
                  <ul>
                    {sectors.map(sectorData => {
                      if (sectorData.key !== 'All' && sectorsStates[sectorData.key])
                        return <ObjectLink key={sectorData.key} objType='sector' extraExpr=' sector board' data={sectorData} />;
                    })}
                  </ul>
                </div>
              </div>
              <div className="table__container">
                <div className="summary__table table__scrollable">
                  <Table scenario={scenario} sectorsStates={sectorsStates} filters={filters} withStickyHeader={true} withSharePerf={true} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Summary;
