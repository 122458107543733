import React from 'react';
import RepTableRow from './RepTableRow';

function RepTable(props) {
  return (
    <React.Fragment>
      {props.repData.map(tableRowData => {
        return <RepTableRow key={tableRowData.key} data={tableRowData} repPeriod={props.repPeriod} repScenario={props.repScenario} rowHandler={props.rowHandler} />
      })}
    </React.Fragment>
  );
}

export default RepTable;