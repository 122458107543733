import { useState } from 'react';

function SortingIcon(props) {
  const [classNames, setClassNames] = useState({
    'no': 'icon icon__sort __disabled',
    'desc': 'icon icon__sort',
    'asc': 'icon icon__sort __down',
  });

  return (
    <i className={props.sortingKey === props.sortingState[0] ? classNames[props.sortingState[1]]: 'icon icon__sort __disabled'} data-value={props.sortingKey} onClick={props.clickHandler}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg" data-value={props.sortingKey}>
        <path d="M4 12H7V10.6667H4V12ZM4 4V5.33333H12V4H4ZM4 8.66667H9V7.33333H4V8.66667Z"
          fill="#606980" data-value={props.sortingKey} />
      </svg>
    </i>
  );
}

export default SortingIcon;