import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Chart from '../Chart/Chart';

import axiosInstance from '../../axios/instance';
import { formatDatetime } from '../../common/formatting';

function ChartTool() {
  return (
    <div className="app page-publication">
      <div className="wrapper">
        <main className="content">
          <div className="container">
            <div className="publication">
              <div className="publication__inner">
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__row">
                      <div className="publication__col-left" style={{ width: '100%'}}>
                        <div className="col col-100">
                          <div className="card card-white">
                            <div>
                              <Chart symbol={'Metals & Mining:AHDY'} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ChartTool;
