export function addCommas(value) {
  return Number(value).toLocaleString('en-US');
}

export function formatFloats(value, roundOrder, withCommas) {
  if (value) {
    if (withCommas) {
      return addCommas(value.toFixed(roundOrder))
    }
    return value.toFixed(roundOrder);
  }
  return value;
}

export function formatPercents(value, withZero, withoutSign=false, withSign=false) {
  if (value) {
    if (withZero) {
      if (withoutSign) {
        if (value < 0) {
          return ((value * 100).toFixed(1) + '%').substring(1);
        }
      } else if (withSign) {
        if (value > 0) {
          return '+' + (value * 100).toFixed(1) + '%';
        }
      }

      return (value * 100).toFixed(1) + '%';
    } else {
      if (withoutSign) {
        if (value < 0) {
          return ((value * 100).toFixed(0) + '%').substring(1);
        }
      } else if (withSign) {
        if (value > 0) {
          return '+' + (value * 100).toFixed(0) + '%';
        }
      }

      return (value * 100).toFixed(0) + '%';
    }
  } else if (value === 0) {
    if (withZero) {
      return '0.0%';
    } else {
      return '0%';
    }
  }

  return value;
}

export function formatPrice(value) {
  if (value) {
    if (value >= 1000) {
      return value.toFixed(0);
    } else if (value >= 100) {
      return value.toFixed(1);
    } else if (value >= 10) {
      return value.toFixed(2);
    } else if (value >= 1) {
      return value.toFixed(3);
    } else {
      return value.toFixed(4);
    }
  }
  return value;
}

export function applyCompanyFilter(data, filterKey, filterValue, filters) {
  if (filterKey === 'isRussian' && filterValue && filters['isAsian']) {
    return data.filter((company) => (company.is_russian === filterValue || company.is_asian === true));
  } else if (filterKey === 'isAsian' && filterValue && filters['isRussian']) {
    return data.filter((company) => (company.is_asian === filterValue || company.is_russian === true));
  } else if (filterKey === 'isRussian') {
    return data.filter((company) => company.is_russian === filterValue);
  } else if (filterKey === 'isAsian') {
    return data.filter((company) => company.is_asian === filterValue);
  } else if (filterKey === 'isCovered') {
    return data.filter((company) => company.is_covered === filterValue);
  } else if (filterKey === 'withLargeCap') {
    return data.filter((company) => company.with_large_cap === filterValue);
  }
}

export function filterCompaniesData(filters, data) {
  if (data.length > 0) {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      if (filterValue) {
        data = applyCompanyFilter(data, filterKey, filterValue, filters);
      }
    }
  }
  return data;
}

export function applyCompanySorting(data, sortingKey, sortingValue) {
  if (sortingValue === 'desc') {
    if (sortingKey === 'Company name') {
      return data.sort(function(a, b) {
        return ('' + b.name).localeCompare(a.name);
      });
    } else if (sortingKey === 'Share price') {
      return data.sort(function(a, b) {
        return b.share.price - a.share.price;
      });
    } else if (sortingKey === 'Market Cap') {
      return data.sort(function(a, b) {
        return b.mcap_usd - a.mcap_usd;
      });
    } else if (sortingKey === 'EV EBITDA') {
      return data.sort(function(a, b) {
        return b.multipliers.EV_EBITDA - a.multipliers.EV_EBITDA;
      });
    } else if (sortingKey === 'PE') {
      return data.sort(function(a, b) {
        return b.multipliers.P_E - a.multipliers.P_E;
      });
    } else if (sortingKey === 'FCF yield') {
      return data.sort(function(a, b) {
        return b.multipliers.FCF_yield - a.multipliers.FCF_yield;
      });
    } else if (sortingKey === 'Div yield') {
      return data.sort(function(a, b) {
        return b.multipliers.Div_yield - a.multipliers.Div_yield;
      });
    }
  } else if (sortingValue === 'asc') {
    if (sortingKey === 'Company name') {
      return data.sort(function(a, b) {
        return ('' + a.name).localeCompare(b.name);
      });
    } else if (sortingKey === 'Share price') {
      return data.sort(function(a, b) {
        return a.share.price - b.share.price;
      });
    } else if (sortingKey === 'Market Cap') {
      return data.sort(function(a, b) {
        return a.mcap_usd - b.mcap_usd;
      });
    } else if (sortingKey === 'EV EBITDA') {
      return data.sort(function(a, b) {
        return a.multipliers.EV_EBITDA - b.multipliers.EV_EBITDA;
      });
    } else if (sortingKey === 'PE') {
      return data.sort(function(a, b) {
        return a.multipliers.P_E - b.multipliers.P_E;
      });
    } else if (sortingKey === 'FCF yield') {
      return data.sort(function(a, b) {
        return a.multipliers.FCF_yield - b.multipliers.FCF_yield;
      });
    } else if (sortingKey === 'Div yield') {
      return data.sort(function(a, b) {
        return a.multipliers.Div_yield - b.multipliers.Div_yield;
      });
    }
  }
}

export function sortingCompaniesData(sortingState, data) {
  if (data.length > 0) {
    if (sortingState[1] !== 'no') {
      data = applyCompanySorting(data, sortingState[0], sortingState[1]);
    }
  }
  return data;
}

export function adjustCompaniesData(filters, sortingState, data) {
  data = filterCompaniesData(filters, data);
  data = sortingCompaniesData(sortingState, data);

  return data;
}

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}

export function formatUTCDatetime(datetimeExpr, isMonthLowerCase, withHoursMinutes, withYear) {
  if (datetimeExpr)  {
    var datetimeObj = new Date(datetimeExpr.slice(0, -1));
    datetimeObj = datetimeObj.addHours(3);

    datetimeExpr = ('0' + datetimeObj.toLocaleDateString('en-us', {day: 'numeric'})).slice(-2);

    if (isMonthLowerCase) {
      datetimeExpr += ' ' + datetimeObj.toLocaleDateString('en-us', {month: 'short'}).toLowerCase();
    } else {
      datetimeExpr += ' ' + datetimeObj.toLocaleDateString('en-us', {month: 'short'});
    }

    if (withHoursMinutes)
      datetimeExpr += ' ' + ('0' + datetimeObj.getHours()).slice(-2) + ':' + ('0' + datetimeObj.getMinutes()).slice(-2);

    if (withYear)
      datetimeExpr += ' ' + datetimeObj.getFullYear();
  }
  return datetimeExpr;
}

export function formatDatetime(datetimeExpr, isMonthLowerCase, withHoursMinutes, withYear) {
  if (datetimeExpr)  {
    if (datetimeExpr.includes('Z')) {
      var datetimeObj = new Date(datetimeExpr.slice(0, -1));
    } else {
      var datetimeObj = new Date(datetimeExpr);
    }

    datetimeExpr = ('0' + datetimeObj.toLocaleDateString('en-us', {day: 'numeric'})).slice(-2);

    if (isMonthLowerCase) {
      datetimeExpr += ' ' + datetimeObj.toLocaleDateString('en-us', {month: 'short'}).toLowerCase();
    } else {
      datetimeExpr += ' ' + datetimeObj.toLocaleDateString('en-us', {month: 'short'});
    }

    if (withHoursMinutes)
      datetimeExpr += ' ' + ('0' + datetimeObj.getHours()).slice(-2) + ':' + ('0' + datetimeObj.getMinutes()).slice(-2);

    if (withYear)
      datetimeExpr += ' ' + datetimeObj.getFullYear();
  }

  return datetimeExpr;
}

export function formatCompanyData(value) {
  if (value) {
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'bn';
    } else {
      return value.toFixed(0) + 'mn';
    }
  }
  return value;
}

export function formatFinsData(formatting, value) {
  if (formatting === '#,0') {
    return formatFloats(value, 0, true);
  } else if (formatting === 'P0') {
    return formatPercents(value, false);
  } else if (formatting === 'P1') {
    return formatPercents(value, true);
  } else if (formatting === 'N1') {
    return formatFloats(value, 1, false);
  }
}

export function checkFinsPeriod(period) {
  if (period.includes('H') || period.includes('Q'))
    return true;
  return false;
}

export function getCurYearNums() {
  var datetimeObj = new Date();
  datetimeObj.setHours(datetimeObj.getHours() - 3);

  var yearNums = datetimeObj.getFullYear().toString().slice(-2);
  return yearNums;
}

export function getDatePart(datetimeObj, partType) {
  var datePart = null;

  if (!datetimeObj) {
    datetimeObj = new Date();
  }

  if (partType === 'day') {
    datePart = datetimeObj.getDate();
  } else if (partType === 'month') {
    datePart = datetimeObj.getMonth();
  } else if (partType === 'year') {
    datePart = datetimeObj.getFullYear();
  }

  return datePart;
}

export function formatDateObj(dateObj) {
  var day = dateObj.getDate().toString();
  var month = (dateObj.getMonth() + 1).toString();
  var year = dateObj.getFullYear().toString();

  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }

  return [year, month, day].join('-');
}

export function formatFilterDateObj(dateObj) {
  var day = dateObj.getDate().toString();
  var month = (dateObj.getMonth() + 1).toString();
  var year = dateObj.getFullYear().toString();

  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }

  return [day, month, year].join('.');
}

export function getFilterListOfDicts(listObj) {
  var ListOfDicts = [];
  var dictObj = {};

  listObj.forEach(item => {
    dictObj = {
      'key': item.key,
    }

    ListOfDicts.push(dictObj);
  });

  return ListOfDicts;
}

export function getEventEndISODateString(datetimeExpr) {
  var dateObj = new Date(datetimeExpr.slice(0, -1));
  dateObj.setUTCHours(23,59,59,999);
  return dateObj;
}