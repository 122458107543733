import { formatDatetime } from '../../common/formatting'

import ICalLink from './ICalLink';

function EventRow(props) {
  return (
    <tr>
      <td className={props.isRed ? 'sticky-col color-red': 'sticky-col'}>{formatDatetime(props.data.moment, true, false, false)}</td>
      <td>{props.data.event_type}</td>
      <td><a href={'/company/' + props.data.company_key + '/'}>{props.data.company_name}</a></td>
      <td>
        <div className="d-flex justify-content-between">
          <a href={'/event/' + props.data.id}>{props.data.header}</a>
          <ICalLink withClass={true} data={props.data} />
        </div>
      </td>
    </tr>
  );
}

export default EventRow;