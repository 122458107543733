import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';
import { handleSearchInputChange } from '../../common/handlers';

import SearchResult from './SearchResult';

import percent from '../../images/percent.png';
import person from '../../images/team/fullsize/DGlushakov.png';

function Header() {
  const location = useLocation();

  const [sectors, setSectors] = useState([]);
  const [foundSectors, setFoundSectors] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [foundCompanies, setFoundCompanies] = useState([]);

  const [commodities, setCommodities] = useState([]);
  const [foundCommodities, setFoundCommodities] = useState([]);

  const [commoditiesByIndustries, setCommoditiesByIndustries] = useState([]);
  const [foundCommoditiesByIndustries, setFoundCommoditiesByIndustries] = useState([]);

  const [warningText, setWarningText] = useState('');

  useEffect(() => {
    if (location.pathname !== '/chart')
      newAxiosInstance.get('option/sector/').then((res) => {
        var sortedData = res.data.sort(function(a, b) {
          return ('' + a.sector_name).localeCompare(b.sector_name);
        });
        var newCommodities = [];
        var commodity = {};

        sortedData.forEach(result => {
          var sector = {};
          Object.assign(sector, result);
          delete sector.commodities;

          result.commodities.forEach(item => {
            commodity = {
              'key': item.key,
              'ticker': item.key,
              'name': item.name,
              'levels': item.levels,
              'sector': sector,
            };
            newCommodities.push(commodity);
          });
        });

        setSectors(sortedData);
        setCommodities(newCommodities);
      });
  }, []);

  useEffect(() => {
    if (location.pathname !== '/chart')
      newAxiosInstance.get('option/company/').then((res) => {
        var filteredData = res.data.filter((item) => {
          return item.ev_ebitda;
        });

        setCompanies(filteredData);
      });
  }, []);

  useEffect(() => {
    if (location.pathname !== '/chart')
      newAxiosInstance.get('option/commodity-by-industry/').then((res) => {
        var newCommoditiesByIndustries = [];

        res.data.forEach(result => {
          result['l1'] = result.levels.l1;
          result['l2'] = result.levels.l2;
          result['l3'] = result.levels.l3;
          result['l4'] = result.levels.l4;
          result['l1_region'] = result.levels.l1 + ' ' + result.region;
          result['l2_region'] = result.levels.l2 + ' ' + result.region;
          result['l3_region'] = result.levels.l3 + ' ' + result.region;
          result['l4_region'] = result.levels.l4 + ' ' + result.region;
          newCommoditiesByIndustries.push(result);
        });

        setCommoditiesByIndustries(newCommoditiesByIndustries);
      });
  }, []);

  useEffect(() => {
    if (location.pathname === '/chart')
      document.body.style['padding-top'] = '0px';

      return () => {
        document.body.style['padding-top'] = '72px';
      };
  }, []);

  useEffect(()  => {
    if (location.pathname !== '/chart')
    document.body.classList.add('bg-black');

    return () => {
        document.body.classList.remove('bg-black');
    };
});

  function handleAggregateSearchInputChange(e) {
    setFoundCompanies([]);
    setFoundCommodities([]);

    if (e.target.value.length > 2) {
      setWarningText('');
      handleSearchInputChange(e, setFoundSectors, sectors, 'sector_name');
      handleSearchInputChange(e, setFoundCompanies, companies, null, ['name', 'ticker']);
      handleSearchInputChange(e, setFoundCommodities, commodities, null, ['name', 'ticker']);
      handleSearchInputChange(e, setFoundCommoditiesByIndustries, commoditiesByIndustries, null,
       ['name', 'ticker', 'l1', 'l2', 'l3', 'l4', 'l1_region', 'l2_region', 'l3_region', 'l4_region']);
    } else if (e.target.value.length > 0 && e.target.value.length <= 2) {
      setWarningText('Type at least 3 characters.');
    } else {
      setWarningText('');
    }
  }

  if (location.pathname === '/') {
    return (
      <div className="app dark-theme page-metals-mining page-product">
        <div className="wrapper">
          <header className="header">
            <div className="container">
              <div className="header__inner">
                <div className="header__logo">
                </div>
                <div className="header__right" style={{ width: '100%' }}>
                  <div className={window.matchMedia('(max-width: 899px)').matches ? "header__search-wrap dropdown search-bar": "header__search-wrap dropdown search-bar state-open"} style={{ width: '100%' }}>
                    <div className="header__search-button dropdown__button js-dropdown-button">
                      <i className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.4996 13.9996H14.7096L14.4296 13.7296C15.6296 12.3296 16.2496 10.4196 15.9096 8.38965C15.4396 5.60965 13.1196 3.38965 10.3196 3.04965C6.08965 2.52965 2.52965 6.08965 3.04965 10.3196C3.38965 13.1196 5.60965 15.4396 8.38965 15.9096C10.4196 16.2496 12.3296 15.6296 13.7296 14.4296L13.9996 14.7096V15.4996L18.2496 19.7496C18.6596 20.1596 19.3296 20.1596 19.7396 19.7496C20.1496 19.3396 20.1496 18.6696 19.7396 18.2596L15.4996 13.9996ZM9.49965 13.9996C7.00965 13.9996 4.99965 11.9896 4.99965 9.49965C4.99965 7.00965 7.00965 4.99965 9.49965 4.99965C11.9896 4.99965 13.9996 7.00965 13.9996 9.49965C13.9996 11.9896 11.9896 13.9996 9.49965 13.9996Z"
                            fill="#606980"/>
                        </svg>
                      </i>
                    </div>
                    <div className="header__search search dropdown__menu">
                      <div className="search__head">
                        <div className="search__head-logo">
                        </div>
                        <div className="search__close js-header-search-close">
                          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0.778245 1.75116C0.389336 2.13959 0.389336 2.7752 0.778245 3.16363L12.092 14.4634C12.4809 14.8519 13.1173 14.8519 13.5062 14.4634C13.8951 14.075 13.8951 13.4394 13.5062 13.0509L2.19246 1.75116C1.80355 1.36273 1.16715 1.36273 0.778245 1.75116Z"
                              fill="#606980"/>
                            <path
                              d="M13.435 3.09199L2.12132 14.3918C1.73241 14.7802 1.09602 14.7802 0.707107 14.3918C0.318198 14.0034 0.318198 13.3677 0.707107 12.9793L12.0208 1.67952C12.4097 1.29109 13.0461 1.29109 13.435 1.67952C13.8239 2.06795 13.8239 2.70356 13.435 3.09199Z"
                              fill="#606980"/>
                          </svg>
                        </div>
                      </div>
                      <div className="search__inner">
                        <form action="#">
                          <div className="search__row" style={{ border: 'solid 0.5px black', borderRadius: '8px' }}>
                            <input className="search__field form-control" type="text" placeholder="Search" onChange={ (e) => handleAggregateSearchInputChange(e) } />
                            <button className="search__button">
                              <i className="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.4996 14.0006H14.7096L14.4296 13.7306C15.6296 12.3306 16.2496 10.4206 15.9096 8.39063C15.4396 5.61063 13.1196 3.39063 10.3196 3.05063C6.08965 2.53063 2.52965 6.09063 3.04965 10.3206C3.38965 13.1206 5.60965 15.4406 8.38965 15.9106C10.4196 16.2506 12.3296 15.6306 13.7296 14.4306L13.9996 14.7106V15.5006L18.2496 19.7506C18.6596 20.1606 19.3296 20.1606 19.7396 19.7506C20.1496 19.3406 20.1496 18.6706 19.7396 18.2606L15.4996 14.0006ZM9.49965 14.0006C7.00965 14.0006 4.99965 11.9906 4.99965 9.50063C4.99965 7.01063 7.00965 5.00063 9.49965 5.00063C11.9896 5.00063 13.9996 7.01063 13.9996 9.50063C13.9996 11.9906 11.9896 14.0006 9.49965 14.0006Z"
                                    fill="#606980"/>
                                </svg>
                              </i>
                            </button>
                          </div>
                          <div className="search__result scrollable">
                            <ul>
                              <SearchResult warningText={warningText} sectorsData={foundSectors} companiesData={foundCompanies} commoditiesData={foundCommodities} commoditiesByIndustriesData={foundCommoditiesByIndustries} />
                            </ul>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="hero">
            <div className="container">
              <div className="hero__inner">
                <div className="hero__description">
                  <div className="breadcrumbs">
                    <ul>
                      <li><a href="/">Main</a></li>
                      <li><span aria-current="page">Metals Wire</span></li>
                    </ul>
                  </div>
                  <div className="hero__title">
                    <h1><a href="/" style={{ color: "white" }}>Metals Wire</a></h1>
                  </div>
                  <div className="hero__menu">
                    <ul>
                      <li><a className="state-selected" href="/">Main</a></li>
                      <li><a href="/equities">Equities</a></li>
                      <li><a href="/data">Data</a></li>
                      <li><a href="/news-reports">News & Reports</a></li>
                      <li><a href="/events">Events</a></li>
                      <li><a href="/chart-tool">Chart Tool</a></li>
                    </ul>
                  </div>
                </div>
                <div className="hero__person">
                  <div className="hero__percent">
                    <img src={percent} srcSet={percent + ' 2x'}/>
                  </div>
                  <div className="hero__person-img">
                    <img src={person} srcSet={person + ' 2x'}
                         alt="Petr Grishin" />
                  </div>
                  <div className="hero__person-info">
                    <div className="hero__person-name">
                      <span>Dmitry</span>
                      Glushakov, CFA
                    </div>
                    <div className="hero__person-position">
                      Head of Metals Wire Research
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if ((location.pathname.includes('/equities')) || (location.pathname.includes('/data') && (!location.pathname.includes('/data-chart')))) {
    return (
      <React.Fragment>
        <header className="header">
          <div className="container">
            <div className="header__inner">
              <div className="header__logo">
              </div>
              <div className="header__right" style={{ width: '100%' }}>
                <div className={window.matchMedia('(max-width: 899px)').matches ? "header__search-wrap dropdown search-bar": "header__search-wrap dropdown search-bar state-open"} style={{ width: '100%' }}>
                  <div className="header__search-button dropdown__button js-dropdown-button">
                    <i className="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.4996 13.9996H14.7096L14.4296 13.7296C15.6296 12.3296 16.2496 10.4196 15.9096 8.38965C15.4396 5.60965 13.1196 3.38965 10.3196 3.04965C6.08965 2.52965 2.52965 6.08965 3.04965 10.3196C3.38965 13.1196 5.60965 15.4396 8.38965 15.9096C10.4196 16.2496 12.3296 15.6296 13.7296 14.4296L13.9996 14.7096V15.4996L18.2496 19.7496C18.6596 20.1596 19.3296 20.1596 19.7396 19.7496C20.1496 19.3396 20.1496 18.6696 19.7396 18.2596L15.4996 13.9996ZM9.49965 13.9996C7.00965 13.9996 4.99965 11.9896 4.99965 9.49965C4.99965 7.00965 7.00965 4.99965 9.49965 4.99965C11.9896 4.99965 13.9996 7.00965 13.9996 9.49965C13.9996 11.9896 11.9896 13.9996 9.49965 13.9996Z"
                          fill="#606980"/>
                      </svg>
                    </i>
                  </div>
                  <div className="header__search search dropdown__menu">
                    <div className="search__head">
                      <div className="search__head-logo">
                      </div>
                      <div className="search__close js-header-search-close">
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.778245 1.75116C0.389336 2.13959 0.389336 2.7752 0.778245 3.16363L12.092 14.4634C12.4809 14.8519 13.1173 14.8519 13.5062 14.4634C13.8951 14.075 13.8951 13.4394 13.5062 13.0509L2.19246 1.75116C1.80355 1.36273 1.16715 1.36273 0.778245 1.75116Z"
                            fill="#606980"/>
                          <path
                            d="M13.435 3.09199L2.12132 14.3918C1.73241 14.7802 1.09602 14.7802 0.707107 14.3918C0.318198 14.0034 0.318198 13.3677 0.707107 12.9793L12.0208 1.67952C12.4097 1.29109 13.0461 1.29109 13.435 1.67952C13.8239 2.06795 13.8239 2.70356 13.435 3.09199Z"
                            fill="#606980"/>
                        </svg>
                      </div>
                    </div>
                    <div className="search__inner">
                      <form action="#">
                        <div className="search__row" style={{ border: 'solid 0.5px black', borderRadius: '8px' }}>
                          <input className="search__field form-control" type="text" placeholder="Search" onChange={ (e) => handleAggregateSearchInputChange(e) } />
                          <button className="search__button">
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4996 14.0006H14.7096L14.4296 13.7306C15.6296 12.3306 16.2496 10.4206 15.9096 8.39063C15.4396 5.61063 13.1196 3.39063 10.3196 3.05063C6.08965 2.53063 2.52965 6.09063 3.04965 10.3206C3.38965 13.1206 5.60965 15.4406 8.38965 15.9106C10.4196 16.2506 12.3296 15.6306 13.7296 14.4306L13.9996 14.7106V15.5006L18.2496 19.7506C18.6596 20.1606 19.3296 20.1606 19.7396 19.7506C20.1496 19.3406 20.1496 18.6706 19.7396 18.2606L15.4996 14.0006ZM9.49965 14.0006C7.00965 14.0006 4.99965 11.9906 4.99965 9.50063C4.99965 7.01063 7.00965 5.00063 9.49965 5.00063C11.9896 5.00063 13.9996 7.01063 13.9996 9.50063C13.9996 11.9906 11.9896 14.0006 9.49965 14.0006Z"
                                  fill="#606980"/>
                              </svg>
                            </i>
                          </button>
                        </div>
                        <div className="search__result scrollable">
                          <ul>
                            <SearchResult warningText={warningText} sectorsData={foundSectors} companiesData={foundCompanies} commoditiesData={foundCommodities} commoditiesByIndustriesData={foundCommoditiesByIndustries} />
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="hero">
          <div className="container">
            <div className="hero__inner">
              <div className="hero__description">
                <div className="hero__title">
                  <div className="dropdown">
                    <div className="dropdown__button js-dropdown-button">
                      <h1><a href="/" style={{ color: "black" }}>Metals Wire</a></h1>
                    </div>
                  </div>
                </div>
                <div className="hero__menu">
                  <ul>
                    <li><a href="/">Main</a></li>
                    <li><a className={location.pathname.includes('/equities') ? 'state-selected': null} href="/equities">Equities</a></li>
                    <li><a className={location.pathname.includes('/equities') ? null: 'state-selected'} href="/data">Data</a></li>
                    <li><a href="/news-reports">News & Reports</a></li>
                    <li><a href="/events">Events</a></li>
                    <li><a href="/chart-tool">Chart Tool</a></li>
                  </ul>
                </div>
                <div className="breadcrumbs">
                  <ul>
                    <li><a href="/">Main</a></li>
                    { location.pathname.includes('/equities') ? <li><span aria-current="page">Equities</span></li>: <li><span aria-current="page">Data</span></li> }
                  </ul>
                </div>
                { location.pathname.includes('/equities') ? <h1>Equities</h1>: <h1>Data overview</h1> }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (location.pathname === '/news-reports') {
    return (
      <React.Fragment>
        <header className="header">
          <div className="container">
            <div className="header__inner">
              <div className="header__logo">
              </div>
              <div className="header__right" style={{ width: '100%' }}>
                <div className={window.matchMedia('(max-width: 899px)').matches ? "header__search-wrap dropdown search-bar": "header__search-wrap dropdown search-bar state-open"} style={{ width: '100%' }}>
                  <div className="header__search-button dropdown__button js-dropdown-button">
                    <i className="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.4996 13.9996H14.7096L14.4296 13.7296C15.6296 12.3296 16.2496 10.4196 15.9096 8.38965C15.4396 5.60965 13.1196 3.38965 10.3196 3.04965C6.08965 2.52965 2.52965 6.08965 3.04965 10.3196C3.38965 13.1196 5.60965 15.4396 8.38965 15.9096C10.4196 16.2496 12.3296 15.6296 13.7296 14.4296L13.9996 14.7096V15.4996L18.2496 19.7496C18.6596 20.1596 19.3296 20.1596 19.7396 19.7496C20.1496 19.3396 20.1496 18.6696 19.7396 18.2596L15.4996 13.9996ZM9.49965 13.9996C7.00965 13.9996 4.99965 11.9896 4.99965 9.49965C4.99965 7.00965 7.00965 4.99965 9.49965 4.99965C11.9896 4.99965 13.9996 7.00965 13.9996 9.49965C13.9996 11.9896 11.9896 13.9996 9.49965 13.9996Z"
                          fill="#606980"/>
                      </svg>
                    </i>
                  </div>
                  <div className="header__search search dropdown__menu">
                    <div className="search__head">
                      <div className="search__head-logo">
                      </div>
                      <div className="search__close js-header-search-close">
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.778245 1.75116C0.389336 2.13959 0.389336 2.7752 0.778245 3.16363L12.092 14.4634C12.4809 14.8519 13.1173 14.8519 13.5062 14.4634C13.8951 14.075 13.8951 13.4394 13.5062 13.0509L2.19246 1.75116C1.80355 1.36273 1.16715 1.36273 0.778245 1.75116Z"
                            fill="#606980"/>
                          <path
                            d="M13.435 3.09199L2.12132 14.3918C1.73241 14.7802 1.09602 14.7802 0.707107 14.3918C0.318198 14.0034 0.318198 13.3677 0.707107 12.9793L12.0208 1.67952C12.4097 1.29109 13.0461 1.29109 13.435 1.67952C13.8239 2.06795 13.8239 2.70356 13.435 3.09199Z"
                            fill="#606980"/>
                        </svg>
                      </div>
                    </div>
                    <div className="search__inner">
                      <form action="#">
                        <div className="search__row" style={{ border: 'solid 0.5px black', borderRadius: '8px' }}>
                          <input className="search__field form-control" type="text" placeholder="Search" onChange={ (e) => handleAggregateSearchInputChange(e) } />
                          <button className="search__button">
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4996 14.0006H14.7096L14.4296 13.7306C15.6296 12.3306 16.2496 10.4206 15.9096 8.39063C15.4396 5.61063 13.1196 3.39063 10.3196 3.05063C6.08965 2.53063 2.52965 6.09063 3.04965 10.3206C3.38965 13.1206 5.60965 15.4406 8.38965 15.9106C10.4196 16.2506 12.3296 15.6306 13.7296 14.4306L13.9996 14.7106V15.5006L18.2496 19.7506C18.6596 20.1606 19.3296 20.1606 19.7396 19.7506C20.1496 19.3406 20.1496 18.6706 19.7396 18.2606L15.4996 14.0006ZM9.49965 14.0006C7.00965 14.0006 4.99965 11.9906 4.99965 9.50063C4.99965 7.01063 7.00965 5.00063 9.49965 5.00063C11.9896 5.00063 13.9996 7.01063 13.9996 9.50063C13.9996 11.9906 11.9896 14.0006 9.49965 14.0006Z"
                                  fill="#606980"/>
                              </svg>
                            </i>
                          </button>
                        </div>
                        <div className="search__result scrollable">
                          <ul>
                            <SearchResult warningText={warningText} sectorsData={foundSectors} companiesData={foundCompanies} commoditiesData={foundCommodities} commoditiesByIndustriesData={foundCommoditiesByIndustries} />
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="hero">
          <div className="container">
            <div className="hero__inner">
              <div className="hero__description">
                <div className="breadcrumbs">
                  <ul>
                    <li><a href="/">Main</a></li>
                    <li><span aria-current="page">News & Reports</span></li>
                  </ul>
                </div>
                <h1 className="hidden-mobile" style={{ marginBottom: '0px' }}>News</h1>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (location.pathname === '/events') {
    return (
      <div className="app dark-theme page-metals-mining page-product">
        <div className="wrapper">
          <header className="header">
            <div className="container">
              <div className="header__inner">
                <div className="header__logo">
                </div>
                <div className="header__right" style={{ width: '100%' }}>
                  <div className={window.matchMedia('(max-width: 899px)').matches ? "header__search-wrap dropdown search-bar": "header__search-wrap dropdown search-bar state-open"} style={{ width: '100%' }}>
                    <div className="header__search-button dropdown__button js-dropdown-button">
                      <i className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.4996 13.9996H14.7096L14.4296 13.7296C15.6296 12.3296 16.2496 10.4196 15.9096 8.38965C15.4396 5.60965 13.1196 3.38965 10.3196 3.04965C6.08965 2.52965 2.52965 6.08965 3.04965 10.3196C3.38965 13.1196 5.60965 15.4396 8.38965 15.9096C10.4196 16.2496 12.3296 15.6296 13.7296 14.4296L13.9996 14.7096V15.4996L18.2496 19.7496C18.6596 20.1596 19.3296 20.1596 19.7396 19.7496C20.1496 19.3396 20.1496 18.6696 19.7396 18.2596L15.4996 13.9996ZM9.49965 13.9996C7.00965 13.9996 4.99965 11.9896 4.99965 9.49965C4.99965 7.00965 7.00965 4.99965 9.49965 4.99965C11.9896 4.99965 13.9996 7.00965 13.9996 9.49965C13.9996 11.9896 11.9896 13.9996 9.49965 13.9996Z"
                            fill="#606980"/>
                        </svg>
                      </i>
                    </div>
                    <div className="header__search search dropdown__menu">
                      <div className="search__head">
                        <div className="search__head-logo">
                        </div>
                        <div className="search__close js-header-search-close">
                          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0.778245 1.75116C0.389336 2.13959 0.389336 2.7752 0.778245 3.16363L12.092 14.4634C12.4809 14.8519 13.1173 14.8519 13.5062 14.4634C13.8951 14.075 13.8951 13.4394 13.5062 13.0509L2.19246 1.75116C1.80355 1.36273 1.16715 1.36273 0.778245 1.75116Z"
                              fill="#606980"/>
                            <path
                              d="M13.435 3.09199L2.12132 14.3918C1.73241 14.7802 1.09602 14.7802 0.707107 14.3918C0.318198 14.0034 0.318198 13.3677 0.707107 12.9793L12.0208 1.67952C12.4097 1.29109 13.0461 1.29109 13.435 1.67952C13.8239 2.06795 13.8239 2.70356 13.435 3.09199Z"
                              fill="#606980"/>
                          </svg>
                        </div>
                      </div>
                      <div className="search__inner">
                        <form action="#">
                          <div className="search__row" style={{ border: 'solid 0.5px black', borderRadius: '8px' }}>
                            <input className="search__field form-control" type="text" placeholder="Search" onChange={ (e) => handleAggregateSearchInputChange(e) } />
                            <button className="search__button">
                              <i className="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.4996 14.0006H14.7096L14.4296 13.7306C15.6296 12.3306 16.2496 10.4206 15.9096 8.39063C15.4396 5.61063 13.1196 3.39063 10.3196 3.05063C6.08965 2.53063 2.52965 6.09063 3.04965 10.3206C3.38965 13.1206 5.60965 15.4406 8.38965 15.9106C10.4196 16.2506 12.3296 15.6306 13.7296 14.4306L13.9996 14.7106V15.5006L18.2496 19.7506C18.6596 20.1606 19.3296 20.1606 19.7396 19.7506C20.1496 19.3406 20.1496 18.6706 19.7396 18.2606L15.4996 14.0006ZM9.49965 14.0006C7.00965 14.0006 4.99965 11.9906 4.99965 9.50063C4.99965 7.01063 7.00965 5.00063 9.49965 5.00063C11.9896 5.00063 13.9996 7.01063 13.9996 9.50063C13.9996 11.9906 11.9896 14.0006 9.49965 14.0006Z"
                                    fill="#606980"/>
                                </svg>
                              </i>
                            </button>
                          </div>
                          <div className="search__result scrollable">
                            <ul>
                              <SearchResult warningText={warningText} sectorsData={foundSectors} companiesData={foundCompanies} commoditiesData={foundCommodities} commoditiesByIndustriesData={foundCommoditiesByIndustries} />
                            </ul>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  } else if (location.pathname === '/chart') {
    return (
      null
    );
  } else {
    return (
      <React.Fragment>
        <header className="header">
          <div className="container">
            <div className="header__inner">
              <div className="header__logo">
              </div>
              <div className="header__right" style={{ width: '100%' }}>
                <div className={window.matchMedia('(max-width: 899px)').matches ? "header__search-wrap dropdown search-bar": "header__search-wrap dropdown search-bar state-open"} style={{ width: '100%' }}>
                  <div className="header__search-button dropdown__button js-dropdown-button">
                    <i className="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.4996 13.9996H14.7096L14.4296 13.7296C15.6296 12.3296 16.2496 10.4196 15.9096 8.38965C15.4396 5.60965 13.1196 3.38965 10.3196 3.04965C6.08965 2.52965 2.52965 6.08965 3.04965 10.3196C3.38965 13.1196 5.60965 15.4396 8.38965 15.9096C10.4196 16.2496 12.3296 15.6296 13.7296 14.4296L13.9996 14.7096V15.4996L18.2496 19.7496C18.6596 20.1596 19.3296 20.1596 19.7396 19.7496C20.1496 19.3396 20.1496 18.6696 19.7396 18.2596L15.4996 13.9996ZM9.49965 13.9996C7.00965 13.9996 4.99965 11.9896 4.99965 9.49965C4.99965 7.00965 7.00965 4.99965 9.49965 4.99965C11.9896 4.99965 13.9996 7.00965 13.9996 9.49965C13.9996 11.9896 11.9896 13.9996 9.49965 13.9996Z"
                          fill="#606980"/>
                      </svg>
                    </i>
                  </div>
                  <div className="header__search search dropdown__menu">
                    <div className="search__head">
                      <div className="search__head-logo">
                      </div>
                      <div className="search__close js-header-search-close">
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.778245 1.75116C0.389336 2.13959 0.389336 2.7752 0.778245 3.16363L12.092 14.4634C12.4809 14.8519 13.1173 14.8519 13.5062 14.4634C13.8951 14.075 13.8951 13.4394 13.5062 13.0509L2.19246 1.75116C1.80355 1.36273 1.16715 1.36273 0.778245 1.75116Z"
                            fill="#606980"/>
                          <path
                            d="M13.435 3.09199L2.12132 14.3918C1.73241 14.7802 1.09602 14.7802 0.707107 14.3918C0.318198 14.0034 0.318198 13.3677 0.707107 12.9793L12.0208 1.67952C12.4097 1.29109 13.0461 1.29109 13.435 1.67952C13.8239 2.06795 13.8239 2.70356 13.435 3.09199Z"
                            fill="#606980"/>
                        </svg>
                      </div>
                    </div>
                    <div className="search__inner">
                      <form action="#">
                        <div className="search__row" style={{ border: 'solid 0.5px black', borderRadius: '8px' }}>
                          <input className="search__field form-control" type="text" placeholder="Search" onChange={ (e) => handleAggregateSearchInputChange(e) } />
                          <button className="search__button">
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4996 14.0006H14.7096L14.4296 13.7306C15.6296 12.3306 16.2496 10.4206 15.9096 8.39063C15.4396 5.61063 13.1196 3.39063 10.3196 3.05063C6.08965 2.53063 2.52965 6.09063 3.04965 10.3206C3.38965 13.1206 5.60965 15.4406 8.38965 15.9106C10.4196 16.2506 12.3296 15.6306 13.7296 14.4306L13.9996 14.7106V15.5006L18.2496 19.7506C18.6596 20.1606 19.3296 20.1606 19.7396 19.7506C20.1496 19.3406 20.1496 18.6706 19.7396 18.2606L15.4996 14.0006ZM9.49965 14.0006C7.00965 14.0006 4.99965 11.9906 4.99965 9.50063C4.99965 7.01063 7.00965 5.00063 9.49965 5.00063C11.9896 5.00063 13.9996 7.01063 13.9996 9.50063C13.9996 11.9906 11.9896 14.0006 9.49965 14.0006Z"
                                  fill="#606980"/>
                              </svg>
                            </i>
                          </button>
                        </div>
                        <div className="search__result scrollable">
                          <ul>
                            <SearchResult warningText={warningText} sectorsData={foundSectors} companiesData={foundCompanies} commoditiesData={foundCommodities} commoditiesByIndustriesData={foundCommoditiesByIndustries} />
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="hero">
          <div className="container">
            <div className="hero__inner">
              <div className="hero__description">
                <div className="hero__title">
                  <div className="dropdown">
                    <div className="dropdown__button js-dropdown-button">
                      <h1><a href="/" style={{ color: "black" }}>Metals Wire</a></h1>
                    </div>
                  </div>
                </div>
                <div className="hero__menu">
                  <ul>
                    <li><a href="/">Main</a></li>
                    <li><a className={location.pathname.includes('/sector') || location.pathname.includes('/company') ? 'state-selected': null} href="/equities">Equities</a></li>
                    <li><a className={location.pathname.includes('/data-chart') ? 'state-selected': null} href="/data">Data</a></li>
                    <li><a href="/news-reports">News & Reports</a></li>
                    <li><a href="/events">Events</a></li>
                    <li><a href="/chart-tool">Chart Tool</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;