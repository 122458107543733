import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { widget } from '../../charting_library';
import Datafeed from './datafeed';

import Currency from './Currency';
import Scale from './Scale';
import DriversList from './DriversList';
import RelatedCompsList from './RelatedCompsList';
import Multiplier from './Multiplier';

function CompanyChart(props) {
  const [tvWidget, setTVWidget] = useState(null);
  const ref = useRef(null);

  const [isWidgetReady, setIsWidgetReady] = useState(false);
  const [isRelatedView, setIsRelatedView] = useState(false);
  const [isPercentScale, setIsPercentScale] = useState(false);

  const [sortedDrivers, setSortedDrivers] = useState([]);
  const [sortedCompanies, setSortedCompanies] = useState([]);

  const [ip, setIP] = useState('');
  const [showCSVButton, setShowCSVButton] = useState(false);
  const possibleIPs = ['2.63.205.2', '195.248.69.45'];

  const getIP = async() => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    if (possibleIPs.includes(ip))
      setShowCSVButton(true);
  }, [ip]);

  const defaultOptions = {
		interval: 'D',

		timeframe: '12M',
		timezone: 'Etc/UTC',

    debug: false,
		libraryPath: '/charting_library/',

    width: '100%',
    height: 500,
		fullscreen: false,
		autosize: false,

		charts_storage_url: 'https://metals-wire.com/api/v2/charts',
    client_id: 'metals-wire.com',
    user_id: 'public_user_id',
    charts_storage_api_version: '1.1'
	};

  function getCurDayOfYear() {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    return day;
  }

  useEffect(() => {
    if (tvWidget === null) {
      var widgetOptions = {
        symbol: props.symbol,
        interval: defaultOptions.interval,
        container: ref.current,
        datafeed: Datafeed,

        timeframe: defaultOptions.timeframe,
        timezone: defaultOptions.timezone,

        debug: defaultOptions.debug,
        library_path: defaultOptions.libraryPath,

        width: defaultOptions.width,
        height: defaultOptions.height,
        fullscreen: defaultOptions.fullscreen,
        autosize: defaultOptions.autosize,

        locale: 'en',

        time_frames: [
          {text: getCurDayOfYear().toString() + 'd', resolution: '1D', title: 'YTD'},
          {text: '1y', resolution: '1D'},
          {text: '2y', resolution: '1D'},
          {text: '5y', resolution: '1D'},
          {text: '10y', resolution: '1D'},
        ],

        charts_storage_url: defaultOptions.charts_storage_url,
        client_id: defaultOptions.client_id,
        user_id: defaultOptions.user_id,
        charts_storage_api_version: defaultOptions.charts_storage_api_version,

        disabled_features: ['header_saveload', 'header_widget'],
        enabled_features: ['study_overlay_compare_legend_option', ],
      };

      setTVWidget(new widget(widgetOptions));

      return () => {
        setTVWidget((prevState) => {
          if (prevState !== null) {
            prevState.remove();
            return null;
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (tvWidget !== null) {
      tvWidget.onChartReady(function() {
        setIsWidgetReady(true);
        tvWidget.activeChart().onSymbolChanged().subscribe(null, handleSymbolChange);
      });
    }
  }, [tvWidget]);

  useEffect(() => {
    var oldDrivers = structuredClone(props.drivers);
    var newSortedDrivers = oldDrivers.sort(function(a, b) {
      return Math.abs(b.sens_ebitda) - Math.abs(a.sens_ebitda);
    });
    setSortedDrivers(newSortedDrivers);

  }, [props.drivers]);

  useEffect(() => {
    var newSortedCompanies = props.relatedCompanies.sort(function(a, b) {
        return b.mcap_usd - a.mcap_usd;
    });

    if (props.isRussian) {
      newSortedCompanies = newSortedCompanies.sort(function(a, b) {
        return Number(a.is_russian) - Number(b.is_russian);
      });
    } else {
      newSortedCompanies = newSortedCompanies.sort(function(a, b) {
        return Number(b.is_russian) - Number(a.is_russian);
      });
    }
    setSortedCompanies(newSortedCompanies);

  }, [props.relatedCompanies]);

  function handleSymbolChange() {
    setIsRelatedView(prevState => {
      if (tvWidget.activeChart().symbol().split(':')[1] === props.symbol) {
        return false;
      } else {
        return true;
      }
    });
  }

  function handlePercentScaleClick(e) {
    e.preventDefault();

    setIsPercentScale(prevState => {
      if (prevState) {
        tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0].setMode(0);
        return false;
      } else {
        tvWidget.activeChart().getPanes()[0].getRightPriceScales()[0].setMode(2);
        return true;
      }
    });
  }

  function handleCSVButtonClick(e) {
    e.preventDefault();

    var tickers = [props.symbol];

    if (props.currency !== 'USD')
      tickers.push(props.symbol + ' USD');

    tvWidget.activeChart().getAllStudies().forEach(({ id }) => {
      if (tvWidget.activeChart().getStudyById(id).isVisible()) {
        if (!!tvWidget.activeChart().getStudyById(id).getInputValues()[1])
          tickers.push(tvWidget.activeChart().getStudyById(id).getInputValues()[1].value.split(':')[1]);
      }
    });

    window.location.href = 'https://metals-wire.com/tv-csv-file/'+ tickers.join();
  }

  return (
    <div className="col col-100">
      <div className="card card-white">
        <div className="card__head" style={{ marginBottom: '10px' }}>
          <div className="card__head-name">
            <div>
              <span style={{ paddingRight: '3px' }}>
                Share price chart
              </span>
              <ul className="tags" style={{ display: 'inline-block', paddingRight: '3px'}}>
                <Scale widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} clickHandler={handlePercentScaleClick} />
              </ul>
              <ul className="tags" style={{ display: 'inline-block', paddingRight: '3px'}}>
                { props.currency !== 'USD' &&
                  <Currency widget={tvWidget} isWidgetReady={isWidgetReady} isRelatedView={isRelatedView} ticker={props.symbol} USDTicker={props.symbol + ' USD'} />
                }
              </ul>
            </div>
          </div>
          <ul className="tags" style={{ display: 'inline-block'}}>
            { showCSVButton && isWidgetReady &&
              <li className={"state-selected"} style={{ display: 'block'}}><a href="#" onClick={handleCSVButtonClick}>{'Export data'}</a></li>
            }
          </ul>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <DriversList sortedDrivers={sortedDrivers} tvWidget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} companyKey={props.companyKey} symbol={props.symbol} />
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <RelatedCompsList sortedCompanies={sortedCompanies} tvWidget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} symbol={props.symbol} />
        </div>
        <div style={{ paddingBottom: '20px' }}>
          <ul className="tags" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.companyKey + ' EV_EBITDA'} name={'Spot EV/EBITDA'} />
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.sector + ' EV_EBITDA'} name={'Sector spot avg EV/EBITDA'} />
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.companyKey + ' EBITDA'} name={'Spot EBITDA'} />
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.companyKey + ' P_E'} name={'Spot P/E'} />
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.companyKey + ' FCF_yield'} name={'Spot FCF yield'} />
            <Multiplier elDisplay={'block'} widget={tvWidget} isWidgetReady={isWidgetReady} isPercentScale={isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={props.companyKey + ' Div_yield'} name={'Spot Div yield'} />
          </ul>
        </div>
        <div>
          <div ref={ ref } />
        </div>
      </div>
    </div>
  );

}

export default CompanyChart;