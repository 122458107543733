export function handleClick(e, withPreventDefault, keySetFunc, namesDict, nameSetFunc) {
  if (withPreventDefault)
    e.preventDefault();

  keySetFunc(e.target.getAttribute('data-value'));

  if (namesDict)
    nameSetFunc(namesDict[e.target.getAttribute('data-value')]);
}

export function handleSectionClick(e, setFunc) {
  setFunc(prevState => {
    if (prevState) {
      return prevState;
    } else {
      return true;
    }
  });
};

export function checkListOfDictsHasKey(listOfDicts, key, value) {
  var jsonString = JSON.stringify(listOfDicts);
  return jsonString.indexOf('"'+key+'":"'+value+'"') > -1;
}

export function handleCheckBoxClick(e, setFunc, key, name) {
  setFunc(prevState => {
    if (checkListOfDictsHasKey(prevState, 'key', key)) {
      return [ ...prevState.filter(item => item.key !== key) ];
    } else {
      return [ ...prevState, {'key': key, 'name': name} ];
    }
  });
};

export function handleSearchInputChange(e, setFunc, initialData, searchKey, searchKeys = null) {
  var newData = [];

  if (e.target.value.length > 0) {
    newData = initialData.filter((item) => {
      if (searchKeys) {
        var searchValues = [];
        searchKeys.forEach(key => {
          if (item[key] && item[key].toLowerCase().includes(e.target.value.toLowerCase()))
            searchValues.push(item[key].toLowerCase());
        });

        if (searchValues.length > 0)
          return true;
        return false;
      } else {
        return item[searchKey].toLowerCase().includes(e.target.value.toLowerCase());
      }
    });
    setFunc(newData);
  } else {
    if (initialData)
      setFunc(initialData);
  }
};