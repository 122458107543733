import { formatFinsData } from '../../common/formatting'

function RepTableRow(props) {
  function getDiff() {
    if (props.data.name.endsWith(' yield') || props.data.name == 'margin') {
      return formatFinsData('P1', props.data.ref_values[props.repPeriod] - props.data.values[props.repPeriod]);
    } else {
      return formatFinsData('P1', props.data.ref_values[props.repPeriod] / props.data.values[props.repPeriod] - 1);
    }
  };

  function handleRowClick(e) {
    props.rowHandler(props.data.ref_ticker);
  }

  return (
    <tr className={props.repScenario == props.data.ref_ticker ? "state-selected": null} onClick={(e) => handleRowClick(e)}>
      <td>{props.data.name}</td>
      <td>{formatFinsData(props.data.formatting, props.data.ref_values[props.repPeriod])}</td>
      <td>{formatFinsData(props.data.formatting, props.data.values[props.repPeriod])}</td>
      <td>{ (props.data.ref_values[props.repPeriod] && props.data.values[props.repPeriod]) ? getDiff(): null}</td>
    </tr>
  );
}

export default RepTableRow;