import { useState, useEffect } from 'react';
import axiosInstance from '../../axios/instance';
import NewsItem from '../../common/components/NewsItem';

function LastNews(props) {
  const [lastNews, setLastNews] = useState([]);

  useEffect(() => {
    axiosInstance.get('news/' + props.newsType + '/' + props.objectKey + '/?limit=3&offset=0').then((res) => {
      if (res.data.results.length > 0)
        setLastNews(res.data.results);
    });
  }, [props.newsType, props.objectKey]);

  if (lastNews.length > 0) {
    return (
      <section className="section news news-horizontal">
        <div className="section__head">
          <div className="section__title">Last news</div>
        </div>
        <div className="news__list">
          {lastNews.map(news => {
            return <NewsItem key={news.id} newsID={news.id} moment={news.moment} header={news.header} />
          })}
        </div>
      </section>
    );
  }
}

export default LastNews;