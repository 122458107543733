import { ICalendar } from 'datebook';

import { getEventEndISODateString } from '../../common/formatting';

function ICalLink(props) {
  const config = {
    title: props.data.event_type + ' ' + props.data.company_name,
    description: props.data.header,
    start: new Date(props.data.moment),
    end: getEventEndISODateString(props.data.moment),
  };
  const icalendar = new ICalendar(config);

  function handleClick() {
    icalendar.download();
  }

  return (
    <a className={props.withClass ? "events-list-add-link": null} onClick={handleClick}>
      <i className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M13.333 2.00033H12.6663V1.33366C12.6663 0.966992 12.3663 0.666992 11.9997 0.666992C11.633 0.666992 11.333 0.966992 11.333 1.33366V2.00033H4.66634V1.33366C4.66634 0.966992 4.36634 0.666992 3.99967 0.666992C3.63301 0.666992 3.33301 0.966992 3.33301 1.33366V2.00033H2.66634C1.93301 2.00033 1.33301 2.60033 1.33301 3.33366V14.0003C1.33301 14.7337 1.93301 15.3337 2.66634 15.3337H13.333C14.0663 15.3337 14.6663 14.7337 14.6663 14.0003V3.33366C14.6663 2.60033 14.0663 2.00033 13.333 2.00033ZM13.333 13.3337C13.333 13.7003 13.033 14.0003 12.6663 14.0003H3.33301C2.96634 14.0003 2.66634 13.7003 2.66634 13.3337V5.33366H13.333V13.3337ZM7.99967 7.33366C8.36786 7.33366 8.66634 7.63213 8.66634 8.00033V9.00033H9.66634C10.0345 9.00033 10.333 9.2988 10.333 9.66699C10.333 10.0352 10.0345 10.3337 9.66634 10.3337H8.66634V11.3337C8.66634 11.7018 8.36786 12.0003 7.99967 12.0003C7.63148 12.0003 7.33301 11.7018 7.33301 11.3337V10.3337H6.33301C5.96482 10.3337 5.66634 10.0352 5.66634 9.66699C5.66634 9.2988 5.96482 9.00033 6.33301 9.00033H7.33301V8.00033C7.33301 7.63213 7.63148 7.33366 7.99967 7.33366Z"
                fill="#606980"/>
        </svg>
      </i>
    </a>
  );
}

export default ICalLink;