import React from 'react';
import TableRow from './TableRow';

function TablePart(props) {
  return (
    <React.Fragment>
      <tr className="table__name">
        <td className="sticky-col">{props.partName}</td>
        <td colSpan={props.data[0].periods.length}></td>
      </tr>
      {props.data.map(tableRow => {
        return <TableRow key={tableRow.key} rowName={tableRow.name} periods={tableRow.periods} formatting={tableRow.formatting} data={tableRow.values} />
      })}
    </React.Fragment>
  );
}

export default TablePart;