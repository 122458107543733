import React from 'react';

import { formatPercents, formatPrice, formatDatetime } from '../../common/formatting'
import { BiLineChart } from 'react-icons/bi';

function TableRow(props) {
  return (
    <React.Fragment>
      <tr>
        <td className="sticky-col">
          {props.data.name}{' '}
          <div style={{display : 'inline-block'}}>
            <a href={'/data-chart/' + props.data.key}>
              <BiLineChart />
            </a>
          </div>
        </td>
        <td>{props.data.units}</td>
        <td>{props.data.frequency}</td>
        <td>{formatDatetime(props.data.last_reported, true, false, false)}</td>
        <td>{formatPrice(props.data.price_spot)}</td>
        <td>{formatPercents(props.data.change_1d, true)}</td>
        <td>{formatPercents(props.data.change_1w, true)}</td>
        <td>{formatPercents(props.data.change_1m, true)}</td>
        <td>{formatPercents(props.data.change_ytd, true)}</td>
        <td>{formatPrice(props.data.price_stress)}</td>
        <td>{formatPrice(props.data.price_mid)}</td>
        <td>{formatPrice(props.data.price_bull)}</td>
        <td>{formatPrice(props.data.price_cons)}</td>
      </tr>
    </React.Fragment>
  );
}

export default TableRow;