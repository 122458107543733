import { Routes, Route } from 'react-router-dom';
import NotFound from './components/NotFound';
import Main from './components/Main';
import Summary from './components/Summary';
import Commodities from './components/Commodities/Commodities';
import CommodityDetail from './components/Commodities/CommodityDetail';
import ChartTool from './components/Commodities/ChartTool';
import News from './components/News/News';
import Events from './components/Events/Events';
import Company from './components/Company/Company';
import Sector from './components/Sector/Sector';
import NewsDetail from './components/News/NewsDetail';
import EventDetail from './components/Events/EventDetail';

function App() {
  return (
    <Routes>
      <Route path='*' element={<NotFound />} />
      <Route path='/' element={<Main />} />
      <Route path='/equities' element={<Summary />} />
      <Route path='/equities/:sectorKey' element={<Summary />} />
      <Route path='/data' element={<Commodities />} />
      <Route path='/data/:l1' element={<Commodities />} />
      <Route path='/data/:l1/:l2' element={<Commodities />} />
      <Route path='/data/:l1/:l2/:l3' element={<Commodities />} />
      <Route path='/data/:l1/:l2/:l3/:l4' element={<Commodities />} />
      <Route path='/data-chart/:commodityKey' element={<CommodityDetail />} />
      <Route path='/chart-tool' element={<ChartTool />} />
      <Route path='/news-reports' element={<News />} />
      <Route path='/events' element={<Events />} />
      <Route path='/company/:companyKey' element={<Company />} />
      <Route path='/sector/:sectorKey' element={<Sector />} />
      <Route path='/news/:newsID' element={<NewsDetail />} />
      <Route path='/event/:eventID' element={<EventDetail />} />
    </Routes>
  );
}

export default App;
