import TablePart from './TablePart';

function Table(props) {
  return (
    <table>
      <thead>
        <tr>
          <th className="sticky-col">Item</th>
          {props.data.length > 0 &&
            props.data[0].results[0].periods.map(period => {
              return <th key={period}>{period}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {props.data.map(tablePart => {
          return <TablePart key={tablePart.key} partName={tablePart.key} data={tablePart.results}/>
        })}
      </tbody>
    </table>
  );
}

export default Table;