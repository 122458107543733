import { formatDatetime } from '../../common/formatting'

function NewsItem(props) {
  return (
    <div className="news__item">
      <div className="news__item-inner">
        <p className="news__item-annotation"><a href={"/news/" + props.newsID}>{props.header}</a></p>
        <div className="news__item-date">{formatDatetime(props.moment, true, false, true)}</div>
      </div>
    </div>
  );
}

export default NewsItem;