import ExtraTableRow from './ExtraTableRow';

function ExtraTable(props) {
  return (
    <table>
      <thead>
        <tr>
          <th className="sticky-col">Item</th>
          <th>Units</th>
          {props.periodsData.map(period => {
            return <th key={period}>{period}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {props.marketData.map((marketDummy , index) => {
          return <ExtraTableRow key={index} data={marketDummy} />
        })}
      </tbody>
    </table>
  );
}

export default ExtraTable;