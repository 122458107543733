import { useState, useEffect } from 'react';
import ListItem from '../../common/components/ListItem';
import Driver from './Driver';

function DriversList(props) {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (props.sortedDrivers.length > 0 && props.sortedDrivers.length < 4) {
      setShowAll(true);
    }
  }, [props.sortedDrivers]);

  function handleShowAllClick(e) {
    e.preventDefault();

    setShowAll(prevState => {
      return !prevState;
    });
  }

  return (
    <ul className="tags" style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {props.sortedDrivers.map((driver, index) => {
        if (driver.currency === 'USD') {
          if (driver.name === 'Commodity basket') {
            return <Driver key={driver.key} elDisplay={index < 3 || showAll ? 'block': 'none'} widget={props.tvWidget} isWidgetReady={props.isWidgetReady} isPercentScale={props.isPercentScale} ticker={props.companyKey + ' commodity basket'} name={props.companyKey + ' ' + driver.name.toLowerCase()} />
          } else {
            return <Driver key={driver.key} elDisplay={index < 3 || showAll ? 'block': 'none'} widget={props.tvWidget} isWidgetReady={props.isWidgetReady} isPercentScale={props.isPercentScale} ticker={driver.ref_ticker} name={driver.name} />
          }
        } else {
          if (driver.ref_ticker.includes('USD')) {
            return <Driver key={driver.key} elDisplay={index < 3 || showAll ? 'block': 'none'} widget={props.tvWidget} isWidgetReady={props.isWidgetReady} isPercentScale={props.isPercentScale} ticker={driver.ref_ticker} name={driver.name} />
          } else {
            return <Driver key={driver.key} elDisplay={index < 3 || showAll ? 'block': 'none'} widget={props.tvWidget} isWidgetReady={props.isWidgetReady} isPercentScale={props.isPercentScale} ticker={driver.ref_ticker + ' USD'} name={driver.name} />
          }
        }
      })}
      { props.sortedDrivers.length > 3 &&
        <ListItem withLink={true} linkClass={null} isActive={showAll} activeClass={'state-selected'} baseClass={null} instanceKey={'More ...'} instanceName={'More ...'} clickHandler={handleShowAllClick} />
      }
    </ul>
  );
}

export default DriversList;