import { useLocation } from "react-router-dom";
import { IconContext } from 'react-icons';
import { BsTelegram, BsEnvelope } from 'react-icons/bs';

function Footer() {
  const location = useLocation();

  if (location.pathname === '/chart') {
    return null;
  } else {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__links" style={{marginBottom: '7px'}}>
              <ul>
                <li><p>The charting technology is provided by <a style={{textDecoration: 'underline'}} href='https://www.tradingview.com/'>TradingView</a>, a platform for traders and investors. With its advanced analytical tools, such as the <a style={{textDecoration: 'underline'}} href='https://www.tradingview.com/economic-calendar/'>Economic calendar</a> or <a style={{textDecoration: 'underline'}} href='https://www.tradingview.com/screener/'>Stock screener</a>, the platform lets you dive into comprehensive market analysis and prepare for trades.</p></li>
              </ul>
            </div>
            <div className="footer__links" style={{marginBottom: '7px'}}>
              <ul>
                <li><p>For commercial purposes (i.e. you want us to set up your own system with your models uploaded and used for calculations or you want to get access to technological process to set up internal database) contact us on MetalsWire@yandex.ru.</p></li>
              </ul>
            </div>
            <div className="footer__links">
              <ul>
                <li>
                  <div style={{display: "inline-block", marginRight: '7px'}}>
                    <a href='https://t.me/metalswire'>
                      <IconContext.Provider value={{ color: 'black', size: '2em' }}>
                        <div>
                          <BsTelegram />
                        </div>
                      </IconContext.Provider>
                    </a>
                  </div>
                  <div style={{display: "inline-block"}}>
                    <a href='mailto:contact-us@metals-wire.com'>
                      <IconContext.Provider value={{ color: 'black', size: '2em' }}>
                        <div>
                          <BsEnvelope />
                        </div>
                      </IconContext.Provider>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="footer__copyright">
              <ul>
                <li>&copy; 2022 - 2023 MetalsWire. All rights reserved.</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;