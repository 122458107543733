import { useState, useEffect } from 'react';

import axiosInstance from '../../../axios/instance';
import newAxiosInstance from '../../../axios/newInstance';
import { filterCompaniesData } from '../../formatting'

import TablePart from './TablePart';
import SortingIcon from './SortingIcon';

function Table(props) {
  const [data, setData] = useState([]);
  const [sortingState, setSortingState] = useState(['Market Cap', 'desc']);
  const [stickyClass, setStickyClass] = useState('sticky-header');

  useEffect(() => {
    newAxiosInstance.get('table/summary-all/' + props.scenario + '/').then((res) => {
      setData(res.data);
    });
  }, [props.scenario]);

  useEffect(() => {
    if (props.withStickyHeader) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [props.withStickyHeader]);

  useEffect(() => {
    if (props.withStickyHeader) {
      window.resizeHeader();
    }
  }, [props.withStickyHeader, data]);

  function handleScroll(e) {
    var scrollTop = window.scrollY;
    var newStickyClass = ((scrollTop >= 550) && !(window.matchMedia('(max-width: 899px)').matches)) ? 'sticky-header is-sticky' : 'sticky-header';
    setStickyClass(newStickyClass);
  };

  function handleSortingClick(e) {
    e.preventDefault();
    setSortingState(prevState => {
      if (prevState[0] === e.target.getAttribute('data-value')) {
        if (prevState[1] === 'no') {
          return [prevState[0], 'desc'];
        } else if (prevState[1] === 'desc') {
          return [prevState[0], 'asc'];
        } else if (prevState[1] === 'asc') {
          return [prevState[0], 'no'];
        }
      } else {
        return [e.target.getAttribute('data-value'), 'desc'];
      }
    });
  }

  return (
  <table>
    <thead className={stickyClass} style={{ display: 'none' }}>
      <tr>
        <th className="sticky-col">
          Company name
          <SortingIcon sortingState={sortingState} sortingKey='Company name' clickHandler={handleSortingClick} />
        </th>
        <th>
          Ticker
        </th>
        <th>
          Currency of
        </th>
        <th>
          Share price
          <SortingIcon sortingState={sortingState} sortingKey='Share price' clickHandler={handleSortingClick} />
        </th>
        <th>
          Market Cap
          <SortingIcon sortingState={sortingState} sortingKey='Market Cap' clickHandler={handleSortingClick} />
        </th>
        <th>
          EV/EBITDA
          <SortingIcon sortingState={sortingState} sortingKey='EV EBITDA' clickHandler={handleSortingClick} />
        </th>
        <th>
          PE
          <SortingIcon sortingState={sortingState} sortingKey='PE' clickHandler={handleSortingClick} />
        </th>
        <th>
          FCF yield
          <SortingIcon sortingState={sortingState} sortingKey='FCF yield' clickHandler={handleSortingClick} />
        </th>
        <th>
          Div yield
          <SortingIcon sortingState={sortingState} sortingKey='Div yield' clickHandler={handleSortingClick} />
        </th>
      </tr>
    </thead>
    <thead className='persist-header'>
      <tr>
        <th className="sticky-col">
          Company name
          <SortingIcon sortingState={sortingState} sortingKey='Company name' clickHandler={handleSortingClick} />
        </th>
        <th>
          Ticker
        </th>
        <th>
          Currency of
        </th>
        <th>
          Share price
          <SortingIcon sortingState={sortingState} sortingKey='Share price' clickHandler={handleSortingClick} />
        </th>
        <th>
          Market Cap
          <SortingIcon sortingState={sortingState} sortingKey='Market Cap' clickHandler={handleSortingClick} />
        </th>
        <th>
          EV/EBITDA
          <SortingIcon sortingState={sortingState} sortingKey='EV EBITDA' clickHandler={handleSortingClick} />
        </th>
        <th>
          PE
          <SortingIcon sortingState={sortingState} sortingKey='PE' clickHandler={handleSortingClick} />
        </th>
        <th>
          FCF yield
          <SortingIcon sortingState={sortingState} sortingKey='FCF yield' clickHandler={handleSortingClick} />
        </th>
        <th>
          Div yield
          <SortingIcon sortingState={sortingState} sortingKey='Div yield' clickHandler={handleSortingClick} />
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map(tablePart => {
        if (props.sectorsStates)  {
          if(props.sectorsStates['All'] || props.sectorsStates[tablePart.sector_key])
            if(tablePart.companies_num > 0)
              if (filterCompaniesData(props.filters, tablePart.companies).length > 0)
                return <TablePart key={tablePart.sector_key} filters={props.filters} sortingState={sortingState} data={tablePart} withSharePerf={props.withSharePerf} />
        } else {
          if(props.sector === tablePart.sector_key)
            if(tablePart.companies_num > 0)
              if (filterCompaniesData(props.filters, tablePart.companies).length > 0)
                return <TablePart key={tablePart.sector_key} filters={props.filters} sortingState={sortingState} data={tablePart} withSharePerf={props.withSharePerf} />
        }
        return null
      })}
    </tbody>
  </table>
  );
}

export default Table;
