import MobileTablePart from './MobileTablePart';

function MobileTable(props) {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan="2">Item</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map(tablePart => {
          return <MobileTablePart key={tablePart.key} period={props.period} partName={tablePart.key} data={tablePart.results}/>
        })}
      </tbody>
    </table>
  );
}

export default MobileTable;