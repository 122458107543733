import React from 'react';
import { formatFloats, formatPercents, adjustCompaniesData } from '../../formatting';
import TableRow from './TableRow';

function TablePart(props) {
  return (
    <React.Fragment>
      <tr className="table__name">
        <td className="sticky-col"><a href={'/sector/' + props.data.sector_key + '/'}>{props.data.sector_name}</a></td>
        <td colSpan="8"></td>
      </tr>
      {adjustCompaniesData(props.filters, props.sortingState, props.data.companies).map(company => {
        if(company.multipliers.EV_EBITDA)
          return <TableRow key={company.key} data={company} withSharePerf={props.withSharePerf} />
      })}
      <tr className="sticky-col">
        <td className="sticky-col"><strong>Sector median</strong></td>
        <td colSpan="4"></td>
        <td><strong>{formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</strong></td>
        <td><strong>{formatFloats(props.data.multipliers.P_E, 2, true)}</strong></td>
        <td><strong>{formatPercents(props.data.multipliers.FCF_yield, true)}</strong></td>
        <td><strong>{formatPercents(props.data.multipliers.Div_yield, true)}</strong></td>
      </tr>
    </React.Fragment>
  );
}

export default TablePart;