function CheckBox(props) {
  function checkBoxHandler(e) {
  };

  if (props.withListItemWrap) {
    return (
      <li>
        <div className="checkbox-group">
          <input className="control-checkbox" type="checkbox" id={props.id} value={props.value} onChange={checkBoxHandler} checked={props.isActive} data-value={props.instanceKey} onClick={props.clickHandler} />
          <label htmlFor={props.id}>
            <span></span> {props.label}
          </label>
        </div>
      </li>
    );
  } else {
    return (
      <div className="checkbox-group">
        <input className="control-checkbox" type="checkbox" id={props.id} value={props.value} onChange={checkBoxHandler} checked={props.isActive} data-value={props.instanceKey} onClick={props.clickHandler} />
        <label htmlFor={props.id}>
          <span></span> {props.label}
        </label>
      </div>
    );
  }
}

export default CheckBox;