import { formatFinsData, checkFinsPeriod } from '../../common/formatting'

function TableRow(props) {
  return (
    <tr>
      <td className="sticky-col">{props.rowName}</td>
      {props.periods.map(period => {
        if (!period.includes('o'))
          return <td key={period}>{formatFinsData(props.formatting, props.data[period])}</td>
      })}
      {checkFinsPeriod(props.periods[props.periods.length - 1]) &&
        <td>{(!!props.data[props.periods[props.periods.length - 2]]) && (!!props.data[props.periods[props.periods.length - 3]]) ? formatFinsData('P0', props.data[props.periods[props.periods.length - 2]] / props.data[props.periods[props.periods.length - 3]] - 1): ''}</td>
      }
    </tr>
  );
}

export default TableRow;