import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';

import ListItem from '../../common/components/ListItem'

import SensTable from './SensTable';

function Sens(props) {
  const [chartMultiplier, setChartMultiplier] = useState('EV_EBITDA');
  const [driver, setDriver] = useState(null);

  useEffect(() => {
    setDriver(props.drivers[0].ref_ticker)
  }, [props.drivers]);

  useEffect(() => {
    if(driver)
      window.newGetChart(
        'divChartContainerSens',
        props.companyKey,
        'sens/' + chartMultiplier + '/' + driver,
        'parabolic'
      );
  }, [props.companyKey, chartMultiplier, driver]);

  useEffect(() => {
    if (driver) {
      if (driver === 'Commodity_basket') {
        window.newGetChart('divChartContainerDriverHist', props.companyKey, 'revenue_diff', 'singleAnother');
      } else {
        window.newGetChart('divChartContainerDriverHist', 'comm', 'driver_hist/' + driver, 'singleAnother');
      }
    }
  }, [props.companyKey, driver]);


  return (
    <div className="col col-100">
      <div className="card card-white">
        <div className="row">
          <div className="col col-33">
            <div className="card__head">
              <div className="card__head-name">
                <span>
                  Major drivers
                </span>
              </div>
              <ul className="tags hidden-desktop">
                <li className="state-selected"><a href="#">3 months</a></li>
                <li><a href="#">6 months</a></li>
                <li><a href="#">1 year</a></li>
              </ul>
            </div>
            <div className="company__table-reporting hidden-mobile">
              <table>
                <tbody>
                  <tr>
                    <td rowSpan="2">
                      Driver
                    </td>
                    <td colSpan="2">
                      1% change effect on
                    </td>
                  </tr>
                  <tr>
                    <td>EBITDA</td>
                    <td>Equity</td>
                  </tr>
                  { driver &&
                    <SensTable drivers={props.drivers} driver={driver} rowHandler={setDriver} />
                  }
                </tbody>
              </table>
            </div>
            <div className="mobile-blue-select company-reporting-analysis-select hidden-desktop">
              <div className="custom-select">
                <div className="custom-select__inner">
                  <div className="custom-select__trigger"><span>Net income</span>
                    <div className="custom-select__arrow"></div>
                  </div>
                  <div className="custom-select__options position-left">
                    <div className="custom-select__options-inner scrollable">
                      <ul className="custom-select__options-list">
                        <li className="custom-select__option state-selected" data-value="EBITDA">
                          EBITDA
                        </li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <span className="company-reporting-analysis-select--count company-reporting-analysis-select--count1">
                  <span>SPOT: 19,635</span>
                  <span>Consensus: 58.63 %</span>
              </span>
              <span className="company-reporting-analysis-select--count company-reporting-analysis-select--count2">vs. Consensus: 29.04 %</span>
            </div>
            <div className="company-reporting-analysis-filter hidden-desktop">
              <div className="company-reporting-analysis-filter--item">
                <div className="company-reporting-analysis-filter--name">Period</div>
                <div className="card__custom-select custom-select">
                  <div className="custom-select__inner">
                    <div className="custom-select__trigger"><span>2H21</span>
                      <i className="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                            fill="#0A2896"/>
                        </svg>
                      </i>
                    </div>
                    <div className="custom-select__options position-right">
                      <div className="custom-select__options-inner scrollable">
                        <ul className="custom-select__options-list">
                          <li className="custom-select__option state-selected" data-value="2H21">2H21</li>
                          <li className="custom-select__option" data-value="2H21">2H21</li>
                          <li className="custom-select__option" data-value="2H21   ">2H21</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-reporting-analysis-filter--item">
                <div className="company-reporting-analysis-filter--name">Reporting date</div>
                <div>10 Feb</div>
              </div>
            </div>
          </div>
          <div className="col col-33">
            <div className="card__head">
              <div className="card__head-name">
                <span>
                  Commodity price performance
                </span>
              </div>
            </div>
            <div className="width-100">
              <div id="divChartContainerDriverHist" className="historical"></div>
            </div>
          </div>
          <div className="col col-33">
            <div className="card__head">
              <div className="card__head-name">
                <span>
                  Sensitivity
                </span>
              </div>
              <ul className="tags">
                <ListItem withLink={true} linkClass={null} isActive={chartMultiplier === 'EV_EBITDA'} activeClass={'state-selected'} baseClass={null} instanceKey={'EV_EBITDA'} instanceName={'EV/EBITDA'} clickArgs={[true, setChartMultiplier, null, null]} />
                <ListItem withLink={true} linkClass={null} isActive={chartMultiplier === 'FCF_yield'} activeClass={'state-selected'} baseClass={null} instanceKey={'FCF_yield'} instanceName={'FCF yield'} clickArgs={[true, setChartMultiplier, null, null]} />
                <ListItem withLink={true} linkClass={null} isActive={chartMultiplier === 'IS0006'} activeClass={'state-selected'} baseClass={null} instanceKey={'IS0006'} instanceName={'EBITDA'} clickArgs={[true, setChartMultiplier, null, null]} />
              </ul>
            </div>
            <div className="width-100">
              <div id="divChartContainerSens" className="historical"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sens;