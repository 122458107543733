import { formatPercents } from '../../formatting';

function SharePerf(props) {

  function getSharePerf() {
    return props.withSharePerf ? '(' + formatPercents(props.data.share.change_1d, true, true) + ')': null;
  }

  if (!!props.data.share.change_1d) {
    return (
      <div className={props.data.share.change_1d >= 0 ? 'quotes__item': 'quotes__item quotes__item-sell'} style={{ display: 'inline-block' }}>
        <div className="quotes__item-actions" style={{ textAlign: 'left' }}>
          <div className="quotes__item-change" style={{ marginBottom: 'none' }}>{getSharePerf()}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default SharePerf;