import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';

import ListItem from '../../common/components/ListItem'
import NewsItem from '../../common/components/NewsItem';

function NewsPager(props) {
  const [newsPage, setNewsPage] = useState(1);
  const [newsPagesMaxNum, setNewsPagesMaxNum] = useState(0);
  const [news, setNews] = useState([]);
  const [newsPagesNumsStart, setNewsPagesNumsStart] = useState([]);
  const [newsPagesNumsMiddle, setNewsPagesNumsMiddle] = useState([]);
  const [newsPagesNumsEnd, setNewsPagesNumsEnd] = useState([]);

  function handleNewsButtonClick(e) {
    e.preventDefault();
    setNewsPage(prevState => {
      return prevState + parseInt(e.target.getAttribute('data-value'));
    });
  }

  function handleNewsPageClick(e) {
    e.preventDefault();
    setNewsPage(prevState => {
      if (prevState !== parseInt(e.target.getAttribute('data-value'))) {
        return parseInt(e.target.getAttribute('data-value'));
      } else {
        return prevState;
      }
    });
  }

  useEffect(() => {
    axiosInstance.get('news/' + props.newsType + '/' + props.objectKey + '/?limit=8&offset=' + (newsPage - 1) * 8).then((res) => {
      if (res.data.results.length > 0) {
        var newsPagesCount = Math.floor(res.data.count / 8);
        if (res.data.count % 8 > 0)
          newsPagesCount++;

        setNewsPagesMaxNum(newsPagesCount);
        setNews(res.data.results);
      }
    });
  }, [props.newsType, props.objectKey, newsPage]);

  useEffect(() => {
    if (newsPagesMaxNum > 10) {
      var newsPagesNums = [];
      var newsPagesNumsStart = [1, 2, 3, 4, 5];
      var newsPagesNumsEnd = [
        newsPagesMaxNum - 4,
        newsPagesMaxNum - 3,
        newsPagesMaxNum - 2,
        newsPagesMaxNum - 1,
        newsPagesMaxNum
      ];

      if (newsPage >= 5 && newsPage <= newsPagesMaxNum - 4) {
        newsPagesNumsStart = [1, 2];
        newsPagesNumsEnd = [newsPagesMaxNum - 1, newsPagesMaxNum];

        newsPagesNums.push(newsPage - 1);
        newsPagesNums.push(newsPage);
        newsPagesNums.push(newsPage + 1);
      }

      setNewsPagesNumsStart(newsPagesNumsStart);
      setNewsPagesNumsMiddle(newsPagesNums);
      setNewsPagesNumsEnd(newsPagesNumsEnd);
    } else {
      var newsPagesNums = [];
      for (var i = 1; i < newsPagesMaxNum + 1; i++) {
        newsPagesNums.push(i);
      }

      setNewsPagesNumsStart(prevState => {
        if (prevState.length === 0) {
          return newsPagesNums;
        }
        else {
          return prevState;
        }
      });
      setNewsPagesNumsMiddle(prevState => {
        if (prevState.length === 0) {
          return prevState;
        }
        else {
          return [];
        }
      });
      setNewsPagesNumsEnd(prevState => {
        if (prevState.length === 0) {
          return prevState;
        }
        else {
          return [];
        }
      });
    }
  }, [newsPagesMaxNum, newsPage]);

  if (news.length > 0) {
    return (
      <section className="section news news-cut news-large">
        <div className="section__head">
          <h2 className="section__title">News</h2>
        </div>
        <div className="news__list">
          {news.map(news => {
            return <NewsItem key={news.id} newsID={news.id}  moment={news.moment} header={news.header} />
          })}
        </div>
        <ul className="pagination">
          <li className={newsPage > 1 ? "page__item": "page__item state-disabled"}>
            {newsPage > 1 &&
              <a className="page__button" href="#" data-value='-1' onClick={handleNewsButtonClick}>Previous</a>
            }
            {newsPage < 2 &&
              <span className="page__button">Previous</span>
            }
          </li>
          {newsPagesNumsStart.map(pageNum => {
            return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={newsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleNewsPageClick} />
          })}
          {newsPagesNumsMiddle.length > 0 &&
            <li className="page__item">
              <span className="page__delimeter">...</span>
            </li>
          }
          {newsPagesNumsMiddle.map(pageNum => {
            return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={newsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleNewsPageClick} />
          })}
          {(newsPagesNumsMiddle.length > 0 || newsPagesNumsEnd.length > 0) &&
            <li className="page__item">
              <span className="page__delimeter">...</span>
            </li>
          }
          {newsPagesNumsEnd.map(pageNum => {
            return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={newsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleNewsPageClick} />
          })}
          <li className={newsPage !== newsPagesMaxNum ? "page__item": "page__item state-disabled"}>
            {newsPage !== newsPagesMaxNum &&
              <a className="page__button" href="#" data-value='1' onClick={handleNewsButtonClick}>Next</a>
            }
            {newsPage === newsPagesMaxNum &&
              <span className="page__button">Next</span>
            }
          </li>
        </ul>
      </section>
    );
  }
}

export default NewsPager;