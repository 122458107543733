import { useState, useEffect } from 'react';
import ListItem from '../../common/components/ListItem';
import RelatedComp from './RelatedComp';

function RelatedCompsList(props) {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (props.sortedCompanies.length > 0 && props.sortedCompanies.length < 4) {
      setShowAll(true);
    }
  }, [props.sortedCompanies]);

  function handleShowAllClick(e) {
    e.preventDefault();

    setShowAll(prevState => {
      return !prevState;
    });
  }

  return (
    <ul className="tags" style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {props.sortedCompanies.map((relatedCompany, index) => {
        return <RelatedComp key={relatedCompany.key} elDisplay={index < 3 || showAll ? 'block': 'none'} widget={props.tvWidget} isWidgetReady={props.isWidgetReady} isPercentScale={props.isPercentScale} currency={props.currency} USDTicker={props.symbol + ' USD'} ticker={relatedCompany.ticker} name={relatedCompany.name} />
      })}
      { props.sortedCompanies.length > 3 &&
        <ListItem withLink={true} linkClass={null} isActive={showAll} activeClass={'state-selected'} baseClass={null} instanceKey={'More ...'} instanceName={'More ...'} clickHandler={handleShowAllClick} />
      }
    </ul>
  );
}

export default RelatedCompsList;