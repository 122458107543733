import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Chart from '../Chart/Chart';

import axiosInstance from '../../axios/instance';
import { formatDatetime } from '../../common/formatting';

function CommodityDetail() {
  const { commodityKey } = useParams();
  const [commodityData, setCommodityData] = useState({'name': null});

  useEffect(() => {
    axiosInstance.get('commodity-by-industry/' + commodityKey + '/').then((res) => {
      setCommodityData(res.data);
    });
  }, [commodityKey]);

  return (
    <div className="app page-publication">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__description">
              <div className="breadcrumbs">
                <ul>
                  <li><a href="/">Main</a></li>
                  <li><a href="/data">Data</a></li>
                  { commodityData.name &&
                    <React.Fragment>
                      <li><a href={"/data/" + commodityData.levels.l1}>{commodityData.levels.l1}</a></li>
                      <li><a href={"/data/" + commodityData.levels.l1 + '/' + commodityData.levels.l2}>{commodityData.levels.l2}</a></li>
                      <li><a href={"/data/" + commodityData.levels.l1 + '/' + commodityData.levels.l2 + '/' + commodityData.levels.l3}>{commodityData.levels.l3}</a></li>
                    </React.Fragment>
                  }
                  { commodityData.name && commodityData.levels.l4 &&
                    <li><a href={"/data/" + commodityData.levels.l1 + '/' + commodityData.levels.l2 + '/' + commodityData.levels.l3 + '/' + commodityData.levels.l4}>{commodityData.levels.l4}</a></li>
                  }
                  <li><span aria-current="page">{commodityData.name}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="publication">
              <div className="publication__inner">
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__head">
                      <div className="publication__head-info">
                        <ul>
                          <li>Metals Wire</li>
                          <li>{commodityData.name}</li>
                        </ul>
                      </div>
                      <div className="publication__head-text">
                        <h1>{commodityData.name}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__row">
                      <div className="publication__col-left" style={{ width: '100%'}}>
                        <div className="col col-100">
                          <div className="card card-white">
                            <div>
                              { commodityData.levels &&
                                <Chart symbol={commodityData.levels.l1 + ':' + commodityKey} />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CommodityDetail;
