import React from 'react';
import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';
import { getDatePart, formatDateObj, formatDatetime, getFilterListOfDicts } from '../../common/formatting';

import CommoditySearchResult from './CommoditySearchResult';

function SearchResult(props) {
  const [sectors, setSectors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [commodities, setCommodities] = useState({});
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    var sectorsKeys = [];
    var newSector = {};
    var newSectors = [];

    if (props.sectorsData.length > 0) {
      props.sectorsData.forEach(sectorData => {
        if (!sectorsKeys.includes(sectorData.sector_key)) {
          sectorsKeys.push(sectorData.sector_key);
          newSector = {
            'key': sectorData.sector_key,
            'name': sectorData.sector_name,
          }
          newSectors.push(newSector);
        }
      });
    }

    if (props.companiesData.length > 0) {
      props.companiesData.forEach(sectorData => {
        if (!sectorsKeys.includes(sectorData.sector_key)) {
          sectorsKeys.push(sectorData.sector_key);
          newSector = {
            'key': sectorData.sector_key,
            'name': sectorData.sector_name,
          }
          newSectors.push(newSector);
        }
      });
    }

    if (props.commoditiesData.length > 0) {
      props.commoditiesData.forEach(sectorData => {
        if (!sectorsKeys.includes(sectorData.sector.sector_key)) {
          sectorsKeys.push(sectorData.sector.sector_key);
          newSector = {
            'key': sectorData.sector.sector_key,
            'name': sectorData.sector.sector_name,
          }
          newSectors.push(newSector);
        }
      });
    }

    var sortedData = newSectors.sort(function(a, b) {
      return ('' + a.name).localeCompare(b.name);
    });
    setSectors(sortedData);
  }, [props.sectorsData, props.companiesData, props.commoditiesData, props.commoditiesByIndustriesData]);

  useEffect(() => {
    var companiesKeys = [];
    var newCompany = {};
    var newCompanies = [];

    if (props.companiesData.length > 0) {
      props.companiesData.forEach(companyData => {
        if (!companiesKeys.includes(companyData.key)) {
          companiesKeys.push(companyData.key);
          newCompany = {
            'key': companyData.key,
            'name': companyData.name,
          }
          newCompanies.push(newCompany);
        }
      });
    }

    if (props.commoditiesData.length > 0) {
      props.commoditiesData.forEach(commodityData => {
        commodityData.sector.companies.forEach(companyData => {
          if (companyData.ev_ebitda) {
            if (!companiesKeys.includes(companyData.key)) {
              companiesKeys.push(companyData.key);
              newCompany = {
                'key': companyData.key,
                'name': companyData.name,
              }
              newCompanies.push(newCompany);
            }
          }
        });
      });
    }

    var sortedData = newCompanies.sort(function(a, b) {
      return ('' + a.name).localeCompare(b.name);
    });
    setCompanies(sortedData);
  }, [props.sectorsData, props.companiesData, props.commoditiesData, props.commoditiesByIndustriesData]);

  useEffect(() => {
    var commoditiesKeys = [];
    var levelsKey = [];
    var newCommodity = {};
    var newCommodities = {};

    if (props.sectorsData.length > 0) {
      props.sectorsData.forEach(sectorData => {
        sectorData.commodities.forEach(commodityData => {
          if (!commoditiesKeys.includes(commodityData.key)) {
            commoditiesKeys.push(commodityData.key);

            levelsKey = '' + commodityData.levels.l1 + ',404;' + commodityData.levels.l2 + ',404;' + commodityData.levels.l3 + ',404;' + commodityData.levels.l4;
            newCommodity = {
              'key': commodityData.key,
              'name': commodityData.name,
            };

            if (levelsKey in newCommodities) {
              newCommodities[levelsKey].push(newCommodity);
            } else {
              newCommodities[levelsKey] = [newCommodity];
            }
          }
        });
      });
    }

    if (props.commoditiesData.length > 0) {
      props.commoditiesData.forEach(commodityData => {
        if (!commoditiesKeys.includes(commodityData.key)) {
          commoditiesKeys.push(commodityData.key);

          levelsKey = '' + commodityData.levels.l1 + ',404;' + commodityData.levels.l2 + ',404;' + commodityData.levels.l3 + ',404;' + commodityData.levels.l4;
          newCommodity = {
            'key': commodityData.key,
            'name': commodityData.name,
          };

          if (levelsKey in newCommodities) {
            newCommodities[levelsKey].push(newCommodity);
          } else {
            newCommodities[levelsKey] = [newCommodity];
          }
        }
      });
    }

    if (props.commoditiesByIndustriesData.length > 0) {
      props.commoditiesByIndustriesData.forEach(commodityData => {
        if (!commoditiesKeys.includes(commodityData.key)) {
          commoditiesKeys.push(commodityData.key);

          levelsKey = '' + commodityData.levels.l1 + ',404;' + commodityData.levels.l2 + ',404;' + commodityData.levels.l3 + ',404;' + commodityData.levels.l4;
          newCommodity = {
            'key': commodityData.key,
            'name': commodityData.name,
          };

          if (levelsKey in newCommodities) {
            newCommodities[levelsKey].push(newCommodity);
          } else {
            newCommodities[levelsKey] = [newCommodity];
          }
        }
      });
    }

    var levelsKeys = Object.keys(newCommodities);
    var sortedKeys = levelsKeys.sort(function(a, b) {
      return ('' + a).localeCompare(b);
    });

    newCommodities['sortedKeys'] = sortedKeys;
    setCommodities(newCommodities);
  }, [props.sectorsData, props.companiesData, props.commoditiesData, props.commoditiesByIndustriesData]);

  useEffect(() => {
    setNews([]);

    if (props.companiesData.length > 0) {
      var filtersJSON = {
        'sectors': [],
        'companies': getFilterListOfDicts(props.companiesData),
      }

      axiosInstance.post('news/table/company/?limit=3&offset=0', filtersJSON).then((res) => {
        setNews(res.data.results);
      });
    }
  }, [props.sectorsData, props.companiesData, props.commoditiesData, props.commoditiesByIndustriesData]);

  useEffect(() => {
    setEvents([]);

    if (props.companiesData.length > 0) {
      var startDate = new Date(getDatePart(null, 'year'), getDatePart(null, 'month'), getDatePart(null, 'day'));
      var endDate = new Date(getDatePart(null, 'year'), getDatePart(null, 'month'), getDatePart(null, 'day'));
      endDate.setDate(startDate.getDate() + 60);
      startDate = formatDateObj(startDate);
      endDate = formatDateObj(endDate);

      var filtersJSON = {
        'sectors': [],
        'companies': getFilterListOfDicts(props.companiesData),
        'events_types': [],
      }

      axiosInstance.post('events/table/?limit=3&offset=0&start_date=' + startDate + '&end_date=' + endDate, filtersJSON).then((res) => {
        setEvents(res.data.results);
      });
    } else if (props.commoditiesData.length > 0) {
      var startDate = new Date(getDatePart(null, 'year'), getDatePart(null, 'month'), getDatePart(null, 'day'));
      var endDate = new Date(getDatePart(null, 'year'), getDatePart(null, 'month'), getDatePart(null, 'day'));
      endDate.setDate(startDate.getDate() + 60);
      startDate = formatDateObj(startDate);
      endDate = formatDateObj(endDate);

      var sectorsKeys = [];
      var sector = {}
      var sectorsListOfDicts = [];

      props.commoditiesData.forEach(commodityData => {
        if (!sectorsKeys.includes(commodityData.sector.sector_key)) {
          sectorsKeys.push(commodityData.sector.sector_key);
          sector = {
            'key': commodityData.sector.sector_key
          }
          sectorsListOfDicts.push(sector);
        }
      });

      var filtersJSON = {
        'sectors': sectorsListOfDicts,
        'companies': [],
        'events_types': [],
      }

      axiosInstance.post('events/table/?limit=3&offset=0&start_date=' + startDate + '&end_date=' + endDate, filtersJSON).then((res) => {
        setEvents(res.data.results);
      });
    }
  }, [props.sectorsData, props.companiesData, props.commoditiesData]);

  return (
    <React.Fragment>
      {props.warningText.length > 0 &&
        <li>
          <div className="search__result-head">
            <span>{props.warningText}</span>
          </div>
        </li>
      }
      { sectors.length > 0 &&
        <li>
          <div className="search__result-head">
            <span>Sector boards</span>
          </div>
          <ul>
            {sectors.map(sectorData => {
              return <li key={sectorData.key} ><a href={'/sector/' + sectorData.key}>{sectorData.name}</a></li>;
            })}
          </ul>
        </li>
      }
      { companies.length > 0 &&
        <li>
          <div className="search__result-head">
            <span>Company boards</span>
          </div>
          <ul>
            {companies.map(companyData => {
                return <li key={companyData.key} ><a href={'/company/' + companyData.key}>{companyData.name}</a></li>;
            })}
          </ul>
        </li>
      }
      { Object.keys(commodities).length > 1 &&
        <li>
          <div className="search__result-head">
            <span>Commodities</span>
          </div>
          <ul>
            {commodities.sortedKeys.map(levelsKey => {
              return <CommoditySearchResult key={levelsKey} levelsKey={levelsKey} data={commodities[levelsKey]} />;
            })}
          </ul>
        </li>
      }
      { news.length > 0 &&
        <li>
          <div className="search__result-head">
            <span>News & Reports</span>
          </div>
          <ul>
            {news.map(newsData => {
              return <li key={newsData.key} ><a href={'/news/' + newsData.id}>{newsData.header}</a></li>;
            })}
          </ul>
        </li>
      }
      { events.length > 0 &&
        <li>
          <div className="search__result-head">
            <span>Events</span>
          </div>
          <ul>
            {events.map(eventData => {
              return <li key={eventData.key} ><a href={'/event/' + eventData.id}>{eventData.company_name}, {formatDatetime(eventData.moment, true, false, false)} - {eventData.header}</a></li>;
            })}
          </ul>
        </li>
      }
    </React.Fragment>
  );
}

export default SearchResult;