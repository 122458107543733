import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';
import { formatDatetime } from '../../common/formatting'

import Rep from '../Company/Rep';

function EventDetail() {
  const { eventID } = useParams();

  const [eventData, setEventData] = useState({});

  useEffect(() => {
    axiosInstance.get('event/' + eventID).then((res) => {
      setEventData(res.data);
    });
  }, [eventID]);

  useEffect(() => {
    window.getDummyReportingChart();
  }, []);

  return (
    <div className="app page-publication">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__description">
              <div className="breadcrumbs">
                <ul>
                  <li><a href="/">Main</a></li>
                  <li><a href="/equities">Equities</a></li>
                  <li><a href={"/company/" + eventData.company_key}>{eventData.company_name}</a></li>
                  <li><span aria-current="page">{eventData.company_name + ', ' + formatDatetime(eventData.moment, true, false, true) + ' - ' + eventData.header}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="publication">
              <div className="publication__inner">
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__head">
                      <div className="publication__head-info">
                        <ul>
                          <li>Metals Wire</li>
                          <li>{eventData.company_name + ' ' + eventData.header}</li>
                          <li>{formatDatetime(eventData.moment, true, false, true)}</li>
                        </ul>
                      </div>
                      <div className="publication__head-text">
                        <h1>{eventData.header}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__row">
                      <div className="publication__col-left" style={{ width: '100%' }}>
                        {eventData && <Rep companyKey={eventData.company_key} isShort={true} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EventDetail;
