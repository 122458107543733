import { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsTelegram } from 'react-icons/bs';

import axiosInstance from '../axios/instance';
import newAxiosInstance from '../axios/newInstance';
import { formatDatetime, formatUTCDatetime } from '../common/formatting';

import ListItem from '../common/components/ListItem';
import CheckBox from '../common/components/CheckBox';
import ObjectLink from '../common/components/ObjectLink';

import Table from '../common/components/SummaryTable/Table';
import Quote from './Quote';

import partner from '../images/partner.png';
import person from '../images/team/DGlushakov.png';
import person1 from '../images/team/NKhalin.png';
import person2 from '../images/team/ISalkovsky.png';

function Main() {
  const [scenario, setScenario] = useState('S');
  const [scenarioName, setScenarioName] = useState('SPOT');
  const [scenarios, setScenarios] = useState([{'key': null, 'name': null}]);
  const [scenariosNames, setScenariosNames] = useState({'S': 'SPOT'})

  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState(null);

  const [filters, setFilters] = useState({
    'isRussian': false,
    'isAsian': false,
    'isCovered': false,
    'withLargeCap': false,
  });

  const [spotDate, setSpotDate] = useState(null);

  const [news, setNews] = useState([]);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    newAxiosInstance.get('option/scenario/').then((res) => {
      var newScenariosNames = {};

      res.data.forEach(result => {
        newScenariosNames[result.key] = result.name;
      });

      setScenarios(res.data);
      setScenariosNames(newScenariosNames);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('option/sector/').then((res) => {
      var newSectors = [];
      var newSector = {};

      res.data.forEach(result => {
        if (result.companies_num > 0) {
          newSector = {
            'key': result.sector_key,
            'name': result.sector_name
          };

          newSectors.push(newSector);
        }
      });

      setSector(newSectors[0].key);
      setSectors(newSectors);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('ref-moment/spot_date/').then((res) => {
      setSpotDate(res.data.value);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('shares/recs/').then((res) => {
      setQuotes(res.data.results);
    });
  }, []);


  useEffect(() => {
    var filtersJSON = {
      'sectors': [],
      'companies': [],
    }

    var newsHeaders = [];
    var newNews = [];

    axiosInstance.post('news/table/?limit=25&offset=0', filtersJSON).then((res) => {
      res.data.results.forEach(result => {
        if (!newsHeaders.includes(result.header)) {
          newsHeaders.push(result.header)
          newNews.push(result);
        }
      });
      setNews(newNews);
    });
  }, []);

  function handleFilterClick(e) {
    setFilters(prevState => ({
      ...prevState,
      [e.target.getAttribute('data-value')]: e.target.checked
    }));
  }

  return (
    <div className="app page-metals-mining-summary">
      <div className="wrapper">
        <main className="content">
          <div className="container">
            <div className="page-row">
              <div className="page-body">
                <section className="section articles">
                  { news.length > 0 &&
                    <div className="articles__list">
                      <div className="article article__large card card-white">
                        <ul className="article__info">
                          <li>
                            <i className="icon icon__speaker">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M13.9591 15.4171C12.8795 15.4171 11.6245 13.0512 11.6245 10.0004C11.6245 6.94958 12.8795 4.58375 13.9591 4.58375C15.0378 4.58375 16.2928 6.94958 16.2928 10.0004C16.2928 13.0512 15.0378 15.4171 13.9591 15.4171ZM8.72654 14.9162C8.49738 15.2125 8.08571 15.31 7.74738 15.1487L6.06779 14.3487C5.79196 14.2171 5.61696 13.9458 5.61071 13.64L5.6253 12.9175L9.02446 14.5196L8.72654 14.9162ZM13.9591 2.96375C13.1953 2.96375 12.487 3.39375 11.8949 4.12791L2.82363 8.40333C2.11446 9.20541 2.11446 10.7946 2.82363 11.5975L4.38113 12.3312L4.37572 13.665C4.39197 14.4408 4.83655 15.1304 5.53738 15.4633L7.21529 16.2637C7.4932 16.3954 7.79112 16.4592 8.08487 16.4592C8.70154 16.4592 9.30822 16.1812 9.70238 15.6729L10.1682 15.0587L11.8949 15.8725C12.487 16.6062 13.1953 17.0367 13.9591 17.0367C16.0299 17.0367 17.7082 13.8862 17.7082 10.0004C17.7082 6.11416 16.0299 2.96375 13.9591 2.96375ZM12.694 8.29208C12.6703 8.29208 12.6499 8.29583 12.629 8.29875C12.4832 8.72541 12.3936 9.23041 12.3753 9.77458C12.3728 9.84458 12.3707 9.91333 12.3703 9.98458C12.372 10.6258 12.4757 11.2171 12.6499 11.7046C12.6645 11.7054 12.6786 11.7087 12.694 11.7087C13.6361 11.7087 14.4011 10.9437 14.4011 10.0004C14.4011 9.05666 13.6361 8.29208 12.694 8.29208Z"
                                  fill="#606980"/>
                              </svg>
                            </i>
                            <span>Metals Wire</span>
                          </li>
                          <li>{formatDatetime(news[0].moment, true, false, false)}</li>
                        </ul>
                        <h3 className="article__title">
                          <a href={"/news/" + news[0].id}>
                            {news[0].header}
                          </a>
                        </h3>
                        <div className="article__bottom">
                          <div className="article__author">
                            <div className="article__author-photo"><img src={person1}
                                                                    alt="Nikanor Khalin" /></div>
                            <span>Nikanor Khalin</span>
                          </div>
                          { news[0].tg_link &&
                            <div className="article__resource">
                              <a href={news[0].tg_link}>
                                <IconContext.Provider value={{ color: 'blue', size: '119' }}>
                                  <div>
                                    <BsTelegram />
                                  </div>
                                </IconContext.Provider>
                              </a>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="article article__small card card-white">
                        <div className="article__author">
                          <div className="article__author-photo"><img src={person1} alt="Nikanor Khalin" /></div>
                          <div>
                            <div className="article__author-name">Nikanor Khalin</div>
                            <div className="article__author-position">Metals Wire</div>
                          </div>
                        </div>
                        <h3 className="article__title">
                          <a href={"/news/" + news[1].id}>
                            {news[1].header}
                          </a>
                        </h3>
                        <div className="article__more">
                          <a href={"/news/" + news[1].id}>
                            <span>Read more</span>
                            <i className="icon">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                  <path
                                    d="M9.99665 8.27668L6.93665 11.3367C6.67665 11.5967 6.25665 11.5967 5.99665 11.3367C5.73665 11.0767 5.73665 10.6567 5.99665 10.3967L8.58332 7.80334L5.99665 5.21668C5.73665 4.95668 5.73665 4.53668 5.99665 4.27668C6.25665 4.01668 6.67665 4.01668 6.93665 4.27668L9.99665 7.33668C10.2567 7.59001 10.2567 8.01668 9.99665 8.27668Z"
                                    fill="#606980"/>
                                </g>
                              </svg>
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="button__wrapper">
                    <a className="articles__button button button__bordered" href="/news-reports">
                      <span>All research</span>
                      <i className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.995 12.415L10.405 17.005C10.015 17.395 9.38498 17.395 8.99498 17.005C8.60498 16.615 8.60498 15.985 8.99498 15.595L12.875 11.705L8.99498 7.82502C8.60498 7.43502 8.60498 6.80502 8.99498 6.41502C9.38498 6.02502 10.015 6.02502 10.405 6.41502L14.995 11.005C15.385 11.385 15.385 12.025 14.995 12.415Z"
                            fill="#0A2896"/>
                        </svg>
                      </i>
                    </a>
                  </div>
                </section>
                <div className="metals-mining-summary">
                  <div className="section__head">
                    <div className="dropdown hidden-desktop">
                      <div className="dropdown__button js-dropdown-button">
                        <i className="icon">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                              d="M7.48641 10.8333C7.48641 10.8333 4.97671 7.08334 3.48135 4.67501C3.12157 4.09826 3.44797 3.33334 4.14046 3.33334H15.8569C16.5494 3.33334 16.8826 4.0838 16.516 4.67501C15.0226 7.08334 12.4924 10.8333 12.4924 10.8333V15.8333C12.4924 16.2917 12.1169 16.6667 11.658 16.6667H8.32073C7.86185 16.6667 7.48641 16.2917 7.48641 15.8333V10.8333ZM9.16683 10.4167L5.62516 5.00001H14.4074L10.8335 10.4167V15L9.16683 15V10.4167Z"
                              fill="#606980" />
                          </svg>
                        </i>
                      </div>
                      <div className="dropdown__selection dropdown__menu dropdown__menu-white dropdown__menu-fixed">
                        <div className="dropdown__slide">
                          <div className="dropdown__hide">
                            <svg width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.5"
                                d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                fill="#D4D7DF" />
                            </svg>
                          </div>
                          <div className="dropdown__menu-title">Feed Settings</div>
                          <div className="dropdown__slide-content scrollable">
                            <ul>
                              <CheckBox withListItemWrap={true} id={'chkbx_111'} value={'1'} label={'Russian companies'} isActive={filters['isRussian']} instanceKey={'isRussian'} clickHandler={handleFilterClick} />
                              <CheckBox withListItemWrap={true} id={'chkbx_222'} value={'1'} label={'Asian companies'} isActive={filters['isAsian']} instanceKey={'isAsian'} clickHandler={handleFilterClick} />
                              <CheckBox withListItemWrap={true} id={'chkbx_333'} value={'1'} label={'Covered companies'} isActive={filters['isCovered']} instanceKey={'isCovered'} clickHandler={handleFilterClick} />
                              <CheckBox withListItemWrap={true} id={'chkbx_444'} value={'1'} label={'Large cap companies'} isActive={filters['withLargeCap']} instanceKey={'withLargeCap'} clickHandler={handleFilterClick} />
                            </ul>
                          </div>
                          <div className="button__wrapper"><button type="button" className="button button__blue hidden-desktop">Apply</button></div>
                        </div>
                        <div className="dropdown__overlay"></div>
                      </div>
                    </div>
                  </div>
                  <div className="summary__filter">
                    <div className="control-group">
                      <CheckBox withListItemWrap={false} id={'chkbx_111'} value={'1'} label={'Russian companies'} isActive={filters['isRussian']} instanceKey={'isRussian'} clickHandler={handleFilterClick} />
                      <CheckBox withListItemWrap={false} id={'chkbx_222'} value={'1'} label={'Asian companies'} isActive={filters['isAsian']} instanceKey={'isAsian'} clickHandler={handleFilterClick} />
                      <CheckBox withListItemWrap={false} id={'chkbx_333'} value={'1'} label={'Covered companies'} isActive={filters['isCovered']} instanceKey={'isCovered'} clickHandler={handleFilterClick} />
                      <CheckBox withListItemWrap={false} id={'chkbx_444'} value={'1'} label={'Large cap companies'} isActive={filters['withLargeCap']} instanceKey={'withLargeCap'} clickHandler={handleFilterClick} />
                    </div>
                    <div className="d-flex">
                      <div className="custom-select__name">Scenarios:</div>
                      <div className="custom-select">
                        <div className="custom-select__inner">
                          <div className="custom-select__trigger"><span>{scenarioName}</span>
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                                  fill="#0A2896" />
                              </svg>
                            </i>
                          </div>
                          <div className="custom-select__options position-right">
                            <div className="custom-select__options-inner scrollable">
                              <ul className="custom-select__options-list">
                                {scenarios.map(scenario => {
                                  return <ListItem key={scenario.key} withLink={false} linkClass={null} isActive={scenario === scenario.key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={scenario.key} instanceName={scenario.name} clickArgs={[true, setScenario, scenariosNames, setScenarioName]} />
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="summary__head">
                    <ul className="tags">
                      {sectors.map(sectorData => {
                        return <ListItem key={sectorData.key} withLink={true} linkClass={null} isActive={sector === sectorData.key} activeClass={'state-selected'} baseClass={null} instanceKey={sectorData.key} instanceName={sectorData.name} clickArgs={[true, setSector, null, null]} />
                      })}
                    </ul>
                    <div className="export hidden-mobile">
                      <ul>
                        <li className="export__date">{formatUTCDatetime(spotDate, true, true, false)}</li>
                        <li>
                          <span>Spot Date</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="search__selected">
                    <div className="search__selected-items">
                      <ul>
                        {sectors.map(sectorData => {
                          if (sectorData.key === sector)
                            return <ObjectLink key={sectorData.key} objType='sector' extraExpr=' sector board' data={sectorData} />;
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="table__container">
                    <div className="summary__table table__scrollable">
                      <Table scenario={scenario} sector={sector} filters={filters} withStickyHeader={false} withSharePerf={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-sidebar">
                <section className="b-team card card-white page-sidebar__block">
                  <div className="page-sidebar__name">Team</div>
                  <div className="page-sidebar__list b-team__list">
                    <ul>
                      <li className="page-sidebar__item b-team__item">
                        <div className="b-team__item-name">
                          <a href="#">
                            Dmitry
                            <br/>
                            Glushakov, CFA
                          </a>
                        </div>
                        <div className="b-team__item-photo"><img src={person}
                                                             alt="Dmitry Glushakov" />
                        </div>
                      </li>
                      <li className="page-sidebar__item b-team__item">
                        <div className="b-team__item-name">
                          <a href="#">
                            Nikanor
                            <br/>
                            Khalin
                          </a>
                        </div>
                        <div className="b-team__item-photo"><img src={person1}
                                                             alt="Nikanor Khalin" />
                        </div>
                      </li>
                      <li className="page-sidebar__item b-team__item">
                        <div className="b-team__item-name">
                          <a href="#">
                            Ivan
                            <br/>
                            Salkovsky
                          </a>
                        </div>
                        <div className="b-team__item-photo"><img src={person2}
                                                             alt="Ivan Salkovsky" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <a href="mailto:contact-us@metals-wire.com" className="b-team__button button button__blue">
                      <i className="icon">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.5 4H4.5C3.675 4 3 4.675 3 5.5V19L6 16H16.5C17.325 16 18 15.325 18 14.5V5.5C18 4.675 17.325 4 16.5 4ZM16.5 14.5H6L4.5 16V5.5H16.5V14.5ZM6.75 9.25H8.25V10.75H6.75V9.25ZM9.75 9.25H11.25V10.75H9.75V9.25ZM12.75 9.25H14.25V10.75H12.75V9.25Z"
                            fill="white"/>
                        </svg>
                      </i>
                      Contact us
                    </a>
                  </div>
                </section>
                <section className="quotes card card-white page-sidebar__block">
                  <div className="page-sidebar__name">Quotes</div>
                  <div className="page-sidebar__list quotes__list">
                    <ul>
                      {quotes.map(quote => {
                        return <Quote key={quote.name} data={quote} />
                      })}
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Main;
