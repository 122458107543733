import { useState, useEffect } from 'react';

function Scale(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(props.isPercentScale)
  }, [props.isPercentScale]);

  if (props.isWidgetReady) {
    return (
      <li className={ isActive ? "state-selected": null}><a href="#" onClick={props.clickHandler}>% growth scale</a></li>
    );
  } else {
    return null;
  }

}

export default Scale;