import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';
import { formatUTCDatetime } from '../../common/formatting'

import ListItem from '../../common/components/ListItem'

import Table from './Table'

function Commodities() {
  const { l1, l2, l3, l4 } = useParams();

  const [firstLevel, setFirstLevel] = useState(null);
  const [firstLevels, setFirstLevels] = useState([]);
  
  const [secondLevel, setSecondLevel] = useState(null);
  const [secondLevels, setSecondLevels] = useState([]);
  
  const [thirdLevel, setThirdLevel] = useState(null);
  const [thirdLevels, setThirdLevels] = useState([]);
  
  const [fourthLevel, setFourthLevel] = useState(null);
  const [fourthLevels, setFourthLevels] = useState([]);

  const [spotDate, setSpotDate] = useState(null);

  useEffect(() => {
    if (l1)
      setFirstLevel(l1);

    if (l2)
      setSecondLevel(l2);

    if (l3)
      setThirdLevel(l3);

    if (l4)
      setFourthLevel(l4);
  }, [l1, l2, l3, l4]);

  useEffect(() => {
    newAxiosInstance.get('option/level/l1/').then((res) => {
      setFirstLevels(res.data);
    });
  }, []);
  
  useEffect(() => {
    if (firstLevel) {
      newAxiosInstance.get('option/level/' + firstLevel + '/l2/').then((res) => {
        setSecondLevels(res.data);
      });
    }
  }, [firstLevel]);
  
  useEffect(() => {
    if (firstLevel && secondLevel) {
      newAxiosInstance.get('option/level/' + firstLevel + '/' + secondLevel + '/l3/').then((res) => {
        setThirdLevels(res.data);
      });
    }
  }, [firstLevel, secondLevel]);
  
  useEffect(() => {
    if (firstLevel && secondLevel && thirdLevel) {
      newAxiosInstance.get('option/level/' + firstLevel + '/' + secondLevel + '/' + thirdLevel + '/l4/').then((res) => {
        setFourthLevels(res.data);
      });
    }
  }, [firstLevel, secondLevel, thirdLevel]);

  useEffect(() => {
    newAxiosInstance.get('ref-moment/spot_date/').then((res) => {
      setSpotDate(res.data.value);
    });
  }, []);

  function handleFirstLevelClick(e) {
    e.preventDefault();

    setFirstLevel(prevState => {
      if (prevState != e.target.getAttribute('data-value')) {
        setSecondLevel(null);
        setSecondLevels([]);

        setThirdLevel(null);
        setThirdLevels([]);

        setFourthLevel(null);
        setFourthLevels([]);

        return e.target.getAttribute('data-value');
      }
      else {
        setSecondLevel(null);

        setThirdLevel(null);

        setFourthLevel(null);

        return prevState;
      }
    });
  }

  function handleSecondLevelClick(e) {
    e.preventDefault();

    setSecondLevel(prevState => {
      if (prevState != e.target.getAttribute('data-value')) {
        setThirdLevel(null);
        setThirdLevels([]);

        setFourthLevel(null);
        setFourthLevels([]);

        return e.target.getAttribute('data-value');
      }
      else {
        setThirdLevel(null);

        setFourthLevel(null);

        return prevState;
      }
    });
  }

  function handleThirdLevelClick(e) {
    e.preventDefault();

    setThirdLevel(prevState => {
      if (prevState != e.target.getAttribute('data-value')) {
        setFourthLevel(null);
        setFourthLevels([]);

        return e.target.getAttribute('data-value');
      }
      else {
        setFourthLevel(null);

        return prevState;
      }
    });
  }

  return (
    <div className="app page-metals-mining-summary">
      <div className="wrapper">
        <main className="content">
          <div className="container">
            <div className="metals-mining-summary">
              <div className="summary__head">
                { firstLevels.length > 0 && !!firstLevels[0].level &&
                  <ul className="tags">
                    {firstLevels.map(levelData => {
                      return <ListItem key={levelData.level} withLink={true} linkClass={null} isActive={firstLevel === levelData.level} activeClass={'state-selected'} baseClass={null} instanceKey={levelData.level} instanceName={levelData.level} clickHandler={handleFirstLevelClick} />
                    })}
                  </ul>
                }
                <div className="export hidden-mobile">
                  <ul>
                    <li className="export__date">{formatUTCDatetime(spotDate, true, true, false)}</li>
                    <li>
                      <span>Spot Date</span>
                    </li>
                  </ul>
                </div>
              </div>
              { secondLevels.length > 0 && !!secondLevels[0].level &&
                <div className="summary__head">
                  <ul className="tags">
                    {secondLevels.map(levelData => {
                      return <ListItem key={levelData.level} withLink={true} linkClass={null} isActive={secondLevel === levelData.level} activeClass={'state-selected'} baseClass={null} instanceKey={levelData.level} instanceName={levelData.level} clickHandler={handleSecondLevelClick} />
                    })}
                  </ul>
                </div>
              }
              { thirdLevels.length > 0 && !!thirdLevels[0].level &&
                <div className="summary__head">
                  <ul className="tags">
                    {thirdLevels.map(levelData => {
                      return <ListItem key={levelData.level} withLink={true} linkClass={null} isActive={thirdLevel === levelData.level} activeClass={'state-selected'} baseClass={null} instanceKey={levelData.level} instanceName={levelData.level} clickHandler={handleThirdLevelClick} />
                    })}
                  </ul>
                </div>
              }
              { fourthLevels.length > 0 && !!fourthLevels[0].level &&
                <div className="summary__head">
                  <ul className="tags">
                    {fourthLevels.map(levelData => {
                      if (levelData.level)
                        return <ListItem key={levelData.level} withLink={true} linkClass={null} isActive={fourthLevel === levelData.level} activeClass={'state-selected'} baseClass={null} instanceKey={levelData.level} instanceName={levelData.level} clickArgs={[true, setFourthLevel, null, null]} />
                    })}
                  </ul>
                </div>
              }
              <div className="table__container">
                <Table firstLevel={firstLevel} secondLevel={secondLevel} thirdLevel={thirdLevel} fourthLevel={fourthLevel} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Commodities;