import { formatPercents, formatPrice } from '../common/formatting';

function Quote(props) {
  return (
    <li className="page-sidebar__item quotes__item">
      <div className="quotes__item-info">
        <div className="quotes__item-name"><a href={'/company/' + props.data.company_key + '/'}>{props.data.comp_title}, {props.data.share_type}</a>({props.data.name})</div>
        <ul>
          <li>{props.data.currency} {formatPrice(props.data.price)}</li>
          <li>TP: {formatPrice(props.data.target_price)} {props.data.currency !== props.data.target_price_currency ? props.data.target_price_currency: null}</li>
        </ul>
      </div>
      <div className="quotes__item-actions">
        <div className="quotes__item-change">UPSIDE {formatPercents(props.data.upside_value, false, false, true)}</div>
        <a className="quotes__item-button" href={'/company/' + props.data.company_key + '/'}>{props.data.rec_type.toLowerCase()}</a>
      </div>
    </li>
  );
}

export default Quote;