import { formatFloats, formatPercents, formatPrice } from '../../formatting';
import SharePerf from './SharePerf';

function TableRow(props) {
  if (props.data.rocket_type === 0) {
    return (
      <tr>
        <td className="sticky-col"><a href={'/company/' + props.data.key + '/'}>{props.data.name}</a></td>
        <td>{props.data.share.name}</td>
        <td>{props.data.share.share_type}</td>
        <td>{formatPrice(props.data.share.price)} <SharePerf data={props.data} withSharePerf={props.withSharePerf} /></td>
        <td>{formatFloats(props.data.mcap_usd, 0, true)}</td>
        <td>{formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</td>
        <td>{formatFloats(props.data.multipliers.P_E, 2, true)}</td>
        <td>{formatPercents(props.data.multipliers.FCF_yield, true)}</td>
        <td>{formatPercents(props.data.multipliers.Div_yield, true)}</td>
      </tr>
    );
  } else if (props.data.rocket_type <= 3) {
    return (
      <tr>
        <td className="sticky-col">
          <div className="d-flex justify-content-between align-items-center">
            <div><a href={'/company/' + props.data.key + '/'}>{props.data.name}</a></div>
            <i className="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.1713 13.1623C10.8378 13.415 10.4015 13.655 9.90118 13.8319C9.79853 13.8698 9.69588 13.7687 9.72157 13.6676C9.79853 13.3518 9.8499 13.0233 9.8499 12.6822C9.8499 10.9261 8.59263 9.43526 8.15636 8.95517C8.06651 8.86669 7.92547 8.86669 7.84843 8.95517C7.41225 9.4226 6.15489 10.9135 6.15489 12.6822C6.15489 13.0738 6.21905 13.4655 6.3217 13.8192C6.34738 13.9203 6.25753 14.0214 6.15489 13.9961C5.29531 13.7687 4.60247 13.4023 4.3459 13.2001C2.25467 11.4566 2.986 8.71505 3.96099 7.27478C5.03883 5.64513 6.32185 4.25526 6.25771 2.30986C6.24491 2.08249 6.50149 1.93082 6.70678 2.03191C8.33615 2.84049 9.4523 4.59661 9.74738 5.99898C10.1836 5.59464 10.3632 4.96295 10.376 4.40712C10.376 4.14176 10.7225 4.00279 10.9149 4.20496C12.3774 5.75891 14.6353 10.4965 11.1713 13.1623Z"
                  fill="#F0C940"/>
              </svg>
            </i>
          </div>
        </td>
        <td>{props.data.share.name}</td>
        <td>{props.data.share.share_type}</td>
        <td>{formatPrice(props.data.share.price)} <SharePerf data={props.data} withSharePerf={props.withSharePerf} /></td>
        <td>{formatFloats(props.data.mcap_usd, 0, true)}</td>
        <td>{formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</td>
        <td>{formatFloats(props.data.multipliers.P_E, 2, true)}</td>
        <td>{formatPercents(props.data.multipliers.FCF_yield, true)}</td>
        <td>{formatPercents(props.data.multipliers.Div_yield, true)}</td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td className="sticky-col">
          <div className="d-flex justify-content-between align-items-center">
            <div><a href={'/company/' + props.data.key + '/'}>{props.data.name}</a></div>
            <i className="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.1713 13.1623C10.8378 13.415 10.4015 13.655 9.90118 13.8319C9.79853 13.8698 9.69588 13.7687 9.72157 13.6676C9.79853 13.3518 9.8499 13.0233 9.8499 12.6822C9.8499 10.9261 8.59263 9.43526 8.15636 8.95517C8.06651 8.86669 7.92547 8.86669 7.84843 8.95517C7.41225 9.4226 6.15489 10.9135 6.15489 12.6822C6.15489 13.0738 6.21905 13.4655 6.3217 13.8192C6.34738 13.9203 6.25753 14.0214 6.15489 13.9961C5.29531 13.7687 4.60247 13.4023 4.3459 13.2001C2.25467 11.4566 2.986 8.71505 3.96099 7.27478C5.03883 5.64513 6.32185 4.25526 6.25771 2.30986C6.24491 2.08249 6.50149 1.93082 6.70678 2.03191C8.33615 2.84049 9.4523 4.59661 9.74738 5.99898C10.1836 5.59464 10.3632 4.96295 10.376 4.40712C10.376 4.14176 10.7225 4.00279 10.9149 4.20496C12.3774 5.75891 14.6353 10.4965 11.1713 13.1623Z"
                  fill="#D42C37"/>
              </svg>
            </i>
          </div>
        </td>
        <td>{props.data.share.name}</td>
        <td>{props.data.share.share_type}</td>
        <td>{formatPrice(props.data.share.price)} <SharePerf data={props.data} withSharePerf={props.withSharePerf} /></td>
        <td>{formatFloats(props.data.mcap_usd, 0, true)}</td>
        <td>{formatFloats(props.data.multipliers.EV_EBITDA, 2, true)}</td>
        <td>{formatFloats(props.data.multipliers.P_E, 2, true)}</td>
        <td>{formatPercents(props.data.multipliers.FCF_yield, true)}</td>
        <td>{formatPercents(props.data.multipliers.Div_yield, true)}</td>
      </tr>
    );
  }
}

export default TableRow;