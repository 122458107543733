import React from 'react';
import SensTableRow from './SensTableRow';

function SensTable(props) {
  return (
    <React.Fragment>
      {props.drivers.map(driver => {
        return <SensTableRow key={driver.key} data={driver} driver={props.driver} rowHandler={props.rowHandler} />
      })}
    </React.Fragment>
  );
}

export default SensTable;