import { useState, useEffect } from 'react';

import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';
import { getDatePart, formatDateObj, getFilterListOfDicts } from '../../common/formatting';
import { handleCheckBoxClick, handleSearchInputChange, checkListOfDictsHasKey } from '../../common/handlers';

import ListItem from '../../common/components/ListItem';
import CheckBox from '../../common/components/CheckBox';
import ChosenFilter from '../../common/components/ChosenFilter';

import EventRow from './EventRow';
import EventCard from './EventCard';

function Events() {
  const [eventsTypes, setEventsTypes] = useState([])
  const [selectedEventsTypes, setSelectedEventsTypes] = useState([])

  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [initialCompanies, setInitialCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [filtersJSON, setFiltersJSON] = useState({
    'sectors': [],
    'companies': [],
    'events_types': [],
  });

  const [eventsYears, setEventsYears] = useState([]);
  const [eventsMonths, setEventsMonths] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]);

  const [activeY, setActiveY] = useState(getDatePart(null, 'year'));
  const [activeM, setActiveM] = useState(getDatePart(null, 'month'));
  const [activeD, setActiveD] = useState(getDatePart(null, 'day'));
  const [startDate, setStartDate] = useState(formatDateObj(new Date(
    getDatePart(null, 'year'),
    getDatePart(null, 'month'),
    getDatePart(null, 'day')
  )));
  const [endDate, setEndDate] = useState(getInitialEndDate(false));
  const [tableEndDate, setTableEndDate] = useState(getInitialEndDate(true));
  const [activeDate, setActiveDate] = useState(new Date(
    getDatePart(null, 'year'),
    getDatePart(null, 'month'),
    getDatePart(null, 'day')
  ));

  const [eventsPage, setEventsPage] = useState(1);
  const [eventsPagesMaxNum, setEventsPagesMaxNum] = useState(0);
  const [events, setEvents] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [eventsPagesNumsStart, setEventsPagesNumsStart] = useState([]);
  const [eventsPagesNumsMiddle, setEventsPagesNumsMiddle] = useState([]);
  const [eventsPagesNumsEnd, setEventsPagesNumsEnd] = useState([]);

  function getInitialEndDate(isTable) {
    var initialStartDate = new Date(
      getDatePart(null, 'year'),
      getDatePart(null, 'month'),
      getDatePart(null, 'day')
    );
    var endDate = new Date(
      getDatePart(null, 'year'),
      getDatePart(null, 'month'),
      getDatePart(null, 'day')
    );

    if (isTable) {
      endDate.setDate(initialStartDate.getDate() + 180);
    } else {
      endDate.setDate(initialStartDate.getDate() + 60);
    }
    return formatDateObj(endDate);
  }

  useEffect(() => {
    var startDate = new Date(activeY, activeM, 1);
    var endDate = new Date(activeY, activeM, 1);
    var tableEndDate = new Date(activeY, activeM, 1);
    var activeDate = new Date(activeY, activeM, 1);

    endDate.setDate(startDate.getDate() + 60);
    tableEndDate.setDate(startDate.getDate() + 180);
    startDate = formatDateObj(startDate);
    endDate = formatDateObj(endDate);
    tableEndDate = formatDateObj(tableEndDate);

    setStartDate(startDate);
    setEndDate(endDate);
    setTableEndDate(tableEndDate);
    setActiveDate(activeDate);
    setEventsPage(1);
  }, [activeY, activeM]);

  useEffect(() => {
    var startDate = new Date(activeY, activeM, activeD);
    var endDate = new Date(activeY, activeM, activeD);
    var tableEndDate = new Date(activeY, activeM, activeD);
    var activeDate = new Date(activeY, activeM, activeD);

    endDate.setDate(startDate.getDate() + 60);
    tableEndDate.setDate(startDate.getDate() + 180);
    startDate = formatDateObj(startDate);
    endDate = formatDateObj(endDate);
    tableEndDate = formatDateObj(tableEndDate);

    setStartDate(startDate);
    setEndDate(endDate);
    setTableEndDate(tableEndDate);
    setActiveDate(activeDate);
    setEventsPage(1);
  }, [activeD]);

  useEffect(() => {
    var newFiltersJSON = {
      'sectors': getFilterListOfDicts(selectedSectors),
      'companies': getFilterListOfDicts(selectedCompanies),
      'events_types': getFilterListOfDicts(selectedEventsTypes),
    }

    setFiltersJSON(newFiltersJSON);
  }, [selectedSectors, selectedCompanies, selectedEventsTypes]);

  useEffect(() => {
    axiosInstance.post('events/summary/?start_date=' + startDate + '&end_date=' + endDate, filtersJSON).then((res) => {
      var valuesData = [];
      var setFuncs = [setActiveY, setActiveM, setActiveD, getDatePart];

      var value = []
      var datetimeObj = null;

      res.data.forEach(result => {
        datetimeObj = new Date(result.moment);
        value = [datetimeObj, result.events_count, result.fill_color, result.stroke_color];
        valuesData.push(value);
      });

      window.setEventsCal(valuesData, setFuncs);
      setEventsPage(1);
    });
  }, [startDate, endDate, filtersJSON]);

  useEffect(() => {
    axiosInstance.post('events/table/?limit=10&offset=' + (eventsPage - 1) * 10 + '&start_date=' + startDate + '&end_date=' + tableEndDate, filtersJSON).then((res) => {
      var eventsPagesCount = Math.floor(res.data.count / 10);
      if (res.data.count % 10 > 0)
        eventsPagesCount++;

      setEventsPagesMaxNum(eventsPagesCount);
      setEvents(res.data.results);
    });
  }, [startDate, tableEndDate, filtersJSON, eventsPage]);

  useEffect(() => {
    axiosInstance.post('events/table/?limit=10&offset=0&start_date=' + startDate + '&end_date=' + tableEndDate, filtersJSON).then((res) => {
      var newActiveEvents = [];

      newActiveEvents = res.data.results.filter((item) => {
        return (new Date(item.moment.slice(0, -1))).getTime() === activeDate.getTime();
      });

      setActiveEvents(newActiveEvents);
    });
  }, [startDate, tableEndDate, filtersJSON, activeDate]);
  
  useEffect(() => {
    if (eventsPagesMaxNum > 10) {
      var eventsPagesNums = [];
      var eventsPagesNumsStart = [1, 2, 3, 4, 5];
      var eventsPagesNumsEnd = [
        eventsPagesMaxNum - 4,
        eventsPagesMaxNum - 3,
        eventsPagesMaxNum - 2,
        eventsPagesMaxNum - 1,
        eventsPagesMaxNum
      ];

      if (eventsPage >= 5 && eventsPage <= eventsPagesMaxNum - 4) {
        eventsPagesNumsStart = [1, 2];
        eventsPagesNumsEnd = [eventsPagesMaxNum - 1, eventsPagesMaxNum];

        eventsPagesNums.push(eventsPage - 1);
        eventsPagesNums.push(eventsPage);
        eventsPagesNums.push(eventsPage + 1);
      }

      setEventsPagesNumsStart(eventsPagesNumsStart);
      setEventsPagesNumsMiddle(eventsPagesNums);
      setEventsPagesNumsEnd(eventsPagesNumsEnd);
    } else {
      var eventsPagesNums = [];
      for (var i = 1; i < eventsPagesMaxNum + 1; i++) {
        eventsPagesNums.push(i);
      }

      setEventsPagesNumsStart(eventsPagesNums);
      setEventsPagesNumsMiddle(prevState => {
        if (prevState.length === 0) {
          return prevState;
        }
        else {
          return [];
        }
      });
      setEventsPagesNumsEnd(prevState => {
        if (prevState.length === 0) {
          return prevState;
        }
        else {
          return [];
        }
      });
    }
  }, [eventsPagesMaxNum, eventsPage]);

  useEffect(() => {
    axiosInstance.get('option/event-type/').then((res) => {
      setEventsTypes(res.data);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('option/event-year/').then((res) => {
      setEventsYears(res.data);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('option/sector/').then((res) => {
      setSectors(res.data.sort(function(a, b) {
        return ('' + a.sector_name).localeCompare(b.sector_name);
      }));
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('option/company/').then((res) => {
      var newData = [];
      newData = res.data.filter((item) => {
        return item.ev_ebitda;
      });

      setInitialCompanies(newData);
      setCompanies(newData);
    });
  }, []);

  useEffect(() => {
    window.setDate(activeY, activeM, activeD);
  }, [activeY, activeM, activeD]);

  useEffect(() => {
    window.setReactHandleDayClick(handleDayClick);
  }, []);

  function handleArrowClick(e, days) {
    e.preventDefault();

    var oldDate = new Date(activeY, activeM, activeD);
    var newDate = new Date(activeY, activeM, activeD);
    newDate.setDate(oldDate.getDate() + days);

    setActiveY(getDatePart(newDate, 'year'));
    setActiveM(getDatePart(newDate, 'month'));
    setActiveD(getDatePart(newDate, 'day'));
  }

  function handleYearClick(e) {
    setActiveY(parseInt(e.target.getAttribute('data-nav-year')));
  }

  function handleMonthClick(e) {
    setActiveM(parseInt(e.target.getAttribute('data-nav-mounth')));
  }

  function handleDayClick(day) {
    setActiveD(day);
  }
  
  function handleEventsButtonClick(e) {
    e.preventDefault();
    setEventsPage(prevState => {
      return prevState + parseInt(e.target.getAttribute('data-value'));
    });
  }

  function handleEventsPageClick(e) {
    e.preventDefault();
    setEventsPage(prevState => {
      if (prevState !== parseInt(e.target.getAttribute('data-value'))) {
        return parseInt(e.target.getAttribute('data-value'));
      } else {
        return prevState;
      }
    });
  }

  function handleClearAllClick(e) {
    setSelectedSectors([]);
    setSelectedCompanies([]);
    setSelectedEventsTypes([]);
  }

  return (
    <div className="app dark-theme page-events-calendar">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__inner">
              <div className="hero__description">
                <div className="breadcrumbs">
                  <ul>
                    <li><a href='/'>Main</a></li>
                    <li><span aria-current="page">Events</span></li>
                  </ul>
                </div>
                <h1>Events</h1>
                <div className="hero__event-calendar">
                  <div className="events-out">
                    <div className="events-arrow events-arrow_left" onClick={(e) => handleArrowClick(e, -7)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.99489 12.4144L13.5849 17.0044C13.9749 17.3944 14.6049 17.3944 14.9949 17.0044C15.3849 16.6144 15.3849 15.9844 14.9949 15.5944L11.1149 11.7044L14.9949 7.82438C15.3849 7.43437 15.3849 6.80437 14.9949 6.41437C14.6049 6.02437 13.9749 6.02437 13.5849 6.41437L8.99489 11.0044C8.60489 11.3844 8.60489 12.0244 8.99489 12.4144Z"
                          fill="white"/>
                      </svg>
                    </div>
                    <div className="events-wrap">
                      <div>
                        <div id="events-calendar" style={{position: 'relative', left: 0}} className="events-chart"></div>
                      </div>
                    </div>
                    <div className="events-arrow events-arrow_right" onClick={(e) => handleArrowClick(e, 7)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.9949 12.4144L10.4049 17.0044C10.0149 17.3944 9.38485 17.3944 8.99485 17.0044C8.60485 16.6144 8.60485 15.9844 8.99485 15.5944L12.8749 11.7044L8.99485 7.82438C8.60485 7.43438 8.60485 6.80437 8.99485 6.41437C9.38485 6.02437 10.0149 6.02437 10.4049 6.41437L14.9949 11.0044C15.3849 11.3844 15.3849 12.0244 14.9949 12.4144Z"
                          fill="white"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="events-calendar">
              <div className="page-row">
                <div className="page-sidebar">
                  <div className="filters scrollable hidden-mobile">
                    <div className="filters__controls">
                      <div className="filters__collapse js-collapse">
                        <div className="filters__collapse-name js-collapse-link">
                          <div className="filters__collapse-nameText"><span>Event type</span>{selectedEventsTypes.length > 0 && <span className="filters__collapse-count">{selectedEventsTypes.length}</span>}</div>
                          <i className="icon js-collapse-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                                fill="#606980"/>
                            </svg>
                          </i>
                        </div>
                        <div className="filters__collapse-content js-collapse-content">
                          <div className="checkbox-list">
                            <ul className="scrollable">
                              {eventsTypes.map((eventType, index) => {
                                return <CheckBox key={eventType.event_type} withListItemWrap={true} id={'chkbx_' + (5 + index)} value={1} label={eventType.event_type} isActive={checkListOfDictsHasKey(selectedEventsTypes, 'key', eventType.event_type)} instanceKey={eventType.event_type} clickHandler={(e) => handleCheckBoxClick(e, setSelectedEventsTypes, eventType.event_type, eventType.event_type)} />
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filters__controls">
                      <div className="filters__collapse js-collapse">
                        <div className="filters__collapse-name js-collapse-link">
                          <div className="filters__collapse-nameText"><span>Sector</span>{selectedSectors.length > 0 && <span className="filters__collapse-count">{selectedSectors.length}</span>}</div>
                          <i className="icon js-collapse-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                                fill="#606980"/>
                            </svg>
                          </i>
                        </div>
                        <div className="filters__collapse-content js-collapse-content">
                          <div className="checkbox-list">
                            <ul className="scrollable">
                              {sectors.map((sector, index) => {
                                return <CheckBox key={sector.sector_key} withListItemWrap={true} id={'chkbx_' + (5 + eventsTypes.length + index)} value={1} label={sector.sector_name} isActive={checkListOfDictsHasKey(selectedSectors, 'key', sector.sector_key)} instanceKey={sector.sector_key} clickHandler={(e) => handleCheckBoxClick(e, setSelectedSectors, sector.sector_key, sector.sector_name)} />
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filters__controls">
                      <div className="filters__collapse js-collapse">
                        <div className="filters__collapse-name js-collapse-link">
                          <div className="filters__collapse-nameText"><span>Company</span>{selectedCompanies.length > 0 && <span className="filters__collapse-count">{selectedCompanies.length}</span>}
                          </div>
                          <i className="icon js-collapse-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                                fill="#606980"/>
                            </svg>
                          </i>
                        </div>
                        <div className="filters__collapse-content js-collapse-content">
                          <div className="filters__search-field"><input className="form-control" placeholder="Search" onChange={ (e) => handleSearchInputChange(e, setCompanies, initialCompanies, 'name') } /></div>
                          <div className="checkbox-list">
                            <ul className="scrollable">
                              {companies.map((company, index) => {
                                return <CheckBox key={company.key} withListItemWrap={true} id={'chkbx_' + (5 + eventsTypes.length + sectors.length + index)} value={1} label={company.name} isActive={checkListOfDictsHasKey(selectedCompanies, 'key', company.key)} instanceKey={company.key} clickHandler={(e) => handleCheckBoxClick(e, setSelectedCompanies, company.key, company.name)} />
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-body">
                  <div className="search__selected">
                    <div className="search__selected-items">
                      <ul>
                        {selectedEventsTypes.map(item => {
                          return <ChosenFilter key={item.key} data={item} clickHandler={(e) => handleCheckBoxClick(e, setSelectedEventsTypes, item.key, item.name)} />;
                        })}
                        {selectedSectors.map(item => {
                          return <ChosenFilter key={item.key} data={item} clickHandler={(e) => handleCheckBoxClick(e, setSelectedSectors, item.key, item.name)} />;
                        })}
                        {selectedCompanies.map(item => {
                          return <ChosenFilter key={item.key} data={item} clickHandler={(e) => handleCheckBoxClick(e, setSelectedCompanies, item.key, item.name)} />;
                        })}
                      </ul>
                    </div>
                    <div className="search__selected-clear">
                      <a href="#" onClick={handleClearAllClick}>
                        <span>Clear all</span>
                        <i className="icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.2005 3.80714C11.9405 3.54714 11.5205 3.54714 11.2605 3.80714L8.00047 7.06047L4.74047 3.80047C4.48047 3.54047 4.06047 3.54047 3.80047 3.80047C3.54047 4.06047 3.54047 4.48047 3.80047 4.74047L7.06047 8.00047L3.80047 11.2605C3.54047 11.5205 3.54047 11.9405 3.80047 12.2005C4.06047 12.4605 4.48047 12.4605 4.74047 12.2005L8.00047 8.94047L11.2605 12.2005C11.5205 12.4605 11.9405 12.4605 12.2005 12.2005C12.4605 11.9405 12.4605 11.5205 12.2005 11.2605L8.94047 8.00047L12.2005 4.74047C12.4538 4.48714 12.4538 4.06047 12.2005 3.80714Z"
                              fill="#0A2896"></path>
                          </svg>
                        </i>
                      </a>
                    </div>
                  </div>
                  <div className="events-calendar__body">
                    <div className="events-calendar__dashboard">
                      { activeEvents.length > 0 &&
                        <div className="cards">
                          {activeEvents.map(event => {
                            return <EventCard key={event.key} data={event} />
                          })}
                        </div>
                      }
                      <h2>Events List</h2>
                      <div className="events-list__table hidden-mobile">
                        <table>
                          <thead>
                            <tr>
                              <th className="sticky-col">Date</th>
                              <th>Event type</th>
                              <th>Entity</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {events.map(event => {
                              return <EventRow key={event.id} isRed={(new Date(event.moment.slice(0, -1))).getTime() === activeDate.getTime()} data={event} />
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="events-list__mobile hidden-desktop">
                        <div className="events-list__mobile--list">
                          <ul>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Index</a>
                                  <a href="#">MSCI</a>
                                </div>
                                <div><a href="#">MSCI Quarterly Index Review takes effect after close</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date color-red">Today</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Earnings release</a>
                                  <a href="#">TGK1</a>
                                </div>
                                <div><a href="#">TGK1 FY21 RAS</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date color-red">Today</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Earnings release</a>
                                  <a href="#">Fix Price</a>
                                </div>
                                <div><a href="#">Fix Price FY21 IFRS and conference call</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date color-red">Today</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Operating data</a>
                                  <a href="#">LSR Group</a>
                                </div>
                                <div><a href="#">LSR Group: Analyst Day</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date color-red">Today</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Earnings release</a>
                                  <a href="#">RusHydro</a>
                                </div>
                                <div><a href="#">RusHydro FY21 IFRS and conference call</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date">Tomorrow</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Operating data</a>
                                  <a href="#">Mosenergo</a>
                                </div>
                                <div><a href="#">Mosenergo FY21 RAS</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date">Tomorrow</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Index</a>
                                  <a href="#">LSR Group</a>
                                </div>
                                <div><a href="#">LSR Group: Analyst Day</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date">Tomorrow</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Earnings release</a>
                                  <a href="#">RusHydro</a>
                                </div>
                                <div><a href="#">RusHydro FY21 IFRS and conference call</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date">3 march</div>
                            </li>
                            <li className="events-list__mobile--list-item">
                              <div className="events-list__mobile--list-item--info">
                                <div className="events-list__mobile--list-item--name">
                                  <a href="#">Operating data</a>
                                  <a href="#">LSR Group</a>
                                </div>
                                <div><a href="#">LSR Group: Analyst Day</a></div>
                              </div>
                              <div className="events-list__mobile--list-item--date">3 march</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ul className="pagination">
                        <li className={eventsPage > 1 ? "page__item": "page__item state-disabled"}>
                          {eventsPage > 1 &&
                            <a className="page__button" href="#" data-value='-1' onClick={handleEventsButtonClick}>Previous</a>
                          }
                          {eventsPage < 2 &&
                            <span className="page__button">Previous</span>
                          }
                        </li>
                        {eventsPagesNumsStart.map(pageNum => {
                          return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={eventsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleEventsPageClick} />
                        })}
                        {eventsPagesNumsMiddle.length > 0 &&
                          <li className="page__item">
                            <span className="page__delimeter">...</span>
                          </li>
                        }
                        {eventsPagesNumsMiddle.map(pageNum => {
                          return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={eventsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleEventsPageClick} />
                        })}
                        {(eventsPagesNumsMiddle.length > 0 || eventsPagesNumsEnd.length > 0) &&
                          <li className="page__item">
                            <span className="page__delimeter">...</span>
                          </li>
                        }
                        {eventsPagesNumsEnd.map(pageNum => {
                          return <ListItem key={pageNum} withLink={true} linkClass={'page__link'} isActive={eventsPage === pageNum} activeClass={'page__item state-active'} baseClass={'page__item'} instanceKey={pageNum} instanceName={pageNum} clickHandler={handleEventsPageClick} />
                        })}
                        <li className={eventsPage !== eventsPagesMaxNum ? "page__item": "page__item state-disabled"}>
                          {eventsPage !== eventsPagesMaxNum &&
                            <a className="page__button" href="#" data-value='1' onClick={handleEventsButtonClick}>Next</a>
                          }
                          {eventsPage === eventsPagesMaxNum &&
                            <span className="page__button">Next</span>
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="events-calendar__datepicker hidden-mobile">
                      <div className="date__select date_wrap">
                        <p className="date__title">Archive</p>
                        <div className="date__title--mobile d-flex">
                          <div className="date__back" data-close-date>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.99563 12.4144L13.5856 17.0044C13.9756 17.3944 14.6056 17.3944 14.9956 17.0044C15.3856 16.6144 15.3856 15.9844 14.9956 15.5944L11.1156 11.7044L14.9956 7.82438C15.3856 7.43437 15.3856 6.80437 14.9956 6.41437C14.6056 6.02437 13.9756 6.02437 13.5856 6.41437L8.99563 11.0044C8.60563 11.3844 8.60563 12.0244 8.99563 12.4144Z"
                                fill="#606980"/>
                            </svg>
                          </div>
                          <div className="date__header">Period</div>
                          <div className="date__text"><a href="#">Clear</a></div>
                        </div>
                        <div className="date__years date__years--pc d-flex wrap-flex">
                          {eventsYears.map(eventsYear => {
                            return <p key={eventsYear.year} className={eventsYear.year === activeY ? 'date__item date__item--active': 'date__item'} data-nav-year={eventsYear.year} onClick={handleYearClick}>{eventsYear.year}</p>;
                          })}
                        </div>
                        <div className="date__years date__years--mobile d-flex wrap-flex">
                          <p className="date__item" data-nav-year="2022">2022</p>
                          <p className="date__item" data-nav-year="2021">2021</p>
                          <p className="date__item" data-nav-year="2020">2020</p>
                          <p className="date__item" data-nav-year="2019">2019</p>
                          <p className="date__item" data-nav-year="2018">2018</p>
                          <p className="date__item" data-nav-year="2017">2017</p>
                          <p className="date__item" data-nav-year="2016">2016</p>
                          <p className="date__item" data-nav-year="2015">2015</p>
                          <p className="date__item" data-nav-year="2014">2014</p>
                          <p className="date__item" data-nav-year="2013">2013</p>
                          <p className="date__item" data-nav-year="2012">2012</p>
                          <p className="date__item" data-nav-year="2011">2011</p>
                        </div>
                        <div className="date__mounths d-flex wrap-flex">
                          {eventsMonths.map((eventsMonth, index) => {
                            return <p key={index} className={index === activeM ? 'date__item date__item--active': 'date__item'} data-nav-mounth={index} onClick={handleMonthClick}>{eventsMonth}</p>;
                          })}
                        </div>
                        <div className="date-picker"></div>
                        <button className="date__button button button__blue" data-close-date>Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Events;