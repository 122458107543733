import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import axiosInstance from '../../axios/instance';
import { formatDatetime } from '../../common/formatting';

import ObjectLink from '../../common/components/ObjectLink';

function NewsDetail() {
  const divTG = useRef(null);
  const { newsID } = useParams();

  const [newsData, setNewsData] = useState({
    'sectors_data': [],
    'companies_data': [],
    'tg_link': null,
    'header': null,
    'moment': null,
    'body': null,
  });
  const [isTGBlocked, setIsTGBlocked] = useState(true);

  useEffect(() => {
    axiosInstance.get('news/' + newsID + '/').then((res) => {
      setNewsData(res.data);
    });
  }, [newsID]);

  useEffect(() => {
    if (newsData.tg_link) {
      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://telegram.org/js/telegram-widget.js?19';

      script.setAttribute('id', 'tgScript');
      script.setAttribute('data-telegram-post', newsData.tg_link.replace('https://t.me/', ''));
      script.setAttribute('data-width', '100%');
      script.setAttribute('data-userpic', 'false');
      script.setAttribute('data-color', '13B4C6');
      script.setAttribute('data-dark-color', '39C4E8');

      script.addEventListener('load', function () {
        setIsTGBlocked(false);
      });

      divTG.current.appendChild(script);

      return () => {
        divTG.current.removeChild(script);
      };
    }
  }, [newsData]);

  return (
    <div className="app page-publication">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__description">
              <div className="breadcrumbs" style={{ marginBottom: '30px' }}>
                <ul>
                  <li><a href="/">Main</a></li>
                  <li><a href="/equities">Equities</a></li>
                  <li><span aria-current="page">{newsData.header}</span></li>
                </ul>
              </div>
              <div className="search__selected" style={{ marginBottom: '60px' }}>
                <div className="search__selected-items">
                  <ul>
                    { newsData.sectors_data.length > 0 &&
                      newsData.sectors_data.map(sectorData => {
                        return <ObjectLink key={sectorData.key} objType='sector' extraExpr=' board' data={sectorData} />;
                      })
                    }
                    { newsData.companies_data.length > 0 &&
                      newsData.companies_data.map(companyData => {
                        if (companyData.ev_ebitda)
                          return <ObjectLink key={companyData.key} objType='company' extraExpr=' board' data={companyData} />;
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="publication">
              <div className="publication__inner">
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__head">
                      <div className="publication__head-info">
                        <ul>
                          <li>Metals Wire</li>
                          <li>{newsData.header}</li>
                          <li>{formatDatetime(newsData.moment, true, false, true)}</li>
                        </ul>
                      </div>
                      <div className="publication__head-text">
                        <h1>{newsData.header}</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="publication__section">
                  <div className="publication__container">
                    <div className="publication__row">
                      <div className="publication__col-left">
                        <div className="publication__text">
                          <div ref={divTG} />
                          { (newsData.tg_link == null || isTGBlocked) ? <p dangerouslySetInnerHTML={{ __html: newsData.body }} />: null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default NewsDetail;
