import { useState, useEffect } from 'react';

import newAxiosInstance from '../../axios/newInstance';

import ListItem from '../../common/components/ListItem'

import RepTable from './RepTable';

function Rep(props) {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [repData, setRepData] = useState(null);
  const [repPeriod, setRepPeriod] = useState(null);
  const [chartPeriod, setChartPeriod] = useState('12m');
  const [repScenario, setRepScenario] = useState('IS0006');

  useEffect(() => {
    newAxiosInstance.get('company-info/' + props.companyKey).then((res) => {
      var newCompanyInfo = {};

      res.data.results.forEach(result => {
        newCompanyInfo[result.period_expr] = result.date_expr;
      });

      setCompanyInfo(newCompanyInfo);
    });
  }, [props.companyKey]);

  useEffect(() => {
    newAxiosInstance.get('table/rep/' + props.companyKey + '/S').then((res) => {
      setRepData(res.data);
      if (res.data.length > 0) {
        setRepPeriod(res.data[0].periods[0]);
      }
    });
  }, [props.companyKey]);

  useEffect(() => {
    if(repPeriod)
      window.newGetChart(
        'divChartContainerRepAnalysis',
        props.companyKey,
        'rep?period=' + repPeriod + '&output=' + repScenario + '&duration=' + chartPeriod,
        'historical'
      );
  }, [props.companyKey, repPeriod, repScenario, chartPeriod]);

  return (
    <div className="col col-100">
      <div className="card card-white">
        <div className="row">
          <div className="col col-33" style={props.isShort ? { maxWidth: '41%', flex: '0 1 41%' } : null}>
            <div className="card__head">
              <div className="card__head-name">
                <span>
                  Reporting analysis
                </span>
              </div>
              <ul className="tags hidden-desktop">
                <li className="state-selected"><a href="#">3 months</a></li>
                <li><a href="#">6 months</a></li>
                <li><a href="#">1 year</a></li>
              </ul>
            </div>
            <div className="company__table-reporting hidden-mobile">
              <table>
                <tbody>
                  <tr>
                    <td>Period</td>
                    <td>
                      <div className="custom-select">
                        <div className="custom-select__inner">
                          <div className="custom-select__trigger"><span>{repPeriod && repPeriod}</span>
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651V9.26651Z"
                                  fill="#606980"></path>
                              </svg>
                            </i>
                          </div>
                          <div className="custom-select__options position-right">
                            <div className="custom-select__options-inner scrollable">
                              <ul className="custom-select__options-list">
                                {repData && repData[0].periods.map(periodData => {
                                  return <ListItem key={periodData} withLink={false} linkClass={null} isActive={repPeriod === periodData} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={periodData} instanceName={periodData} clickArgs={[true, setRepPeriod, null, null]} />
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>Reporting date</td>
                    <td>{ (companyInfo && repPeriod) && companyInfo[repPeriod]}</td>
                  </tr>
                  <tr>
                    <td>Scenario</td>
                    <td>SPOT</td>
                    <td>Consensus</td>
                    <td>vs. Consensus</td>
                  </tr>
                  { (repData && repPeriod) &&
                    <RepTable repData={repData} repPeriod={repPeriod} repScenario={repScenario} rowHandler={setRepScenario} />
                  }
                </tbody>
              </table>
            </div>
            <div className="mobile-blue-select company-reporting-analysis-select hidden-desktop">
              <div className="custom-select">
                <div className="custom-select__inner">
                  <div className="custom-select__trigger"><span>Net income</span>
                    <div className="custom-select__arrow"></div>
                  </div>
                  <div className="custom-select__options position-left">
                    <div className="custom-select__options-inner scrollable">
                      <ul className="custom-select__options-list">
                        <li className="custom-select__option state-selected" data-value="EBITDA">
                          EBITDA
                        </li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                        <li className="custom-select__option" data-value="EBITDA">EBITDA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <span className="company-reporting-analysis-select--count company-reporting-analysis-select--count1">
                  <span>SPOT: 19,635</span>
                  <span>Consensus: 58.63 %</span>
              </span>
              <span className="company-reporting-analysis-select--count company-reporting-analysis-select--count2">vs. Consensus: 29.04 %</span>
            </div>
            <div className="company-reporting-analysis-filter hidden-desktop">
              <div className="company-reporting-analysis-filter--item">
                <div className="company-reporting-analysis-filter--name">Period</div>
                <div className="card__custom-select custom-select">
                  <div className="custom-select__inner">
                    <div className="custom-select__trigger"><span>2H21</span>
                      <i className="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                            fill="#0A2896"/>
                        </svg>
                      </i>
                    </div>
                    <div className="custom-select__options position-right">
                      <div className="custom-select__options-inner scrollable">
                        <ul className="custom-select__options-list">
                          <li className="custom-select__option state-selected" data-value="2H21">2H21</li>
                          <li className="custom-select__option" data-value="2H21">2H21</li>
                          <li className="custom-select__option" data-value="2H21   ">2H21</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-reporting-analysis-filter--item">
                <div className="company-reporting-analysis-filter--name">Reporting date</div>
                <div>10 Feb</div>
              </div>
            </div>
          </div>
          <div className="col col-66" style={props.isShort ? { maxWidth: '59%', flex: '0 1 59%' } : null}>
            <div className="card__head">
              <div className="card__head-name">
                <span>
                    Reporting chart
                </span>
              </div>
              <ul className="tags">
                <ListItem withLink={true} linkClass={null} isActive={chartPeriod === '3m'} activeClass={'state-selected'} baseClass={null} instanceKey={'3m'} instanceName={'3 months'} clickArgs={[true, setChartPeriod, null, null]} />
                <ListItem withLink={true} linkClass={null} isActive={chartPeriod === '6m'} activeClass={'state-selected'} baseClass={null} instanceKey={'6m'} instanceName={'6 months'} clickArgs={[true, setChartPeriod, null, null]} />
                <ListItem withLink={true} linkClass={null} isActive={chartPeriod === '12m'} activeClass={'state-selected'} baseClass={null} instanceKey={'12m'} instanceName={'12 months'} clickArgs={[true, setChartPeriod, null, null]} />
              </ul>
            </div>
            <div className="width-100">
              <div id="divChartContainerRepAnalysis" className="historical"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rep;