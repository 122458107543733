import React from 'react';
import MobileTableRow from './MobileTableRow';

function MobileTablePart(props) {
  return (
    <React.Fragment>
      <tr className="table__name">
        <td colSpan="2">{props.partName}</td>
      </tr>
      {props.data.map(tableRow => {
        return <MobileTableRow key={tableRow.key} period={props.period} rowName={tableRow.name} periods={tableRow.periods} formatting={tableRow.formatting} data={tableRow.values} />
      })}
    </React.Fragment>
  );
}

export default MobileTablePart;