import { formatFloats } from '../../common/formatting'

function SensTableRow(props) {
  function handleRowClick(e) {
    props.rowHandler(props.data.ref_ticker);
  }

  if (!!props.data.sens_ebitda || !!props.data.sens_equity) {
    return (
      <tr className={props.driver == props.data.ref_ticker ? "state-selected": null} onClick={(e) => handleRowClick(e)}>
        <td>{props.data.name}</td>
        <td>{formatFloats(props.data.sens_ebitda, 2, false)}{!!props.data.sens_ebitda && '%'}</td>
        <td>{formatFloats(props.data.sens_equity, 2, false)}{!!props.data.sens_equity && '%'}</td>
      </tr>
    );
  } else {
    return null;
  }
}

export default SensTableRow;