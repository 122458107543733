import TableRow from './TableRow';

function Table(props) {
  return (
    <table>
      <thead>
        <tr>
          <th className="sticky-col">{props.data.sector_name}</th>
          <th></th>
          <th>Share&nbsp;price</th>
          <th>Market&nbsp;Cap</th>
          <th>EV/EBITDA</th>
          <th>PE</th>
          <th>FCF yield</th>
          <th>Div yield</th>
          <th>X-score</th>
          <th>TU Date</th>
          <th>Date</th>
          <th>MW</th>
          <th>Cons</th>
          <th>diff</th>
          <th>Weighted</th>
          <th>Weighted vs Cons</th>
        </tr>
      </thead>
      <tbody>
        {props.data.companies.map(company => {
          if (company.multipliers.EV_EBITDA)
            return <TableRow key={company.key} data={company} />
        })}
      </tbody>
    </table>
  );
}

export default Table;