import axios from 'axios';

const baseURL = 'https://cruncher-api.acptl.ru/api/v2/';

const newAxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

export default newAxiosInstance;