import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';
import { formatUTCDatetime, formatPrice } from '../../common/formatting'
import { handleClick, handleSectionClick } from '../../common/handlers'

import ListItem from '../../common/components/ListItem'
import CheckBox from '../../common/components/CheckBox'
import NewsItem from '../../common/components/NewsItem';

import Table from './Table';
import MobileTable from './MobileTable';
import ExtraTable from './ExtraTable';
import LastNews from '../News/LastNews';
import NewsPager from '../News/NewsPager';
import SensChartsCompaniesList from './SensChartsCompaniesList';

function Sector() {
  const { sectorKey } = useParams();
  const [sectorData, setSectorData] = useState({
    'name': null,
  });

  const [scenarios, setScenarios] = useState([{'key': null, 'name': null}]);
  const [scenariosNames, setScenariosNames] = useState({'S': 'SPOT'})

  const [tabScenario, setTabScenario] = useState('S');
  const [tabScenarioName, setTabScenarioName] = useState('SPOT');
  const [spotDate, setSpotDate] = useState(null);
  const [commName, setCommName] = useState(null);
  const [commPrice, setCommPrice] = useState(null);
  const [calendarQ, setCalendarQ] = useState(null);
  const [calendarH, setCalendarH] = useState(null);
  const [tableData, setTableData] = useState({'companies': []});

  const [commodities, setCommodities] = useState([]);
  const [tooltips, setTooltips] = useState({});
  const [commPeriod, setCommPeriod] = useState('5 years');

  const [sensChartsTicker, setSensChartsTicker] = useState('EV_EBITDA');
  const [sensChartsFilter, setSensChartsFilter] = useState([]);
  const [sensChartsCompanies, setSensChartsCompanies] = useState([]);

  const [cashCostOptions, setCashCostOptions] = useState([]);
  const [cashCostOptionsNames, setCashCostOptionsNames] = useState({});
  const [cashCostOption, setCashCostOption] = useState(null);
  const [cashCostOptionName, setCashCostOptionName] = useState(null);

  const [balanceOptions, setBalanceOptions] = useState([]);

  const [marginOptions, setMarginOptions] = useState([]);
  const [marginOptionsNames, setMarginOptionsNames] = useState({});
  const [marginOption, setMarginOption] = useState(null);
  const [marginOptionName, setMarginOptionName] = useState(null);
  const [steelMarginHorizon, setSteelMarginHorizon] = useState('long');
  const [steelMarginHorizonNames, setSteelMarginHorizonNames] = useState({
    'long': 'Long-term',
    'mid': 'Medium-term',
    'short': 'Short-term',
  });
  const [steelMarginHorizonName, setSteelMarginHorizonName] = useState('Long-term');

  const [supplyChartKey, setSupplyChartKey] = useState('range_CLPCTOTL');
  const [demandIsOpen, setDemandIsOpen] = useState(false);
  const [deeperDemandIsOpen, setDeeperDemandIsOpen] = useState(false);
  const [supplyIsOpen, setSupplyIsOpen] = useState(false);
  const [balanceIsOpen, setBalanceIsOpen] = useState(false);
  const [inventoriesIsOpen, setInventoriesIsOpen] = useState(false);

  const [periodsData, setPeriodsData] = useState([]);
  const [marketData, setMarketData] = useState([]);

  useEffect(() => {
    newAxiosInstance.get('sector/' + sectorKey + '/').then((res) => {
      setSectorData(res.data);
    });
  }, [sectorKey]);

  useEffect(() => {
    newAxiosInstance.get('option/scenario/').then((res) => {
      var newScenarios = [];
      var newScenariosNames = {};

      res.data.forEach(result => {
        if (!result.key.includes('MW') && !result.key.includes('Sn')) {
          newScenarios.push(result)
          newScenariosNames[result.key] = result.name;
        }
      });

      setScenarios(newScenarios);
      setScenariosNames(newScenariosNames);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('ref-moment/spot_date/').then((res) => {
      setSpotDate(res.data.value);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('commodities/' + sectorKey + '/').then((res) => {
      if (res.data.results.length > 0) {
        setCommName(res.data.results[0].name);
        setCommPrice(res.data.results[0].price);
        setCommodities(res.data.results);
      }
    });
  }, [sectorKey]);

  function isCurScenario(x) {
     return x.scenario_key === tabScenario;
  }

  useEffect(() => {
    if (commodities.length > 0) {
      if (tabScenario == 'S') {
        setCommPrice(commodities[0].price);
      } else {
        setCommPrice(commodities[0].other_prices.filter(isCurScenario)[0].price)
      }
    }
  }, [tabScenario, commodities]);

  useEffect(() => {
    newAxiosInstance.get('ref-value/calend_q/').then((res) => {
      setCalendarQ(res.data.value);
    });
  }, []);

  useEffect(() => {
    newAxiosInstance.get('ref-value/calend_h/').then((res) => {
      setCalendarH(res.data.value);
    });
  }, []);
  
  useEffect(() => {
    newAxiosInstance.get('table/sector/' + sectorKey + '/' + tabScenario + '/').then((res) => {
      setTableData(res.data);
    });
  }, [sectorKey, tabScenario]);

  useEffect(() => {
    axiosInstance.get('tooltips/').then((res) => {
      if (res.data.results.length > 0) {
        var newTooltips = {};
        res.data.results.forEach(result => {
          newTooltips[result.key] = result.value;
        });
        setTooltips(newTooltips);
      }
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('charts-options/' + sectorKey + '/cash_cost/').then((res) => {
      if (res.data.results.length > 0) {
        var newCashCostOptionsNames = {};
        res.data.results.forEach(result => {
          newCashCostOptionsNames[result.ref_key] = result.value;
        });

        setCashCostOptions(res.data.results);
        setCashCostOptionsNames(newCashCostOptionsNames);
        setCashCostOption(res.data.results[0].ref_key);
        setCashCostOptionName(res.data.results[0].value);
      }
    });
  }, [sectorKey]);

  useEffect(() => {
    axiosInstance.get('charts-options/' + sectorKey + '/balance/').then((res) => {
      if (res.data.results.length > 0) {
        setBalanceOptions(res.data.results);
      }
    });
  }, [sectorKey]);

  useEffect(() => {
    axiosInstance.get('charts-options/' + sectorKey + '/margin/').then((res) => {
      if (res.data.results.length > 0) {
        var newMarginOptionsNames = {};
        res.data.results.forEach(result => {
          newMarginOptionsNames[result.ref_key] = result.value;
        });

        setMarginOptions(res.data.results);
        setMarginOptionsNames(newMarginOptionsNames);
        setMarginOption(res.data.results[0].ref_key);
        setMarginOptionName(res.data.results[0].value);
      }
    });
  }, [sectorKey]);

  useEffect(() => {
    window.newGetChart('divChartContainerSensBars', 'sens_bars/' + sectorKey, sensChartsTicker, 'columnTwiceWithFilter', sensChartsFilter);
    window.newGetChart('divChartContainerSensLines', 'sens_lines/' + sectorKey, sensChartsTicker + '/Sector_basket', 'parabolicWithFilter', sensChartsFilter);
  }, [sectorKey, sensChartsTicker, sensChartsFilter]);

  useEffect(() => {
    if (tableData.companies.length > 0) {
      var filterComps = [];
      var comps = [];

      {tableData.companies.map(company => {
        if (!!company.multipliers.EV_EBITDA) {
          if (filterComps.length < 5)
            filterComps.push(company.key.replace('_', ' '));
          comps.push(company);
        }

      })}
      setSensChartsFilter(filterComps);
      setSensChartsCompanies(comps);
    }
  }, [tableData]);

  useEffect(() => {
    if (cashCostOption)
      if (sectorKey === 'Steel') {
        window.getChart('divChartContainerCashCost', sectorKey, cashCostOption, 'columnTwice');
      } else if (sectorKey === 'PGM') {
        window.getChart('divChartContainerCashCost', sectorKey, cashCostOption, 'columnLineExtra');
      } else {
        window.getChart('divChartContainerCashCost', sectorKey, cashCostOption, 'columnLine');
      }
  }, [sectorKey, cashCostOption]);

  useEffect(() => {
    if (balanceOptions.length > 0)
      if (sectorKey === 'Steel') {
        window.getChart('divChartContainerBalance', sectorKey, balanceOptions[0].ref_key, 'columnLineExtra');
      } else {
        window.getChart('divChartContainerBalance', sectorKey, balanceOptions[0].ref_key, 'columnTwice');
      }
  }, [sectorKey, balanceOptions]);

  useEffect(() => {
    if (marginOptions.length > 0)
      if (sectorKey === 'Steel') {
        window.getChart('divChartContainerMargin', sectorKey, marginOption + '_' + steelMarginHorizon, 'columnLineExtra');
      } else if (sectorKey === 'PGM') {
        window.getChart('divChartContainerMargin', sectorKey, marginOptions[0].ref_key, 'columnLineExtra');
      } else {
        window.getChart('divChartContainerMargin', sectorKey, marginOptions[0].ref_key, 'columnTwice');
      }
  }, [sectorKey, marginOptions, marginOption, steelMarginHorizon]);

  useEffect(() => {
    if (commodities.length > 0) {
      if (commodities.length === 1) {
        if (commPeriod === '5 years') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_1', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_2', 'area');
        } else if (commPeriod === '1 year') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_3', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_4', 'area');
        } else if (commPeriod === 'YTD') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_5', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_6', 'area');
        }
      } else {
        if (commPeriod === '5 years') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_' + commodities[0].key + '_5', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_range_' + commodities[0].key + '_5', 'area');
        } else if (commPeriod === '1 year') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_' + commodities[0].key + '_1', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_range_' + commodities[0].key + '_1', 'area');
        } else if (commPeriod === 'YTD') {
          window.getChart('divChartContainerComm', sectorKey, 'comm_' + commodities[0].key + '_0', 'linearLegend');
          window.getChart('divChartContainerCommRange', sectorKey, 'comm_range_' + commodities[0].key + '_0', 'area');
        }
      }
    }
  }, [sectorKey, commodities, commPeriod]);

  useEffect(() => {
    if (sectorKey === 'Copper' && demandIsOpen) {
      window.getChart('divChartContainerConsumptionByCountry', sectorKey, 'donut_2', 'donut');
      window.getChart('divChartContainerConsumptionByIndustry', sectorKey, 'donut_3', 'donut');
      window.getChart('divChartContainerConsumptionByIndustryChina', sectorKey, 'donut_4', 'donut');
    }
  }, [sectorKey, demandIsOpen]);

  useEffect(() => {
    if (sectorKey === 'Copper' && deeperDemandIsOpen) {
      window.getChart('divChartContainerRealEstate', sectorKey, 'spec_1', 'doubleLine');
      window.getChart('divChartContainerCopperImports', sectorKey, 'spec_2', 'multiArea');
      window.getChart('divChartContainerGridInvestments', sectorKey, 'range_CNLCIGRD', 'markers');
      window.getChart('divChartContainerHouseholdRefrigerators', sectorKey, 'range_CHMMREFC', 'markers');
      window.getChart('divChartContainerAirConditioners', sectorKey, 'range_CHMMAIRR', 'markers');
      window.getChart('divChartContainerPowerGeneratingEquipment', sectorKey, 'range_CHXCPWEQ', 'markers');
    }
  }, [sectorKey, deeperDemandIsOpen]);

  useEffect(() => {
    if (sectorKey === 'Copper' && supplyIsOpen) {
      window.getChart('divChartContainerMinedOutputByCountries', sectorKey, 'donut_0', 'donut');
      window.getChart('divChartContainerRefinedOutputByCountries', sectorKey, 'donut_1', 'donut');
      window.getChart('divChartContainerOutputByType', sectorKey, 'spec_7', 'proportion');
      window.getChart('divChartContainerMarketBalance', sectorKey, 'full_market', 'linearDbl');
      window.getChart('divChartContainerContract', sectorKey, 'spec_6', 'doubleLine');
      window.getChart('divChartContainerRefinedOutput', sectorKey, 'range_CHMMRCOC', 'markers');
    }
  }, [sectorKey, supplyIsOpen]);

  useEffect(() => {
    if (sectorKey === 'Copper' && supplyIsOpen) {
      window.getChart('divChartContainerSupplyOutput', sectorKey, supplyChartKey, 'markers');
    }
  }, [sectorKey, supplyIsOpen, supplyChartKey]);

  useEffect(() => {
    if (sectorKey === 'Copper' && balanceIsOpen) {
      window.getChart('divChartContainerGlobalBalance', sectorKey, 'price_market', 'colLine');
    }
  }, [sectorKey, balanceIsOpen]);

  useEffect(() => {
    if (sectorKey === 'Copper' && inventoriesIsOpen) {
      window.getChart('divChartContainerExchangeInventories', sectorKey, 'spec_3', 'multiArea');
      window.getChart('divChartContainerSpeculativePositioning', sectorKey, 'spec_4', 'linerLine');
      window.getChart('divChartContainerCurrencyRates', sectorKey, 'spec_5', 'tripleLinear');
    }
  }, [sectorKey, inventoriesIsOpen]);

  useEffect(() => {
    if (sectorKey === 'Copper') {
      newAxiosInstance.get('market-dummies/data/').then((res) => {
        var newMarketData = [];
        var newMarketDataGroup = {
          'units': res.data.market_dummies[0].units,
          'name': res.data.market_dummies[0].name,
          'values': [],
        };

        res.data.market_dummies.forEach(result => {
          if (result.name !== newMarketDataGroup.name) {
            newMarketData.push(newMarketDataGroup);
            newMarketDataGroup = {
              'units': result.units,
              'name': result.name,
              'values': [],
            };
          }
          if (result.units !== '') {
            newMarketDataGroup.values.push(result.value);
          }
        });
        newMarketData.push(newMarketDataGroup);

        setPeriodsData(res.data.periods);
        setMarketData(newMarketData);
      });
    }
  }, [sectorKey]);

  function handleSensChartsCompClick(e) {
    e.preventDefault();

    setSensChartsFilter(prevState => {
        if (prevState.includes(e.target.getAttribute('data-value'))) {
          return prevState.filter(function(value){
            return value !== e.target.getAttribute('data-value');
          });
        } else {
          return [ ...prevState, e.target.getAttribute('data-value') ];
        }
      });
  }

  return (
    <div className="app page-copper">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__description">
              <div className="breadcrumbs">
                <ul>
                  <li><a href="/">Main</a></li>
                  <li><a href={"/equities/" + sectorKey}>Equities</a></li>
                  <li><span aria-current="page">{sectorData.name}</span></li>
                </ul>
              </div>
              <h1>{sectorData.name}</h1>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="copper company">
              <LastNews newsType={'sector'} objectKey={sectorKey} />
              <section className="section">
                <div className="copper__head justify-content-between hidden-mobile">
                  <div className="copper__head-data">
                    <ul>
                      <li className="d-flex align-items-center">
                        <div className="custom-select__name">Scenarios:</div>
                        <div className="custom-select__name-1"></div>
                        <div className="custom-select">
                          <div className="custom-select__inner">
                            <div className="custom-select__trigger">
                              {tabScenarioName}
                              {tabScenarioName === 'SPOT' &&
                                <span>&nbsp;{formatUTCDatetime(spotDate, true, true, false)}</span>
                              }
                              <i className="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                                    fill="#0A2896"/>
                                </svg>
                              </i>
                            </div>
                            <div className="custom-select__options position-right">
                              <div className="custom-select__options-inner scrollable">
                                <ul className="custom-select__options-list">
                                  {scenarios.map(scenario => {
                                    return <ListItem key={scenario.key} withLink={false} linkClass={null} isActive={tabScenario === scenario.key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={scenario.key} instanceName={scenario.name === 'SPOT' ? scenario.name + ' ' + formatUTCDatetime(spotDate, true, true, false): scenario.name} clickArgs={[true, setTabScenario, scenariosNames, setTabScenarioName]} />
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="d-flex align-items-center">
                        <div className="custom-select__name">{commName + ':'}</div>
                        <div>{formatPrice(commPrice)}</div>
                      </li>
                      <li className="d-flex align-items-center">
                        <div className="custom-select__name">Calendar:</div>
                        <div>{calendarQ && calendarH &&
                              calendarQ + '/' + calendarH + ' EBITDA'}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="copper__table copper__table-1 hidden-mobile">
                  <div className="table__container">
                    <div className="table__scrollable">
                      <Table data={tableData} />
                    </div>
                  </div>
                </div>
                <div className="section__head hidden-desktop">
                  <h2 className="section__title">Table headline</h2>
                  <div className="d-flex align-items-center">
                    <div className="dropdown hidden-desktop">
                      <div className="dropdown__button js-dropdown-button">
                        <i className="icon">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M7.48641 10.8333C7.48641 10.8333 4.97671 7.08334 3.48135 4.67501C3.12157 4.09826 3.44797 3.33334 4.14046 3.33334H15.8569C16.5494 3.33334 16.8826 4.0838 16.516 4.67501C15.0226 7.08334 12.4924 10.8333 12.4924 10.8333V15.8333C12.4924 16.2917 12.1169 16.6667 11.658 16.6667H8.32073C7.86185 16.6667 7.48641 16.2917 7.48641 15.8333V10.8333ZM9.16683 10.4167L5.62516 5.00001H14.4074L10.8335 10.4167V15L9.16683 15V10.4167Z"
                                  fill="#606980"/>
                          </svg>
                        </i>
                      </div>
                      <div className="dropdown__selection dropdown__menu dropdown__menu-white dropdown__menu-fixed">
                        <div className="dropdown__slide">
                          <div className="dropdown__hide">
                            <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.5"
                                    d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                    fill="#D4D7DF"/>
                            </svg>
                          </div>
                          <div className="dropdown__menu-title">Feed Settings</div>
                          <div className="dropdown__slide-content scrollable">
                            <ul>
                              {scenarios.map((scenario, index) => {
                                return <CheckBox key={scenario.key} withListItemWrap={true} id={'chkbx_' + (index + 1) + (index + 1)  + (index + 1)} value={index+1} label={scenario.name === 'SPOT' ? scenario.name + ' ' + formatUTCDatetime(spotDate, true, true, false): scenario.name} isActive={tabScenario === scenario.key} instanceKey={scenario.key} clickArgs={[true, setTabScenario, scenariosNames, setTabScenarioName]} />
                              })}
                            </ul>
                          </div>
                          <div className="button__wrapper">
                            <button type="button"
                                    className="button button__blue hidden-desktop">Apply
                            </button>
                          </div>
                        </div>
                        <div className="dropdown__overlay"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <MobileTable data={tableData} />
                <div className="row">
                  <div className="col col-50">
                    <div className="card card-white">
                      <div className="tabs">
                        <div className="card__head" style={{ marginBottom: '0px' }}>
                          <div className="card__head-name">
                            <ul className="tabs__caption" style={{ marginBottom: '10px' }}>
                              <li className="bonds__tab active">Sensitivity Bars</li>
                              <li className="bonds__tab">Sensitivity Lines</li>
                            </ul>
                          </div>
                          <ul className="tags">
                            <ListItem withLink={true} linkClass={null} isActive={sensChartsTicker === 'EV_EBITDA'} activeClass={'state-selected'} baseClass={null} instanceKey={'EV_EBITDA'} instanceName={'EV/EBITDA'} clickArgs={[true, setSensChartsTicker, null, null]} />
                            <ListItem withLink={true} linkClass={null} isActive={sensChartsTicker === 'FCF_yield'} activeClass={'state-selected'} baseClass={null} instanceKey={'FCF_yield'} instanceName={'FCF yield'} clickArgs={[true, setSensChartsTicker, null, null]} />
                            <ListItem withLink={true} linkClass={null} isActive={sensChartsTicker === 'IS0006'} activeClass={'state-selected'} baseClass={null} instanceKey={'IS0006'} instanceName={'EBITDA'} clickArgs={[true, setSensChartsTicker, null, null]} />
                          </ul>
                        </div>
                        <SensChartsCompaniesList companies={sensChartsCompanies} sensChartsFilter={sensChartsFilter} handleSensChartsCompClick={handleSensChartsCompClick} />
                        <div className="tabs__content active">
                          <div className="chart-wrapper">
                            <div className="custom-scrollbar custom-chart">
                              <div id="divChartContainerSensBars" className="chart columnTwice2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="tabs__content">
                          <div className="chart-wrapper">
                            <div className="custom-scrollbar custom-chart">
                              <div id="divChartContainerSensLines" className="chart columnTwice2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-50">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                            <span>
                              {cashCostOptions.length > 0 && cashCostOptions[0].name}
                            </span>
                          <div className="dropdown dropdown-hint">
                            <div className="dropdown__button js-dropdown-button-hint">
                              <i className="icon icon__help">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="8" cy="8" r="6.5" stroke="#606980"/>
                                  <path
                                    d="M7.34737 9.38028C7.34737 9.12394 7.38596 8.90376 7.46316 8.71972C7.54035 8.52911 7.63509 8.35493 7.74737 8.19718C7.85965 8.03944 7.98246 7.89484 8.11579 7.76338C8.24912 7.62535 8.37193 7.48732 8.48421 7.3493C8.59649 7.20469 8.69123 7.05352 8.76842 6.89577C8.84561 6.73146 8.88421 6.54413 8.88421 6.3338C8.88421 6.02488 8.77895 5.78169 8.56842 5.60423C8.36491 5.42676 8.04912 5.33803 7.62105 5.33803C7.58596 5.33803 7.51228 5.34131 7.4 5.34789C7.29474 5.34789 7.16491 5.36103 7.01053 5.38732C6.86316 5.40704 6.70175 5.44648 6.52632 5.50563C6.35088 5.55822 6.17544 5.6338 6 5.73239V4.82535C6.32281 4.6939 6.63158 4.60845 6.92632 4.56901C7.22807 4.523 7.4807 4.5 7.68421 4.5C8.05614 4.5 8.38596 4.54601 8.67368 4.63803C8.9614 4.72347 9.20351 4.84507 9.4 5.00282C9.59649 5.16056 9.74386 5.34789 9.84211 5.56479C9.94737 5.77512 10 6.00516 10 6.25493C10 6.49155 9.95789 6.70845 9.87368 6.90563C9.79649 7.09624 9.69474 7.27371 9.56842 7.43803C9.44211 7.60235 9.30877 7.76009 9.16842 7.91127C9.02807 8.05587 8.89474 8.20704 8.76842 8.36479C8.64211 8.51596 8.53684 8.68028 8.45263 8.85775C8.37544 9.02864 8.33684 9.21925 8.33684 9.42958V9.57746H7.34737V9.38028ZM7.25263 10.3958H8.43158V11.5H7.25263V10.3958Z"
                                    fill="#606980"/>
                                </svg>
                              </i>
                            </div>
                            <div className="dropdown__menu dropdown__menu-dark dropdown--bottom-right dropdown__menu-fixed">
                              <div className="dropdown__slide">
                                <div className="dropdown__hide">
                                  <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5"
                                          d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                          fill="#D4D7DF"/>
                                  </svg>
                                </div>
                                <div className="dropdown__slide-content scrollable">
                                  <h2 className="hidden-desktop">{cashCostOptions.length > 0 && cashCostOptions[0].name}</h2>
                                  <p>
                                    {Object.keys(tooltips).length > 0 && cashCostOptions.length > 0 && tooltips[cashCostOptions[0].ref_key]}
                                  </p>
                                </div>
                              </div>
                              <div className="dropdown__overlay"></div>
                            </div>
                          </div>
                        </div>
                        <div className="card__custom-select custom-select">
                          <div className="custom-select__inner">
                            <div className="custom-select__trigger"><span>{cashCostOptionName}</span>
                              <i className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                                    fill="#0A2896"/>
                                </svg>
                              </i>
                            </div>
                            <div className="custom-select__options position-right">
                              <div className="custom-select__options-inner scrollable">
                                <ul className="custom-select__options-list">
                                  {cashCostOptions.map(option => {
                                    return <ListItem key={option.ref_key} withLink={false} linkClass={null} isActive={cashCostOption === option.ref_key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={option.ref_key} instanceName={option.value} clickArgs={[true, setCashCostOption, cashCostOptionsNames, setCashCostOptionName]} />
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card__data-value hidden-desktop">
                          <div className="card__data-value-title">GLEN LN cash cost</div>
                          <ul>
                            <li>USD: 3,643</li>
                            <li>Percents: 71.99%</li>
                          </ul>
                        </div>
                      </div>
                      <div className="chart-wrapper">
                        <div className="custom-scrollbar custom-chart">
                          <div id="divChartContainerCashCost" className="chart columnTwice2"></div>
                        </div>
                        <div className="icon__fullsize js-fullsize-button">
                          <i className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.6166 9.99029V4.58092C15.6166 4.1213 15.2395 3.74418 14.7857 3.75007L9.37636 3.75007C8.91674 3.75007 8.54551 4.1213 8.54551 4.58092C8.54551 5.04054 8.91674 5.41177 9.37636 5.41177L13.949 5.41177L13.9549 9.99029C13.9549 10.4499 14.3261 10.8211 14.7857 10.8211C15.2453 10.8211 15.6166 10.4499 15.6166 9.99029ZM9.9898 15.6161H4.58043C4.12081 15.6161 3.74369 15.239 3.74958 14.7852L3.74958 9.37587C3.74958 8.91625 4.12081 8.54502 4.58043 8.54502C5.04005 8.54502 5.41128 8.91625 5.41128 9.37587L5.41128 13.9485L9.9898 13.9544C10.4494 13.9544 10.8206 14.3256 10.8206 14.7852C10.8206 15.2449 10.4494 15.6161 9.9898 15.6161Z"
                                    fill="#606980"></path>
                            </svg>
                          </i>
                        </div>
                        <div className="icon__smallsize js-smallsize-button">
                          <i className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M10.206 2.83084V8.24021C10.206 8.69983 10.5831 9.07695 11.0369 9.07106H16.4462C16.9059 9.07106 17.2771 8.69982 17.2771 8.24021C17.2771 7.78059 16.9059 7.40935 16.4462 7.40935L11.8736 7.40936L11.8677 2.83084C11.8677 2.37122 11.4965 1.99999 11.0369 1.99999C10.5772 1.99999 10.206 2.37122 10.206 2.83084ZM2.83085 10.2041H8.24022C8.69984 10.2041 9.07696 10.5812 9.07107 11.0349V16.4443C9.07107 16.9039 8.69984 17.2751 8.24022 17.2751C7.7806 17.2751 7.40937 16.9039 7.40937 16.4443L7.40937 11.8717L2.83085 11.8658C2.37123 11.8658 2 11.4945 2 11.0349C2 10.5753 2.37123 10.2041 2.83085 10.2041Z"
                                    fill="#606980"/>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {sectorKey !== 'Iron_ore' &&
                    <React.Fragment>
                      <div className="col col-50">
                        <div className="card card-white">
                          <div className="card__head">
                            <div className="card__head-name">
                                <span>
                                  {balanceOptions.length > 0 && balanceOptions[0].name}
                                </span>
                              <div className="dropdown dropdown-hint">
                                <div className="dropdown__button js-dropdown-button-hint">
                                  <i className="icon icon__help">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="8" cy="8" r="6.5" stroke="#606980"/>
                                      <path
                                        d="M7.34737 9.38028C7.34737 9.12394 7.38596 8.90376 7.46316 8.71972C7.54035 8.52911 7.63509 8.35493 7.74737 8.19718C7.85965 8.03944 7.98246 7.89484 8.11579 7.76338C8.24912 7.62535 8.37193 7.48732 8.48421 7.3493C8.59649 7.20469 8.69123 7.05352 8.76842 6.89577C8.84561 6.73146 8.88421 6.54413 8.88421 6.3338C8.88421 6.02488 8.77895 5.78169 8.56842 5.60423C8.36491 5.42676 8.04912 5.33803 7.62105 5.33803C7.58596 5.33803 7.51228 5.34131 7.4 5.34789C7.29474 5.34789 7.16491 5.36103 7.01053 5.38732C6.86316 5.40704 6.70175 5.44648 6.52632 5.50563C6.35088 5.55822 6.17544 5.6338 6 5.73239V4.82535C6.32281 4.6939 6.63158 4.60845 6.92632 4.56901C7.22807 4.523 7.4807 4.5 7.68421 4.5C8.05614 4.5 8.38596 4.54601 8.67368 4.63803C8.9614 4.72347 9.20351 4.84507 9.4 5.00282C9.59649 5.16056 9.74386 5.34789 9.84211 5.56479C9.94737 5.77512 10 6.00516 10 6.25493C10 6.49155 9.95789 6.70845 9.87368 6.90563C9.79649 7.09624 9.69474 7.27371 9.56842 7.43803C9.44211 7.60235 9.30877 7.76009 9.16842 7.91127C9.02807 8.05587 8.89474 8.20704 8.76842 8.36479C8.64211 8.51596 8.53684 8.68028 8.45263 8.85775C8.37544 9.02864 8.33684 9.21925 8.33684 9.42958V9.57746H7.34737V9.38028ZM7.25263 10.3958H8.43158V11.5H7.25263V10.3958Z"
                                        fill="#606980"/>
                                    </svg>
                                  </i>
                                </div>
                                <div className="dropdown__menu dropdown__menu-dark dropdown--bottom-right dropdown__menu-fixed">
                                  <div className="dropdown__slide">
                                    <div className="dropdown__hide">
                                      <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.5"
                                              d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                              fill="#D4D7DF"/>
                                      </svg>
                                    </div>
                                    <div className="dropdown__slide-content scrollable">
                                      <h2 className="hidden-desktop">{balanceOptions.length > 0 && balanceOptions[0].name}</h2>
                                      <p>
                                        {Object.keys(tooltips).length > 0 && balanceOptions.length > 0 && tooltips[balanceOptions[0].ref_key]}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="dropdown__overlay"></div>
                                </div>
                              </div>
                            </div>
                            <div className="card__data-value hidden-desktop">
                              <div className="card__data-value-title">2019</div>
                              <ul>
                                <li>Copper price: 3,643</li>
                              </ul>
                            </div>
                          </div>
                          <div id="divChartContainerBalance" className="chart columnTwice"></div>
                        </div>
                      </div>
                      <div className="col col-50">
                        <div className="card card-white">
                          <div className="card__head">
                            <div className="card__head-name">
                              <span>
                                {marginOptions.length > 0 && marginOptions[0].name}
                              </span>
                              <div className="dropdown dropdown-hint">
                                <div className="dropdown__button js-dropdown-button-hint">
                                  <i className="icon icon__help">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="8" cy="8" r="6.5" stroke="#606980"/>
                                      <path
                                        d="M7.34737 9.38028C7.34737 9.12394 7.38596 8.90376 7.46316 8.71972C7.54035 8.52911 7.63509 8.35493 7.74737 8.19718C7.85965 8.03944 7.98246 7.89484 8.11579 7.76338C8.24912 7.62535 8.37193 7.48732 8.48421 7.3493C8.59649 7.20469 8.69123 7.05352 8.76842 6.89577C8.84561 6.73146 8.88421 6.54413 8.88421 6.3338C8.88421 6.02488 8.77895 5.78169 8.56842 5.60423C8.36491 5.42676 8.04912 5.33803 7.62105 5.33803C7.58596 5.33803 7.51228 5.34131 7.4 5.34789C7.29474 5.34789 7.16491 5.36103 7.01053 5.38732C6.86316 5.40704 6.70175 5.44648 6.52632 5.50563C6.35088 5.55822 6.17544 5.6338 6 5.73239V4.82535C6.32281 4.6939 6.63158 4.60845 6.92632 4.56901C7.22807 4.523 7.4807 4.5 7.68421 4.5C8.05614 4.5 8.38596 4.54601 8.67368 4.63803C8.9614 4.72347 9.20351 4.84507 9.4 5.00282C9.59649 5.16056 9.74386 5.34789 9.84211 5.56479C9.94737 5.77512 10 6.00516 10 6.25493C10 6.49155 9.95789 6.70845 9.87368 6.90563C9.79649 7.09624 9.69474 7.27371 9.56842 7.43803C9.44211 7.60235 9.30877 7.76009 9.16842 7.91127C9.02807 8.05587 8.89474 8.20704 8.76842 8.36479C8.64211 8.51596 8.53684 8.68028 8.45263 8.85775C8.37544 9.02864 8.33684 9.21925 8.33684 9.42958V9.57746H7.34737V9.38028ZM7.25263 10.3958H8.43158V11.5H7.25263V10.3958Z"
                                        fill="#606980"/>
                                    </svg>
                                  </i>
                                </div>
                                <div className="dropdown__menu dropdown__menu-dark dropdown--bottom-right dropdown__menu-fixed">
                                  <div className="dropdown__slide">
                                    <div className="dropdown__hide">
                                      <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.5"
                                              d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                              fill="#D4D7DF"/>
                                      </svg>
                                    </div>
                                    <div className="dropdown__slide-content scrollable">
                                      <h2 className="hidden-desktop">{marginOptions.length > 0 && marginOptions[0].name}</h2>
                                      <p>
                                        {Object.keys(tooltips).length > 0 && marginOptions.length > 0 && tooltips[marginOptions[0].ref_key]}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="dropdown__overlay"></div>
                                </div>
                              </div>
                            </div>
                            { sectorKey == 'Steel' &&
                              <div>
                                <div className="card__custom-select custom-select">
                                  <div className="custom-select__inner">
                                    <div className="custom-select__trigger"><span>{marginOptionName}</span>
                                      <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                                            fill="#0A2896"/>
                                        </svg>
                                      </i>
                                    </div>
                                    <div className="custom-select__options position-right">
                                      <div className="custom-select__options-inner scrollable">
                                        <ul className="custom-select__options-list">
                                          {marginOptions.map(option => {
                                            return <ListItem key={option.ref_key} withLink={false} linkClass={null} isActive={marginOption === option.ref_key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={option.ref_key} instanceName={option.value} clickArgs={[true, setMarginOption, marginOptionsNames, setMarginOptionName]} />
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card__custom-select custom-select">
                                  <div className="custom-select__inner">
                                    <div className="custom-select__trigger"><span>{steelMarginHorizonName}</span>
                                      <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                                            fill="#0A2896"/>
                                        </svg>
                                      </i>
                                    </div>
                                    <div className="custom-select__options position-right">
                                      <div className="custom-select__options-inner scrollable">
                                        <ul className="custom-select__options-list">
                                          <ListItem withLink={false} linkClass={null} isActive={steelMarginHorizon === 'long'} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={'long'} instanceName={'Long-term'} clickArgs={[true, setSteelMarginHorizon, steelMarginHorizonNames, setSteelMarginHorizonName]} />
                                          <ListItem withLink={false} linkClass={null} isActive={steelMarginHorizon === 'mid'} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={'mid'} instanceName={'Medium-term'} clickArgs={[true, setSteelMarginHorizon, steelMarginHorizonNames, setSteelMarginHorizonName]} />
                                          <ListItem withLink={false} linkClass={null} isActive={steelMarginHorizon === 'short'} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={'short'} instanceName={'Short-term'} clickArgs={[true, setSteelMarginHorizon, steelMarginHorizonNames, setSteelMarginHorizonName]} />
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                          <div id="divChartContainerMargin" className="chart columnTwice"></div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  <div className="col col-100">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                            <span>
                                {sectorData.name} price performance
                            </span>
                        </div>
                        <ul className="tags">
                          <ListItem withLink={true} linkClass={null} isActive={commPeriod === '5 years'} activeClass={'state-selected'} baseClass={null} instanceKey={'5 years'} instanceName={'5 years'} clickArgs={[true, setCommPeriod, null, null]} />
                          <ListItem withLink={true} linkClass={null} isActive={commPeriod === '1 year'} activeClass={'state-selected'} baseClass={null} instanceKey={'1 year'} instanceName={'1 year'} clickArgs={[true, setCommPeriod, null, null]} />
                          <ListItem withLink={true} linkClass={null} isActive={commPeriod === 'YTD'} activeClass={'state-selected'} baseClass={null} instanceKey={'YTD'} instanceName={'YTD'} clickArgs={[true, setCommPeriod, null, null]} />
                        </ul>
                        <div className="card__data-value hidden-desktop">
                          <div className="card__data-value-title">21-Feb-2020</div>
                          <ul>
                            <li>Price: 5,500</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col col-60">
                          <div id="divChartContainerComm" className="linelegend"></div>
                        </div>
                        <div className="col col-40">
                          <div id="divChartContainerCommRange" className="area"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              { sectorKey === 'Copper' &&
                <React.Fragment>
                  <h2 className="copper__title">Copper overview</h2>
                  <div className="accordion">
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link" onClick={(e) => handleSectionClick(e, setDemandIsOpen)}>
                        <h3>Demand drivers</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="row">
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Refined copper consumption by country
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerConsumptionByCountry" className="donut"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Refined copper consumption by industry
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerConsumptionByIndustry" className="donut"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Refined copper consumption by industry in China
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerConsumptionByIndustryChina" className="donut"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link" onClick={(e) => handleSectionClick(e, setDeeperDemandIsOpen)}>
                        <h3>Deeper into Chinese demand</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="row">
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China real estate statistics
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Starts: 11.23%</li>
                                      <li>Percents: -3.34%</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerRealEstate" className="double-line"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China copper imports
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Scrap: 112</li>
                                      <li>Concentrate: 156</li>
                                      <li>YoY: -3.34%</li>
                                      <li>Unwrought copper and copper products: 418</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerCopperImports" className="multiarea"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China grid investments
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerGridInvestments" className="marker"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China household refrigerators output
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerHouseholdRefrigerators" className="marker"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China air conditioners output
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerAirConditioners" className="marker"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China power generating equipment output
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerPowerGeneratingEquipment" className="marker"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link" onClick={(e) => handleSectionClick(e, setSupplyIsOpen)}>
                        <h3>Supply side</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="row">
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Mined copper output by countries
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerMinedOutputByCountries" className="donut"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Refined copper output by countries
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerRefinedOutputByCountries" className="donut"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-33">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Refined copper output by type/sourse
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerOutputByType" className="proportion"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Copper concentrate market balance
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerMarketBalance" className="dbllinear"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Copper TC/RC - spot and annual contract
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerContract" className="double-line"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                China refined output
                                            </span>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerRefinedOutput" className="marker"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Copper output
                                            </span>
                                  </div>
                                  <div className="card__custom-select custom-select">
                                    <div className="custom-select__inner">
                                      <div className="custom-select__trigger"><span>Chile</span>
                                        <i className="icon">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651V9.26651Z"
                                              fill="#606980"/>
                                          </svg>
                                        </i>
                                      </div>
                                      <div className="custom-select__options position-right">
                                        <div className="custom-select__options-inner scrollable">
                                          <ul className="custom-select__options-list">
                                            <li className="custom-select__option state-selected" data-value="range_CLPCTOTL" onClick={(e) => handleClick(e, false, setSupplyChartKey, null, null)}>Chile</li>
                                            <li className="custom-select__option" data-value="range_PEMTCOPP" onClick={(e) => handleClick(e, false, setSupplyChartKey, null, null)}>Peru</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card__data-value hidden-desktop">
                                    <div className="card__data-value-title">2014 Jun 30</div>
                                    <ul>
                                      <li>Range: 17</li>
                                      <li>Mean: 48</li>
                                      <li>2021: 18</li>
                                      <li>2020: 32</li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerSupplyOutput" className="marker"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link" onClick={(e) => handleSectionClick(e, setBalanceIsOpen)}>
                        <h3>Balance</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="row">
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Global copper balance
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerGlobalBalance" className="dbllinear"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link" onClick={(e) => handleSectionClick(e, setInventoriesIsOpen)}>
                        <h3>Inventories, costs and other</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="row">
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Global copper exchange inventories
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerExchangeInventories" className="multiarea"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Speculative positioning in copper futures market
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerSpeculativePositioning" className="dbllinear"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-50">
                              <div className="card card-white">
                                <div className="card__head">
                                  <div className="card__head-name">
                                            <span>
                                                Currency rates of copper-producing countries
                                            </span>
                                  </div>
                                </div>
                                <div>
                                  <div id="divChartContainerCurrencyRates" className="dbllinear"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section js-collapse">
                      <div className="section__title js-collapse-link">
                        <h3>Summary</h3>
                        <i className="icon icon__collapse js-collapse-icon hidden-mobile">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651Z"
                              fill="#606980"/>
                          </svg>
                        </i>
                        <span className="icon__plus hidden-desktop"></span>
                      </div>
                      <div className="js-collapse-content">
                        <div className="section__content">
                          <div className="copper__table copper__table-2">
                            <div className="table__container">
                              <div className="table__scrollable">
                                <ExtraTable periodsData={periodsData} marketData={marketData} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </React.Fragment>
              }
              <NewsPager newsType={'sector'} objectKey={sectorKey} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Sector;
