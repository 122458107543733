function ExtraTableRow(props) {
  if (props.data.units === '') {
    return (
      <tr className="table__name">
        <td className="sticky-col">{props.data.name}</td>
        <td colSpan="11"></td>
      </tr>
    );
  } else {
    if (props.data.name === 'Total World Demand' || props.data.name === 'Potential production' || props.data.name === 'Total World Production') {
      return (
        <tr>
          <td className="sticky-col"><strong>{props.data.name}</strong></td>
          <td><strong>{props.data.units}</strong></td>
          {props.data.values.map((value, index) => {
            return <td key={index}><strong>{value}</strong></td>
          })}
          {(() => {
            const arr = [];
            for (var i=props.data.values.length + 1; i < 10; i++) {
              arr.push(<td key={i}><strong></strong></td>);
            }
            return arr;
          })()}
        </tr>
      );
    } else {
      return (
        <tr>
          <td className="sticky-col">{props.data.name}</td>
          <td>{props.data.units}</td>
          {props.data.values.map((value, index) => {
            return <td key={index}>{value}</td>
          })}
          {(() => {
            const arr = [];
            for (var i=props.data.values.length + 1; i < 10; i++) {
              arr.push(<td key={i}></td>);
            }
            return arr;
          })()}
        </tr>
      );
    }
  }
}

export default ExtraTableRow;