import React from 'react';

function NotFound() {
  return (
    <div className="app page-metals-mining-summary">
      <div className="wrapper">
        <main className="content">
          <div className="container">
            <div>
              <h1>404, page not found.</h1>
              <p>Looks like the page you were looking for is no longer here.</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default NotFound;