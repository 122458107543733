import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import newAxiosInstance from '../../axios/newInstance';
import { formatPrice, formatPercents, formatCompanyData } from '../../common/formatting';

import ListItem from '../../common/components/ListItem';
import CheckBox from '../../common/components/CheckBox';
import NewsItem from '../../common/components/NewsItem';

import Table from './Table';
import MobileTable from './MobileTable';
import LastNews from '../News/LastNews';
import NewsPager from '../News/NewsPager';

import CompanyChart from '../Chart/CompanyChart';
import Sens from './Sens';
import Rep from './Rep';
import TargetPrice from './TargetPrice';

function Company() {
  const { companyKey } = useParams();
  const [companyData, setCompanyData] = useState({
    'name': null,
    'sector_key': null,
    'sector_name': null,
    'mcap_usd': null,
    'model_currency': null,
    'mcap_model': null,
    'free_float': null,
    'adtv_6m': null,
    'share': {
      'name': null,
      'share_type': null,
      'price': null,
    },
    tickers: null,
    recs: [],
  });
  const [scenarios, setScenarios] = useState([{'key': null, 'name': null}]);
  const [scenariosNames, setScenariosNames] = useState({'S': 'SPOT'})

  const [finsScenario, setFinsScenario] = useState('S');
  const [finsScenarioName, setFinsScenarioName] = useState('SPOT');
  const [mobileTablePeriod, setMobileTablePeriod] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [perfPeriod, setPerfPeriod] = useState('2Y');
  const [waterPeriod, setWaterPeriod] = useState('D');

  const [drivers, setDrivers] = useState([{'key': null, 'name': null}]);
  const [driversNames, setDriversNames] = useState({null: null})
  const [driver, setDriver] = useState(null);
  const [driverName, setDriverName] = useState(null);

  const [NTMScenario, setNTMScenario] = useState('S');
  const [NTMScenarioName, setNTMScenarioName] = useState('SPOT');

  useEffect(() => {
    newAxiosInstance.get('company/' + companyKey).then((res) => {
      setCompanyData(res.data);
    });
  }, [companyKey]);

  useEffect(() => {
    newAxiosInstance.get('option/scenario').then((res) => {
      var newScenarios = [];
      var newScenariosNames = {};

      res.data.forEach(result => {
        if (!result.key.includes('MW') && !result.key.includes('Sn')) {
          newScenarios.push(result)
          newScenariosNames[result.key] = result.name;
        }
      });

      setScenarios(newScenarios);
      setScenariosNames(newScenariosNames);
    });
  }, []);
  
  useEffect(() => {
    newAxiosInstance.get('table/fins/' + companyKey + '/' + finsScenario).then((res) => {
      var newTableData = [];
      var prevGroup = res.data[0].group;
      var newGroup = {
        'key': prevGroup,
        'results': []
      };

      res.data.forEach(result => {
        if (result.group !== prevGroup) {
            if (newGroup.results.length > 0)
              newTableData.push(newGroup);

            newGroup = {
              'key': result.group,
              'results': []
            };
        }

        if (Object.keys(result.values).length > 0)
          newGroup.results.push(result);

        prevGroup = result.group;
      });

      if (newGroup.results.length > 0)
        newTableData.push(newGroup);

      setTableData(newTableData);
      setMobileTablePeriod(prevState => {
        if (prevState) {
          return prevState;
        }
        else {
          return newTableData[0].results[0].periods[0];
        }
      });
    });
  }, [companyKey, finsScenario]);

  useEffect(() => {
    newAxiosInstance.get('option/driver/' + companyKey).then((res) => {
      var newDriversNames = {};

      res.data.forEach(result => {
        newDriversNames[result.ref_ticker] = result.short_name;
      });

      setDrivers(res.data);
      setDriversNames(newDriversNames);

      if (res.data.length > 0) {
        setDriver(prevState => {
          if (prevState) {
            return prevState;
          }
          else {
            if (res.data.length > 1)
              return res.data[1].ref_ticker;
            return prevState;
          }
        });
        setDriverName(prevState => {
          if (prevState) {
            return prevState;
          }
          else {
            if (res.data.length > 1)
              return res.data[1].short_name;
            return prevState;
          }
        });
      }
    });
  }, [companyKey]);

  useEffect(() => {
    window.newGetChart('divChartContainerPieRevenue', companyKey, 'pie_revenue', 'donut');
    window.newGetChart('divChartContainerPieCashCosts', companyKey, 'pie_cash_costs', 'donut');
    window.newGetChart('divChartContainerGrowth', companyKey, 'growth', 'rain');
    window.newGetChart('divChartContainerBoxIS0006', companyKey, 'box/IS0006', 'row');
    window.newGetChart('divChartContainerBoxEVEBITDA', companyKey, 'box/EV_EBITDA', 'row');
    window.newGetChart('divChartContainerBoxPE', companyKey, 'box/P_E', 'row');
    window.newGetChart('divChartContainerBoxDivYield', companyKey, 'box/Div_yield', 'row');
    window.newGetChart('divChartContainerBoxFCFYield', companyKey, 'box/FCF_yield', 'row');
  }, [companyKey]);

  useEffect(() => {
    var scenarioParam = NTMScenario.replace('S_', '');
    scenarioParam = scenarioParam.charAt(0) + scenarioParam.substring(1).toLowerCase();
    window.newGetChart('divChartContainerExtraLeft', companyKey, 'extra_left/' + scenarioParam, 'lineSq');
    window.newGetChart('divChartContainerExtraCenter', companyKey, 'extra_center/' + scenarioParam, 'sky');
    window.newGetChart('divChartContainerExtraRight', companyKey, 'extra_right/' + scenarioParam, 'lineSq');
  }, [companyKey, NTMScenario]);

  useEffect(() => {
    window.newGetChart('divChartContainerEVEBITDAPerf', companyKey, 'ev_ebitda_perf/' + perfPeriod, 'historical');
  }, [companyKey, perfPeriod]);

  useEffect(() => {
    window.newGetChart('divChartContainerWaterfall', companyKey, 'waterfall/' + waterPeriod, 'skyWidth');
  }, [companyKey, waterPeriod]);

  useEffect(() => {
    if (driver)
      window.newGetChart('divChartContainerRevenueDiff', 'comm', 'driver_hist/' + driver, 'singleAnother');
  }, [driver]);

  return (
    <div className="app page-company">
      <div className="wrapper">
        <div className="hero">
          <div className="container">
            <div className="hero__description">
              <div className="hero__company">
                <div>
                  <div className="breadcrumbs">
                    <ul>
                      <li><a href="/">Main</a></li>
                      { companyData.sector_key &&
                        <React.Fragment>
                          <li><a href={"/equities/" + companyData.sector_key}>Equities</a></li>
                          <li><a href={"/sector/" + companyData.sector_key}>{companyData.sector_name}</a></li>
                        </React.Fragment>
                      }
                      <li><span aria-current="page">{companyData.name}</span></li>
                    </ul>
                  </div>
                  <h1>{companyData.name} ({companyData.share.name})</h1>
                  {companyData.recs.map(quote => {
                    return <TargetPrice key={quote.name} data={quote} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="content">
          <div className="container">
            <div className="company">
              <LastNews newsType={'company'} objectKey={companyKey} />
              <section className="section">
                <div className="company__headline">
                  <div className="company__headline-item">
                    <div className="company__headline-title">{formatPrice(companyData.share.price)} {companyData.share.share_type}</div>
                    <a href="#">Share price</a>
                  </div>
                  <div className="company__headline-item">
                    <div className="company__headline-title">{companyData.tickers}</div>
                    <a href="#">Tickers</a>
                  </div>
                  <div className="company__headline-item">
                    <div className="company__headline-title">{companyData.model_currency} {formatCompanyData(companyData.mcap_model)}</div>
                    <a href="#">Market Cap</a>
                  </div>
                  <div className="company__headline-item">
                    <div className="company__headline-title">{companyData.free_float && formatPercents(companyData.free_float / 100, false)}{companyData.free_float && ', USD'} {companyData.free_float && formatCompanyData(companyData.free_float / 100 * companyData.mcap_usd)}</div>
                    <a href="#">Free float</a>
                  </div>
                  <div className="company__headline-item">
                    <div className="company__headline-title">{companyData.adtv_6m && 'USD'} {formatCompanyData(companyData.adtv_6m)}</div>
                    <a href="#">ADTV (6M)</a>
                  </div>
                </div>
                <div className="row">
                  {(drivers.length > 0 && drivers[0].name && companyData.share.name) && <CompanyChart companyKey={companyKey.replace('_', ' ')} isRussian={companyData.is_russian} symbol={companyData.share.name} sector={companyData.sector_name} currency={companyData.share.currency} drivers={drivers} relatedCompanies={companyData.related_companies} />}
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="tabs">
                        <ul className="tabs__caption">
                          <li className="bonds__tab active">Revenue split</li>
                          <li className="bonds__tab">Costs split by currency</li>
                        </ul>
                        <div className="tabs__content active">
                          <div id="divChartContainerPieRevenue" className="donut"></div>
                        </div>
                        <div className="tabs__content">
                          <div id="divChartContainerPieCashCosts" className="donut"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                          <span>
                            Commodity price performance
                          </span>
                        </div>
                        <div className="card__custom-select custom-select">
                          <div className="custom-select__inner">
                            <div className="custom-select__trigger"><span>{driverName}</span>
                              <i className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                                    fill="#0A2896"/>
                                </svg>
                              </i>
                            </div>
                            <div className="custom-select__options position-right">
                              <div className="custom-select__options-inner scrollable">
                                <ul className="custom-select__options-list">
                                  {drivers.map(driverData => {
                                    if (driverData.name !== 'Commodity basket')
                                      return <ListItem key={driverData.key} withLink={false} linkClass={null} isActive={driver === driverData.ref_ticker} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={driverData.ref_ticker} instanceName={driverData.name} clickArgs={[true, setDriver, driversNames, setDriverName]} />
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="chart-wrapper">
                        <div className="custom-scrollbar custom-chart">
                          <div id="divChartContainerRevenueDiff" className="single-line"></div>
                        </div>
                        <div className="icon__fullsize js-fullsize-button">
                          <i className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M15.6166 9.99029V4.58092C15.6166 4.1213 15.2395 3.74418 14.7857 3.75007L9.37636 3.75007C8.91674 3.75007 8.54551 4.1213 8.54551 4.58092C8.54551 5.04054 8.91674 5.41177 9.37636 5.41177L13.949 5.41177L13.9549 9.99029C13.9549 10.4499 14.3261 10.8211 14.7857 10.8211C15.2453 10.8211 15.6166 10.4499 15.6166 9.99029ZM9.9898 15.6161H4.58043C4.12081 15.6161 3.74369 15.239 3.74958 14.7852L3.74958 9.37587C3.74958 8.91625 4.12081 8.54502 4.58043 8.54502C5.04005 8.54502 5.41128 8.91625 5.41128 9.37587L5.41128 13.9485L9.9898 13.9544C10.4494 13.9544 10.8206 14.3256 10.8206 14.7852C10.8206 15.2449 10.4494 15.6161 9.9898 15.6161Z"
                                    fill="#606980"></path>
                            </svg>
                          </i>
                        </div>
                        <div className="icon__smallsize js-smallsize-button">
                          <i className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M10.206 2.83084V8.24021C10.206 8.69983 10.5831 9.07695 11.0369 9.07106H16.4462C16.9059 9.07106 17.2771 8.69982 17.2771 8.24021C17.2771 7.78059 16.9059 7.40935 16.4462 7.40935L11.8736 7.40936L11.8677 2.83084C11.8677 2.37122 11.4965 1.99999 11.0369 1.99999C10.5772 1.99999 10.206 2.37122 10.206 2.83084ZM2.83085 10.2041H8.24022C8.69984 10.2041 9.07696 10.5812 9.07107 11.0349V16.4443C9.07107 16.9039 8.69984 17.2751 8.24022 17.2751C7.7806 17.2751 7.40937 16.9039 7.40937 16.4443L7.40937 11.8717L2.83085 11.8658C2.37123 11.8658 2 11.4945 2 11.0349C2 10.5753 2.37123 10.2041 2.83085 10.2041Z"
                                    fill="#606980"/>
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                            <span>
                              Revenue growth at spot prices
                            </span>
                        </div>
                      </div>
                      <div>
                        <div id="divChartContainerGrowth" className="rain"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                          <span>
                            Earnings to shareholders ntm
                          </span>
                        </div>
                        <div className="card__custom-select custom-select">
                          <div className="custom-select__inner">
                            <div className="custom-select__trigger"><span>{NTMScenarioName}</span>
                              <i className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M12.8896 7.7221L9.99619 10.6682L7.10283 7.7221C6.812 7.42597 6.3422 7.42597 6.05137 7.7221C5.76055 8.01822 5.76055 8.49658 6.05137 8.79271L9.47419 12.2779C9.76502 12.574 10.2348 12.574 10.5256 12.2779L13.9485 8.79271C14.2393 8.49658 14.2393 8.01822 13.9485 7.7221C13.6576 7.43356 13.1804 7.42597 12.8896 7.7221Z"
                                    fill="#0A2896"/>
                                </svg>
                              </i>
                            </div>
                            <div className="custom-select__options position-right">
                              <div className="custom-select__options-inner scrollable">
                                <ul className="custom-select__options-list">
                                  {scenarios.map(scenario => {
                                    return <ListItem key={scenario.key} withLink={false} linkClass={null} isActive={NTMScenario === scenario.key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={scenario.key} instanceName={scenario.name} clickArgs={[true, setNTMScenario, scenariosNames, setNTMScenarioName]} />
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div id="divChartContainerExtraLeft" className="customizeLineSq"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                            <span>
                              Capital structure ntm
                            </span>
                        </div>
                      </div>
                      <div>
                        <div id="divChartContainerExtraCenter" className="sky"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-33">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                            <span>
                              Earnings to firm ntm
                            </span>
                        </div>
                      </div>
                      <div>
                        <div id="divChartContainerExtraRight" className="customizeLineSq"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-100 hidden-mobile">
                    <div className="chart-colors">
                      <ul>
                        <li><span className="chart-color" style={{backgroundColor: '#0056FF'}}></span>Multiples</li>
                        <li><span className="chart-color-circle" style={{backgroundColor: '#0056FF'}}></span>Yields</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col-100">
                    <div className="card card-white">
                      <div className="row">
                        <div className="col col-33 hidden-mobile">
                          <div className="card__head">
                            <div className="card__head-name">
                              <span>
                                Key performance and valuation metrics
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="row-list">
                              <div className="row-item">
                                <div className="row-name">EBITDA</div>
                                <div className="row-data">
                                  <div id="divChartContainerBoxIS0006"
                                       className="row-values"></div>
                                </div>
                              </div>
                              <div className="row-item">
                                <div className="row-name">EV/EBITDA</div>
                                <div className="row-data">
                                  <div id="divChartContainerBoxEVEBITDA"
                                       className="row-values"></div>
                                </div>
                              </div>
                              <div className="row-item">
                                <div className="row-name">P/E</div>
                                <div className="row-data">
                                  <div id="divChartContainerBoxPE"
                                       className="row-values"></div>
                                </div>
                              </div>
                              <div className="row-item">
                                <div className="row-name">Div yield</div>
                                <div className="row-data">
                                  <div id="divChartContainerBoxDivYield"
                                       className="row-values"></div>
                                </div>
                              </div>
                              <div className="row-item">
                                <div className="row-name">FCF yield</div>
                                <div className="row-data">
                                  <div id="divChartContainerBoxFCFYield"
                                       className="row-values"></div>
                                </div>
                              </div>
                            </div>
                            <div style={{height: '100px', width: '324px'}}>
                              <div id="row-legend" style={{height: '100px'}}></div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-66">
                          <div className="card__head">
                            <div className="card__head-name">
                              <span>
                                Historical ev/ebitda performance
                              </span>
                            </div>
                            <ul className="tags">
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '3M'} activeClass={'state-selected'} baseClass={null} instanceKey={'3M'} instanceName={'3 months'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '6M'} activeClass={'state-selected'} baseClass={null} instanceKey={'6M'} instanceName={'6 months'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '1Y'} activeClass={'state-selected'} baseClass={null} instanceKey={'1Y'} instanceName={'1 year'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '2Y'} activeClass={'state-selected'} baseClass={null} instanceKey={'2Y'} instanceName={'2 years'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '3Y'} activeClass={'state-selected'} baseClass={null} instanceKey={'3Y'} instanceName={'3 years'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '4Y'} activeClass={'state-selected'} baseClass={null} instanceKey={'4Y'} instanceName={'4 years'} clickArgs={[true, setPerfPeriod, null, null]} />
                              <ListItem withLink={true} linkClass={null} isActive={perfPeriod === '5Y'} activeClass={'state-selected'} baseClass={null} instanceKey={'5Y'} instanceName={'5 years'} clickArgs={[true, setPerfPeriod, null, null]} />
                            </ul>
                          </div>
                          <div className="width-100">
                            <div id="divChartContainerEVEBITDAPerf" className="historical"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  { drivers.length > 0 && drivers[0].name &&
                    <Sens companyKey={companyKey} drivers={drivers} />
                  }
                  <div className="col col-100">
                    <div className="card card-white">
                      <div className="card__head">
                        <div className="card__head-name">
                          <span>
                            EV/EBITDA factors chart
                          </span>
                        </div>
                        <ul className="tags">
                          <ListItem withLink={true} linkClass={null} isActive={waterPeriod === 'D'} activeClass={'state-selected'} baseClass={null} instanceKey={'D'} instanceName={'1 day'} clickArgs={[true, setWaterPeriod, null, null]} />
                          <ListItem withLink={true} linkClass={null} isActive={waterPeriod === 'W'} activeClass={'state-selected'} baseClass={null} instanceKey={'W'} instanceName={'1 week'} clickArgs={[true, setWaterPeriod, null, null]} />
                          <ListItem withLink={true} linkClass={null} isActive={waterPeriod === 'M'} activeClass={'state-selected'} baseClass={null} instanceKey={'M'} instanceName={'1 month'} clickArgs={[true, setWaterPeriod, null, null]} />
                        </ul>
                      </div>
                      <div>
                        <div id="divChartContainerWaterfall" className="sky-height"></div>
                      </div>
                    </div>
                  </div>
                  <Rep companyKey={companyKey} />
                </div>
              </section>
              <section className="section section-company-table">
                <div className="section__title">
                  <h2>Condensed Financial statements</h2>
                  <div className="dropdown hidden-desktop">
                    <div className="dropdown__button js-dropdown-button">
                      <i className="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                                d="M7.48641 10.8333C7.48641 10.8333 4.97671 7.08334 3.48135 4.67501C3.12157 4.09826 3.44797 3.33334 4.14046 3.33334H15.8569C16.5494 3.33334 16.8826 4.0838 16.516 4.67501C15.0226 7.08334 12.4924 10.8333 12.4924 10.8333V15.8333C12.4924 16.2917 12.1169 16.6667 11.658 16.6667H8.32073C7.86185 16.6667 7.48641 16.2917 7.48641 15.8333V10.8333ZM9.16683 10.4167L5.62516 5.00001H14.4074L10.8335 10.4167V15L9.16683 15V10.4167Z"
                                fill="#606980"/>
                        </svg>
                      </i>
                    </div>
                    <div className="dropdown__selection dropdown__menu dropdown__menu-white dropdown__menu-fixed">
                      <div className="dropdown__slide">
                        <div className="dropdown__hide">
                          <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5"
                                  d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                                  fill="#D4D7DF"/>
                          </svg>
                        </div>
                        <div className="dropdown__menu-title">Scenarios</div>
                        <div className="dropdown__slide-content scrollable">
                          <ul>
                            {scenarios.map((scenario, index) => {
                              return <CheckBox key={scenario.key} withListItemWrap={true} id={'chkbx_' + (index + 1) + (index + 1)  + (index + 1)} value={index+1} label={scenario.name} isActive={finsScenario === scenario.key} instanceKey={scenario.key} clickArgs={[true, setFinsScenario, scenariosNames, setFinsScenarioName]} />
                            })}
                          </ul>
                        </div>
                        <div className="button__wrapper">
                          <button type="button"
                                  className="button button__blue hidden-desktop">Apply
                          </button>
                        </div>
                      </div>
                      <div className="dropdown__overlay"></div>
                    </div>
                  </div>
                </div>
                <div className="section__content">
                  <div className="table__head justify-content-between hidden-mobile">
                    <div className="table__head-info">
                      <div>Scenarios:</div>
                      <div className="custom-select">
                        <div className="custom-select__inner">
                          <div className="custom-select__trigger"><span>{finsScenarioName}</span>
                            <i className="icon">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M15.4676 9.26651L11.9955 12.8018L8.52349 9.26651C8.1745 8.91116 7.61074 8.91116 7.26174 9.26651C6.91275 9.62187 6.91275 10.1959 7.26174 10.5513L11.3691 14.7335C11.7181 15.0888 12.2819 15.0888 12.6309 14.7335L16.7383 10.5513C17.0872 10.1959 17.0872 9.62187 16.7383 9.26651C16.3893 8.92027 15.8166 8.91116 15.4676 9.26651V9.26651Z"
                                  fill="#606980"/>
                              </svg>
                            </i>
                          </div>
                          <div className="custom-select__options position-right">
                            <div className="custom-select__options-inner scrollable">
                              <ul className="custom-select__options-list">
                                {scenarios.map(scenario => {
                                  return <ListItem key={scenario.key} withLink={false} linkClass={null} isActive={finsScenario === scenario.key} activeClass={'custom-select__option state-selected'} baseClass={'custom-select__option'} instanceKey={scenario.key} instanceName={scenario.name} clickArgs={[true, setFinsScenario, scenariosNames, setFinsScenarioName]} />
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="company__table hidden-mobile">
                    <div className="table__container">
                      <div className="table__scrollable">
                        <Table companyKey={companyKey} data={tableData} />
                      </div>
                    </div>
                  </div>
                  <ul className="tags hidden-desktop">
                    {tableData.length > 0 &&
                      tableData[0].results[0].periods.map(period => {
                        return <ListItem key={period} withLink={true} linkClass={null} isActive={mobileTablePeriod === period} activeClass={'state-selected'} baseClass={null} instanceKey={period} instanceName={period} clickArgs={[true, setMobileTablePeriod, null, null]} />
                    })}
                  </ul>
                  <div className="company__table-2 hidden-desktop">
                    <MobileTable companyKey={companyKey} period={mobileTablePeriod} data={tableData} />
                  </div>
                </div>
              </section>
              <NewsPager newsType={'company'} objectKey={companyKey} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Company;
