import { formatFinsData, checkFinsPeriod } from '../../common/formatting'

function MobileTableRow(props) {
  return (
    <tr>
      <td className={(props.rowName === 'yield' || props.rowName === 'margin') ? 'color-dark-gray': null}>{props.rowName}</td>
      {props.periods.map(period => {
        if (!props.period.includes('o') && props.period === period)
          return <td key={period}>{formatFinsData(props.formatting, props.data[period])}</td>
      })}
      {props.period.includes('o') && checkFinsPeriod(props.periods[props.periods.length - 1]) &&
        <td>{(!!props.data[props.periods[props.periods.length - 2]]) && (!!props.data[props.periods[props.periods.length - 3]]) ? formatFinsData('P0', props.data[props.periods[props.periods.length - 2]] / props.data[props.periods[props.periods.length - 3]] - 1): ''}</td>
      }
    </tr>
  );
}

export default MobileTableRow;