import axiosInstance from '../../axios/instance';
import newAxiosInstance from '../../axios/newInstance';

async function makeApiRequest(path) {
  var res = await axiosInstance.get(path);
  return res.data;
}

async function newMakeApiRequest(path) {
  var res = await newAxiosInstance.get(path);
  return res.data;
}

export default {
  onReady: async (callback) => {
		var configurationDataExchanges = await newMakeApiRequest('charts/configuration/exchanges');
		var configurationDataSymbolsTypes = await newMakeApiRequest('charts/configuration/symbols-types');

		var configurationData = {
      'exchanges': [
        {
          'value': 'All',
          'name': 'All',
          'desc': 'All',
        },
        ...configurationDataExchanges
      ],
      'symbols_types': [
        {
          'name': 'All',
          'value': 'All',
        },
        ...configurationDataSymbolsTypes
      ],
      'supported_resolutions': ['D', ],
    }

		callback(configurationData);
	},

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
      var symbolsData = [];
      var newSymbols = [];

      if (!exchange)
        exchange = 'All'
      if (!symbolType)
        symbolType = 'All'

      if (userInput) {
        symbolsData = await newMakeApiRequest(`charts/symbol/search/${exchange}/${symbolType}/?search=${userInput}`);
      } else {
        symbolsData = await newMakeApiRequest(`charts/symbol/search/${exchange}/${symbolType}/`);
      }

      symbolsData.forEach(symbolItem => {
        var symbolInfo = {
          symbol: symbolItem.key,
          full_name: symbolItem.exchange + ':' + symbolItem.key,
          description: symbolItem.title,
          exchange: symbolItem.exchange,
          type: symbolItem.symbol_type,
        };
        newSymbols.push(symbolInfo);
      });

      onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		var symbolItem = await newMakeApiRequest(`charts/symbol/info/${symbolName}`);

		if (!symbolItem) {
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}

		var symbolInfo = {
		  name: symbolItem.key,
		  ticker: symbolItem.exchange + ':' + symbolItem.key,
			description: symbolItem.title,
			type: symbolItem.symbol_type,
			session: '24x7',
			exchange: symbolItem.exchange,
			timezone: 'Etc/UTC',
			format: 'price',
			minmov: symbolItem.min_mov,
			pricescale: symbolItem.price_scale,
			supported_resolutions: ['D', ],
			has_empty_bars: true,
      visible_plots_set: 'c',
			sector: symbolItem.sector,
			industry: symbolItem.industry,
			currency_code: symbolItem.currency_code,
			unit_id: symbolItem.unit_id,
		};

		onSymbolResolvedCallback(symbolInfo);
	},

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		var { from, to, countBack, firstDataRequest } = periodParams;
		var urlParameters = {
			to: to,
			countBack: countBack,
		};
		var newUrlParameters = {
			to: to,
			count_back: countBack,
		};

		var query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');
		var newQuery = Object.keys(newUrlParameters)
			.map(name => `${name}=${encodeURIComponent(newUrlParameters[name])}`)
			.join('&');

		try {
		    var multiplier = symbolInfo.name.slice(symbolInfo.name.lastIndexOf(' ') + 1);
		    var multipliers = ['Div_yield', 'EBITDA', 'EV_EBITDA', 'FCF_yield', 'P_E'];
            if (symbolInfo.exchange === 'Commodity basket' || (symbolInfo.exchange === 'Multipliers' && multipliers.includes(multiplier))) {
                var data = await newMakeApiRequest(`charts/symbol/history/${symbolInfo.name}?${newQuery}`);
            } else {
                var data = await newMakeApiRequest(`charts/symbol/history/${symbolInfo.name}/?${newQuery}`);
            }

			if (!data || data.length === 0) {
				// "noData" should be set if there is no data in the requested period.
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

      onHistoryCallback(data, { noData: false });
		} catch (error) {
			onErrorCallback(error);
		}
	},

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
  },

  unsubscribeBars: (subscriberUID) => {
  },
};
