import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { widget } from '../../charting_library';
import Datafeed from './datafeed';

function Chart(props) {
  const [tvWidget, setTVWidget] = useState(null);
  const ref = useRef(null);

  const [isWidgetReady, setIsWidgetReady] = useState(false);

  const [ip, setIP] = useState('');
  const [showCSVButton, setShowCSVButton] = useState(false);
  const possibleIPs = ['2.63.205.2', '195.248.69.45'];

  const getIP = async() => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    if (possibleIPs.includes(ip))
      setShowCSVButton(true);
  }, [ip]);

  const defaultOptions = {
		interval: 'D',

		timeframe: '12M',
		timezone: 'Etc/UTC',

    debug: false,
		libraryPath: '/charting_library/',

    width: '100%',
    height: 500,
		fullscreen: false,
		autosize: false,

		charts_storage_url: 'https://metals-wire.com/api/v2/charts',
    client_id: 'metals-wire.com',
    user_id: 'public_user_id',
    charts_storage_api_version: '1.1'
	};

  function getCurDayOfYear() {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);
    return day;
  }


  useEffect(() => {
    if (tvWidget === null) {
      var widgetOptions = {
        symbol: props.symbol,
        interval: defaultOptions.interval,
        container: ref.current,
        datafeed: Datafeed,

        timeframe: defaultOptions.timeframe,
        timezone: defaultOptions.timezone,

        debug: defaultOptions.debug,
        library_path: defaultOptions.libraryPath,

        width: defaultOptions.width,
        height: defaultOptions.height,
        fullscreen: defaultOptions.fullscreen,
        autosize: defaultOptions.autosize,

        locale: 'en',

        time_frames: [
          {text: getCurDayOfYear().toString() + 'd', resolution: '1D', title: 'YTD'},
          {text: '1y', resolution: '1D'},
          {text: '2y', resolution: '1D'},
          {text: '5y', resolution: '1D'},
          {text: '10y', resolution: '1D'},
        ],

        charts_storage_url: defaultOptions.charts_storage_url,
        client_id: defaultOptions.client_id,
        user_id: defaultOptions.user_id,
        charts_storage_api_version: defaultOptions.charts_storage_api_version,

        disabled_features: ['use_localstorage_for_settings'],
        enabled_features: ['study_templates', 'study_overlay_compare_legend_option', ],
      };

      setTVWidget(new widget(widgetOptions));

      return () => {
        setTVWidget((prevState) => {
          if (prevState !== null) {
            prevState.remove();
            return null;
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (tvWidget !== null) {
      tvWidget.onChartReady(function() {
        setIsWidgetReady(true);
      });
    }
  }, [tvWidget]);

  function handleCSVButtonClick(e) {
    e.preventDefault();

    var tickers = [props.symbol.split(':')[1]];

    tvWidget.activeChart().getAllStudies().forEach(({ id }) => {
      if (!!tvWidget.activeChart().getStudyById(id).getInputValues()[0])
        tickers.push(tvWidget.activeChart().getStudyById(id).getInputValues()[0].value.split(':')[1]);
    });

    window.location.href = 'https://metals-wire.com/tv-csv-file/'+ tickers.join();
  }

  return (
    <React.Fragment>
      <div className="card__head" style={{ marginBottom: '10px' }}>
        <div className="card__head-name">
          <div>
          </div>
        </div>
        <ul className="tags" style={{ display: 'inline-block'}}>
          { showCSVButton && isWidgetReady &&
            <li className={"state-selected"} style={{ display: 'block'}}><a href="#" onClick={handleCSVButtonClick}>{'Export data'}</a></li>
          }
        </ul>
      </div>
      <div>
        <div ref={ ref } />
      </div>
    </React.Fragment>
  );

}

export default Chart;