import { useState, useEffect } from 'react';

function Currency(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(props.isRelatedView)
  }, [props.isRelatedView]);

  function handleClick(e) {
    e.preventDefault();

    setIsActive(prevState => {
      if (prevState) {
        props.widget.activeChart().setSymbol(props.ticker);
        return prevState;
      }
      else {
        props.widget.activeChart().setSymbol(props.USDTicker);
        return prevState;
      }
    });
  }

  if (props.isWidgetReady) {
    return (
      <li className={ isActive ? "state-selected": null}><a href="#" onClick={handleClick}>USD</a></li>
    );
  } else {
    return null;
  }

}

export default Currency;