import { useState } from 'react';
import { formatPrice } from '../../common/formatting';

function TargetPrice(props) {
  const [extraClass, setExtraClass] = useState('')

  function handleClick(e) {
    e.preventDefault();

    setExtraClass((prevState) => {
      if (prevState === '') {
        return ' state-open';
      } else {
        return '';
      }
    });

  }

  return (
    <h1>
      <div className="card__head-name">
        <span style={{ color: 'black' }}>
          {props.data.rec_type}; TP - {formatPrice(props.data.target_price)} {props.data.target_price_currency}/{props.data.share_type}
        </span>
        <div className={"dropdown dropdown-hint" + extraClass} style={{display: 'inline'}} onClick={handleClick}>
          <div className="dropdown__button js-dropdown-button-hint">
            <i className="icon icon__help">
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="6.5" stroke="#606980"/>
                <path
                  d="M7.34737 9.38028C7.34737 9.12394 7.38596 8.90376 7.46316 8.71972C7.54035 8.52911 7.63509 8.35493 7.74737 8.19718C7.85965 8.03944 7.98246 7.89484 8.11579 7.76338C8.24912 7.62535 8.37193 7.48732 8.48421 7.3493C8.59649 7.20469 8.69123 7.05352 8.76842 6.89577C8.84561 6.73146 8.88421 6.54413 8.88421 6.3338C8.88421 6.02488 8.77895 5.78169 8.56842 5.60423C8.36491 5.42676 8.04912 5.33803 7.62105 5.33803C7.58596 5.33803 7.51228 5.34131 7.4 5.34789C7.29474 5.34789 7.16491 5.36103 7.01053 5.38732C6.86316 5.40704 6.70175 5.44648 6.52632 5.50563C6.35088 5.55822 6.17544 5.6338 6 5.73239V4.82535C6.32281 4.6939 6.63158 4.60845 6.92632 4.56901C7.22807 4.523 7.4807 4.5 7.68421 4.5C8.05614 4.5 8.38596 4.54601 8.67368 4.63803C8.9614 4.72347 9.20351 4.84507 9.4 5.00282C9.59649 5.16056 9.74386 5.34789 9.84211 5.56479C9.94737 5.77512 10 6.00516 10 6.25493C10 6.49155 9.95789 6.70845 9.87368 6.90563C9.79649 7.09624 9.69474 7.27371 9.56842 7.43803C9.44211 7.60235 9.30877 7.76009 9.16842 7.91127C9.02807 8.05587 8.89474 8.20704 8.76842 8.36479C8.64211 8.51596 8.53684 8.68028 8.45263 8.85775C8.37544 9.02864 8.33684 9.21925 8.33684 9.42958V9.57746H7.34737V9.38028ZM7.25263 10.3958H8.43158V11.5H7.25263V10.3958Z"
                  fill="#606980"/>
              </svg>
            </i>
          </div>
          <div className="dropdown__menu dropdown__menu-dark dropdown--bottom-right dropdown__menu-fixed" style={{ minWidth: '450px' }}>
            <div className="dropdown__slide">
              <div className="dropdown__hide">
                <svg width="35" height="10" viewBox="0 0 35 10" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5"
                        d="M0 2.66564C0 1.3644 1.28662 0.4541 2.51367 0.887179L16.5188 5.83016C16.8307 5.94024 17.1703 5.94322 17.4841 5.83863L32.5026 0.832454C33.7312 0.42292 35 1.33739 35 2.63246V2.63246C35 3.44914 34.4774 4.1742 33.7026 4.43246L17.9405 9.68649C17.331 9.88968 16.671 9.88389 16.0651 9.67004L1.25829 4.4441C0.504251 4.17797 0 3.46526 0 2.66564V2.66564Z"
                        fill="#D4D7DF"/>
                </svg>
              </div>
              <div className="dropdown__slide-content scrollable" style={{ fontFamily: '"VTBGroupUI",Arial,sans-serif', fontSize: '14px', lineHeight: '20px', letterSpacing: '-.4px' }}>
                <p>
                  {props.data.comment}
                </p>
              </div>
            </div>
            <div className="dropdown__overlay"></div>
          </div>
        </div>
      </div>
    </h1>
  );
}

export default TargetPrice;