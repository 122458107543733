import MobileTableRow from './MobileTableRow';

function MobileTable(props) {
  return (
    <div className="summary__list hidden-desktop">
      <ul>
       {props.data.companies.map(company => {
         if (company.multipliers.EV_EBITDA)
           return <MobileTableRow key={company.key} data={company} />
       })}
      </ul>
    </div>
  );
}

export default MobileTable;